import Utils from './utils';

export default class Paywall {
  static setup() {
    $('body').on(Utils.interactionEvent, '.lp--js-premiumLink', function (e) {
      e.preventDefault();
      Paywall.requirePremium().then(function () {
        window.location.href = e.currentTarget.getAttribute('href');
      });
    });

    $('body').on(Utils.interactionEvent, '.lp--sessionCircleEditorLink', function (e) {
      e.preventDefault();
      Paywall.requirePremium().then(function () {
        window.location.href = e.currentTarget.getAttribute('href');
      });
    });
  }

  static requirePremium() {
    return new Promise(function (resolve) {
      if (Paywall.isPremium()) {
        resolve();
      } else {
        location.href = '/#paywall';
      }
    });
  }

  // Note that this only performs a client-side validation of payment-status, which is fine
  // since even if a malicious user would change the local flag, we of course perform
  // server-side checks which can't be bypassed. So a malicious user would be redirected
  // and served the modal instead of receiving an in-action flow.
  static isPremium() {
    return document.body.getAttribute('data-user-premium') === 'true';
  }
}
