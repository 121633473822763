import styled from 'styled-components';

export const ErrorMessageWrapper = styled.div`
  background-image: url(/assets/images/theme-2/icons/field-error.png);
  background-size: 14px 14px;
  background-position: left center;
  padding: 0px 0px 0px 20px;
  margin: ${(props) => (props.noMargin === true ? '0' : '10px 0 25px 25px')};
  background-repeat: no-repeat;
  white-space: nowrap;
`;
