import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { DatePickerWrapper } from './styles';
import { format, parseISO } from 'date-fns';
import { enUS, es, sv, da, nb, arSA, de, fr } from 'date-fns/locale';

const DatePicker = ({ value, onChange, minDate, maxDate, disabled, ...rest }) => {
  const [selectedDate, setSelectedDate] = useState(value ? parseISO(value) : null);
  const locale = window.baseLocale;
  const dateLocales = {
    'en-US': enUS,
    'es-ES': es,
    'sv-SE': sv,
    'da-DK': da,
    'nb-NO': nb,
    'de-DE': de,
    'fr-FR': fr,
    'ar-SA': arSA,
  };
  let dateFormat = dateLocales[locale].formatLong.date({ width: 'short' });

  if (!dateFormat.includes('yyyy')) {
    dateFormat = dateFormat.replace('y', 'yyyy');
  }

  registerLocale(locale, dateLocales[locale]);

  const formatIsoDate = (date) => {
    return format(new Date(date), 'yyyy-MM-dd');
  };

  const changeHandler = (date) => {
    setSelectedDate(date);
    onChange(formatIsoDate(date));
  };

  return (
    <DatePickerWrapper>
      <ReactDatePicker
        role="textbox"
        locale={locale}
        dateFormat={dateFormat}
        selected={value ? selectedDate : null}
        onChange={(date) => changeHandler(date)}
        disabled={disabled}
        placeholderText={dateFormat.toLowerCase()}
        minDate={minDate ? parseISO(minDate) : new Date()}
        maxDate={maxDate ? parseISO(maxDate) : null}
        {...rest}
      />
    </DatePickerWrapper>
  );
};

DatePicker.propTypes = {
  value: PropTypes.string,
  minDate: PropTypes.string,
  maxDate: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  rest: PropTypes.object,
};

export default DatePicker;
