import styled, { css } from 'styled-components';

export const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${({ border }) =>
    border &&
    css`
      border: 2px solid ${({ theme }) => theme.colors.iconButtonBorder};
      border-radius: 8px;
    `}
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const CropButton = styled.img`
  border-radius: 50%;
  height: 40px;
  width: 40px;
  flex-shrink: 0;
  margin-left: 10px;
  margin-bottom: 10px;
  transition: 0.2s border-color ease-in-out;
  border: 2px solid ${({ theme }) => theme.colors.iconButtonBorder};

  &:last-of-type {
    margin-left: 5px;
  }

  ${({ src }) =>
    src &&
    css`
      &:hover,
      &:focus {
        cursor: pointer;
        transition: 0.2s border-color ease-in-out;
        border-color: ${({ theme }) => theme.colors.iconButtonBorderHover};
      }
    `}
`;
