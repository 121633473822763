import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { HeaderTool } from './styles';

import { launchAsync } from '@microsoft/immersive-reader-sdk';
import ImmersiveReaderContentBuilder from '../../../app/assets/js/shared/immersiveReader.js';
import ImmersiveReaderIcon from '../../../public/assets/images/theme-2/icons/panel-header/immersive-reader.svg';

import useApi from '../../hooks/useApi';

const ImmersiveReaderButton = ({ userToken, nodeData = {}, title = '', show = true }) => {
  const [launchParameters, setLaunchParameters] = useState(null);

  const handleOpenEvent = (event) => {
    const text = event && event.detail;
    openImmersiveReader({
      title: i18n.__('app_selection'),
      chunks: [{ content: text, mimeType: 'text/html' }],
    });
  };

  useEffect(() => {
    document.addEventListener('open.immersiveReader', handleOpenEvent);
    return () => document.removeEventListener('open.immersiveReader', handleOpenEvent);
  }, []);

  const { getData } = useApi(userToken);
  const immersiveReaderContent = new ImmersiveReaderContentBuilder(title);

  const getLaunchParameters = async () => {
    if (launchParameters === null) {
      const response = await getData(`/integrations/microsoft/fetchImmersiveReaderLaunchParameters`);
      if (response.success) {
        setLaunchParameters(response.body);
        return response.body;
      }
    }
    return launchParameters;
  };

  const openImmersiveReader = async (selectedContent = undefined) => {
    immersiveReaderContent.buildFromContent(nodeData, 'checklist');
    const content = selectedContent || immersiveReaderContent.getContent();

    let options = {};
    try {
      const [language] = i18n.getLocale().split('-');
      if (language) {
        options.uiLang = language;
      }
    } catch (error) {}

    const { token, subdomain } = await getLaunchParameters();
    launchAsync(token, subdomain, content, options);
  };

  return (
    <HeaderTool
      src={ImmersiveReaderIcon}
      onClick={() => {
        openImmersiveReader();
      }}
      role="img"
      title={i18n.__('app_open_screen_reader')}
      show={show}
    />
  );
};

ImmersiveReaderButton.propTypes = {
  userToken: PropTypes.string.isRequired,
  title: PropTypes.string,
  nodeData: PropTypes.object,
};

export default ImmersiveReaderButton;
