import React, { useState } from 'react';
import PropTypes from 'prop-types';
import NodeActivityEditor from '../../NodeActivityEditor';
import CommentsIcon from '../../../../public/assets/images/theme-2/icons/asterisk-white.svg';
import AcceptIcon from '../../../../public/assets/images/theme-2/icons/accepted.svg';
import SneakPeakIcon from '../../../../public/assets/images/theme-2/icons/toolbar/visible-black.svg';
import { ViewSection, HeaderRequestFeedback, SneakPeak, AcceptButton } from '../styles';
import { Editor, ButtonWrapper, Questions, Question } from '../../NodeActivityEditor/styles';

const WrittenQuizContentTeacherPanel = ({ data, report, accept }) => {
  const [hasPeaked, setHasPeaked] = useState({});

  if (!report || !data) {
    return null;
  }

  const { state: reportState } = report;
  const workNotStarted = reportState === 'blank';
  const getAnswer = (questionId) =>
    report.QuizAnswers && report.QuizAnswers.find((answer) => answer.question_id === questionId);
  const getWorkInProgress = (questionId) => {
    const answer = getAnswer(questionId);
    return reportState === 'active' && answer && answer.draft;
  };

  const shouldShowAcceptButton = reportState !== 'accepted';

  const getContent = (questionId) => {
    const answer = getAnswer(questionId);
    if (workNotStarted) {
      return i18n.__('work_not_started');
    } else if (getWorkInProgress(questionId) && !hasPeaked[questionId]) {
      return i18n.__('work_in_progress');
    } else if (answer) {
      return answer.text;
    } else {
      return '';
    }
  };

  const toggleReportComments = (answer) => {
    document.dispatchEvent(
      new CustomEvent('toggleComments', { detail: { entityId: answer.uuid, entityType: 'quiz_answer' } }),
    );
  };

  const sneakPeak = (questionId) => {
    setHasPeaked({ ...hasPeaked, [questionId]: true });
  };

  const getViewForQuestion = (question, index) => {
    const headerRightPanel = (
      <HeaderRequestFeedback onClick={() => toggleReportComments(getAnswer(question.id))} src={CommentsIcon} />
    );

    const editorPanel = (
      <Editor
        showStatusText={workNotStarted || (getWorkInProgress(question.id) && !hasPeaked[question.id])}
        dangerouslySetInnerHTML={{ __html: getContent(question.id) }}
      />
    );

    const sneakPeakPanel =
      getWorkInProgress(question.id) && !hasPeaked[question.id] ? (
        <SneakPeak onClick={() => sneakPeak(question.id)} src={SneakPeakIcon}>
          {i18n.__('sneak_peak')}
        </SneakPeak>
      ) : null;

    return (
      <NodeActivityEditor
        isQuestion={true}
        questionIndex={index}
        headerText={question.body}
        headerRightPanel={headerRightPanel}
        editorPanel={editorPanel}
        sneakPeakPanel={sneakPeakPanel}
      />
    );
  };

  const questions = data.QuizQuestions.map((question, index) => (
    <Question key={question.id}>{getViewForQuestion(question, index)}</Question>
  ));

  return (
    <ViewSection>
      <Questions>{questions}</Questions>
      <ButtonWrapper>
        {shouldShowAcceptButton && (
          <AcceptButton onClick={accept} src={AcceptIcon} title={i18n.__('editor_state_accept_assignment')} />
        )}
      </ButtonWrapper>
    </ViewSection>
  );
};

export default WrittenQuizContentTeacherPanel;

WrittenQuizContentTeacherPanel.propTypes = {
  data: PropTypes.object.isRequired,
  report: PropTypes.object.isRequired,
  accept: PropTypes.func.isRequired,
};
