import styled, { css } from 'styled-components';

export const PanelWrapper = styled.section`
  display: flex;
  min-height: 100%;
  flex-direction: column;
`;

export const HeaderSection = styled.section`
  margin: 0px 0px 26px 0px;
  padding: 0 45px 0 42px;
`;

export const HeaderText = styled.textarea`
  color: ${({ theme }) => theme.colors.black_9};
  font-size: 36px;
  border: none;
  margin: 0;
  padding: 0;
  resize: none;
  width: 100%;
  font-weight: 700;
  overflow: hidden;
  text-overflow: ellipsis;

  &::placeholder {
    color: ${({ theme }) => theme.colors.grey};
    font-style: italic;
    text-indent: -4px;
  }
`;

export const ContentSection = styled.section`
  margin: 0;
  color: ${({ theme }) => theme.colors.black_9};
  font-size: 18px;
  padding: 0 42px;
`;

export const FooterSection = styled.section`
  margin-top: auto;
  box-shadow: -3px -2px 5px ${({ theme }) => theme.colors.black_1};
  position: sticky;
  background: ${({ theme }) => theme.colors.white}
  bottom: 0;
  z-index: 2;
`;

export const BottomPanel = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.colors.black_5};
  display: flex;
  height: 60px;
  margin-top: auto;
  padding: 0 30px;
  justify-content: space-between;
`;

export const InfoPanel = styled.div`
  border-bottom: 2px solid ${({ theme }) => theme.colors.buttonGreenFront};
  border-top: 2px solid ${({ theme }) => theme.colors.buttonGreenFront};
  color: ${({ theme }) => theme.colors.black_7};
  cursor: pointer;
  font-size: 14px;
  font-style: italic;
  padding: 16px 42px;
`;

export const InfoText = styled.div``;

export const InfoIcon = styled.div`
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
  height: 32px;
  position: absolute;
  right: 50%;
  width: 32px;

  ${({ src }) =>
    src &&
    css`
      background-image: url(${src});
    `}
`;

export const DeleteIcon = styled.div`
  cursor: pointer;
  background-position: left center;
  background-repeat: no-repeat;
  background-size: 11px 11px;
  border: none;
  padding: 0 0 0 20px;
  color: ${({ theme }) => theme.colors.black_9};
  opacity: 0.7;
  transition: 0.2s opacity ease-in-out;
  margin-right: 5px;
  padding-right: 5px;

  &:hover,
  &:focus {
    opacity: 1;
    transition: 0.2s opacity ease-in-out;
  }

  ${({ src }) =>
    src &&
    css`
      background-image: url(${src});
    `}
`;

export const TypeSelector = styled.div`
  margin-left: 5px;
  padding: 10px;
  border: 2px solid ${({ theme }) => theme.colors.behaviorYellow};
  border-radius: 8px;
  display: flex;
  align-items: center;
`;

export const ArticleType = styled.div`
  cursor: pointer;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 11px 11px;
  border: 2px solid ${({ theme }) => theme.colors.transparent};
  padding: 10px;
  color: ${({ theme }) => theme.colors.black_9};
  opacity: 0.7;
  transition: 0.2s opacity ease-in-out;
  margin-right: 5px;

  &:hover,
  &:focus {
    opacity: 1;
    transition: 0.2s opacity ease-in-out;
  }

  ${({ src }) =>
    src &&
    css`
      background-image: url(${src});
    `}

  ${({ selected }) =>
    selected &&
    css`
      border: 2px solid ${({ theme }) => theme.colors.behaviorYellow};
      border-radius: 50%;
    `}
`;

export const SelectorText = styled.div``;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;

  button {
    margin-left: 10px;
  }
`;

export const Questions = styled.div`
  display: flex;
  flex-direction: column;
`;

export const QuestionWrapper = styled.div`
  display: flex;
`;

export const Question = styled.div`
  display: flex;
  margin-top: 40px;
  width: 100%;

  > div {
    width: 100%;
  }

  .ql-editor {
    line-height: 22px;
  }
`;

export const QuestionTools = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px 10px;
`;

export const DeleteQuestion = styled.div`
  cursor: pointer;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 11px 11px;
  border: 1px solid ${({ theme }) => theme.colors.black};
  border-radius: 50%;
  padding: 13px;
  margin-bottom: 5px;
  opacity: 0.7;
  transition: 0.2s opacity ease-in-out;

  &:hover,
  &:focus {
    opacity: 1;
    transition: 0.2s opacity ease-in-out;
  }

  ${({ src }) =>
    src &&
    css`
      background-image: url(${src});
    `}
`;

export const MoveUpQuestion = styled(DeleteQuestion)`
  border: none;
  margin-bottom: 3px;
  opacity: 0.4;
  &:hover,
  &:focus {
    opacity: 0.7;
  }
`;

export const MoveDownQuestion = styled(MoveUpQuestion)`
  transform: rotate(180deg);
`;

export const InsertImage = styled(MoveUpQuestion)`
  background-size: 26px 26px;
  margin-bottom: 5px;
`;
