import React, { useEffect, useState } from 'react';
import Popup from '../Popup';
import TextField from '../TextField';
import SecondaryButton from '../SecondaryButton';
import useKeyPress from '../../hooks/useKeyPress';
import Embedder from '../../../app/assets/js/shared/embedder';
import Utils from '../../../app/assets/js/shared/utils';
import {
  PopupContainer,
  PopupClose,
  SelectionPanel,
  SelectionHeader,
  EmbedPanel,
  Embed,
  BasicLinkPanel,
  CustomLinkPanel,
  LoadingPanel,
  Description,
  LinkText,
  ColumnWrapper,
  Wrapper,
  PanelHeader,
} from './styles';

const EmbedSelectionPopup = ({ data, popupIsOpen, onSelect, onCustomLink, onClose, ...rest }) => {
  const { url, fileName = url } = data;
  const selectLabel = i18n.__('app_select').replace('...', '');
  const [isLoading, setIsLoading] = useState(true);
  const [embedResponse, setEmbedResponse] = useState();
  const [linkTitle, setLinkTitle] = useState(fileName);

  useEffect(() => {
    const tryEmbed = async () => {
      const remoteUrl = new URL(url);
      const favIconUrl = `${remoteUrl.origin}/favicon.ico`;
      const [favIconExist, response] = await Promise.all([Utils.tryLoadImage(favIconUrl), Embedder.embed(url)]);
      if (favIconExist) {
        data.favIcon = favIconUrl;
      }
      if (response.result !== 'OEMBED_MISSING' && response.html) {
        const updatedHtml = await getUpdatedEmbed(response.html);
        if (updatedHtml) {
          setEmbedResponse(updatedHtml);
        }
      } else {
        const iframeHtml = getIframeEmbed();
        setEmbedResponse(iframeHtml);
      }
      setIsLoading(false);
    };
    tryEmbed();
  }, []);

  const handleClose = () => {
    onClose();
  };

  const getEmbedAsElement = (html) => {
    const div = document.createElement('div');
    div.innerHTML = html;
    return div;
  };

  const getUpdatedEmbed = async (html) => {
    const embed = getEmbedAsElement(html);
    if (embed) {
      const blockQuoteLink = embed.querySelector('blockquote a');
      const iframe = embed.querySelector('iframe');
      if (blockQuoteLink) {
        blockQuoteLink.setAttribute('target', '_blank');
        if (iframe) {
          iframe.setAttribute('style', 'pointer-events: none');
        }
      }
      if (iframe) {
        iframe.setAttribute('title', iframe.src);
      }
      return embed.innerHTML;
    }
    return html;
  };

  const getIframeEmbed = () => {
    const baseUrl = Utils.fileIsOffice(url)
      ? Utils.getOfficePreviewUrl()
      : Utils.fileIsPdf(url)
        ? '/pdfjs/viewer.html?file='
        : '';
    return `<div class="iframe"><iframe src=${baseUrl + url} title=${fileName}></iframe></div>`;
  };

  const selectEmbed = () => {
    onSelect(embedResponse);
  };

  const selectBasic = () => {
    onSelect(`<a href=${url} target="_blank">${linkTitle}</a>`);
  };
  const selectCustom = () => {
    data.fileName = fileName;
    onCustomLink(data);
  };

  useKeyPress('Escape', () => {
    handleClose();
  });

  const popupContent = (
    <PopupContainer className="embed-selection-popup" {...rest}>
      <PopupClose
        tabIndex={0}
        onClick={handleClose}
        onKeyPress={handleClose}
        role="button"
        aria-label={i18n.__('app_close')}
      />
      <SelectionPanel>
        <SelectionHeader>{i18n.__('select_embed.header')}</SelectionHeader>
        <BasicLinkPanel>
          <ColumnWrapper>
            <PanelHeader>{i18n.__('select_embed.basic_link_header')}</PanelHeader>
            <Wrapper>
              <Description>{i18n.__('select_embed.basic_link_description')}:</Description>
              <SecondaryButton text={selectLabel} onClick={selectBasic} />
            </Wrapper>
            <LinkText>
              <TextField
                autoFocus
                id="linkTitle"
                name="linkTitle"
                aria-label={i18n.__('select_template.title_placeholder')}
                placeholder={i18n.__('select_template.title_placeholder')}
                defaultValue={linkTitle}
                onChange={(ev) => {
                  ev.preventDefault();
                  setLinkTitle(ev.target.value);
                }}
              />
            </LinkText>
          </ColumnWrapper>
        </BasicLinkPanel>
        <CustomLinkPanel>
          <ColumnWrapper>
            <PanelHeader>{i18n.__('select_embed.custom_link_header')}</PanelHeader>
            <Wrapper>
              <Description>{i18n.__('select_embed.custom_link_description')}:</Description>
              <SecondaryButton text={selectLabel} onClick={selectCustom} />
            </Wrapper>
          </ColumnWrapper>
        </CustomLinkPanel>
        {!isLoading && embedResponse ? (
          <EmbedPanel>
            <ColumnWrapper>
              <PanelHeader>{i18n.__('select_embed.embed_link_header')}</PanelHeader>
              <Wrapper>
                <Description>{i18n.__('select_embed.embed_link_description')}:</Description>
                <SecondaryButton text={selectLabel} onClick={selectEmbed} />
              </Wrapper>
              <Embed dangerouslySetInnerHTML={{ __html: embedResponse || '' }} />
            </ColumnWrapper>
          </EmbedPanel>
        ) : (
          <LoadingPanel>
            <ColumnWrapper>
              <PanelHeader>{i18n.__('select_embed.embed_link_header')}</PanelHeader>
              <Description>
                {isLoading ? i18n.__('select_embed.checking_for_embed') : i18n.__('select_embed.no_embed_available')}
              </Description>
            </ColumnWrapper>
          </LoadingPanel>
        )}
      </SelectionPanel>
    </PopupContainer>
  );

  return (
    <Popup
      popupIsOpen={popupIsOpen}
      id="embed-selection-popup"
      fullBody={popupContent}
      onClose={onClose}
      overflowY={true}
    />
  );
};

export default EmbedSelectionPopup;
