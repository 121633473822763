import styled from 'styled-components';

export const Container = styled.div``;

export const TextBlock = styled.p``;

export const Wrapper = styled.div`
  margin-bottom: 24px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;

  button {
    margin-left: 16px;
  }
`;
