import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  margin: 10px 0;
`;

export const ColumnWrapper = styled(Wrapper)`
  flex-direction: column;
  margin: 0;
  width: ${({ width }) => (width ? `${width}` : '100%')};
`;

export const PopupColumnWrapper = styled(ColumnWrapper)``;

export const LibraryHeader = styled.h3`
  font-size: 19px;
  color: ${({ theme }) => theme.colors.hoverGrey};
  margin-top: 22px;
  margin-bottom: 15px;
`;

export const GeneralLibraryHeader = styled(LibraryHeader)`
  color: ${({ theme }) => theme.colors.white};
  font-size: 20px;
  margin-bottom: 15px;
  margin-top: 0px;
`;

export const PublishWrapper = styled.div``;

export const LibraryName = styled.h5`
  margin-right: 15px;
  color: ${({ theme }) => theme.colors.standardTextGrey};
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const PublishInfo = styled.p`
  font-size: 15px;
  font-style: italic;
  color: ${({ theme }) => theme.colors.white_7};
  margin-bottom: 10px;
  text-align: left;

  a {
    text-decoration: underline;
    transition: color 0.2s ease;

    &:hover,
    &:focus {
      color: ${({ theme }) => theme.colors.white};
      transition: color 0.2s ease;
    }
  }
`;

export const PublishOrganisationWrapper = styled.div`
  margin-bottom: 10px;
`;

export const ButtonPanel = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const UpdateInfo = styled.span`
  color: ${({ theme }) => theme.colors.grey};
  font-style: italic;
  font-size: 15px;
`;

export const UserWrapper = styled(ColumnWrapper)`
  border-top: 1px solid ${({ theme }) => theme.colors.white_2};
  margin-top: 20px;
  padding-top: 20px;
`;

export const UserToggle = styled.label`
  border-bottom: 1px solid ${({ theme }) => theme.colors.transparent};
  cursor: pointer;
  font-weight: 400;
  width: fit-content;
  transition: border-color 0.2s ease;
  display: flex;
  align-items: center;

  &:hover,
  &:focus {
    transition: border-color 0.2s ease;
    border-color: ${({ theme }) => theme.colors.white};
  }

  &:after {
    content: '';
    margin-left: 10px;
    background-image: url(/assets/images/icons/arrow-down.svg);
    width: 12px;
    height: 7px;
    display: flex;
    transform: ${({ show }) => (show ? 'rotate(180deg)' : 'rotate(0deg)')};
  }
`;

export const UserButtonPanel = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const UserInfoPanel = styled.div`
  height: auto;
  max-height: 600px;
  transition: max-height 0.2s ease;
  overflow: hidden;
  display: ${({ show }) => (show ? 'block' : 'none')};
`;

export const Profile = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: 26px;
  margin-top: 28px;
`;

export const ProfileAvatar = styled.div`
  background: ${({ theme }) => theme.colors.hoverGrey};
  border-radius: 50%;
  display: inline-block;
  height: 50px;
  margin-right: 15px;
  width: 50px;

  ${({ src, theme }) =>
    src &&
    css`
      background: url(${src}) center center no-repeat;
      background-size: cover;
    `}
`;

export const ProfileName = styled.span`
  color: ${({ theme }) => theme.colors.white};
  font-size: 14px;
`;

export const ByLine = styled.div`
  label {
    color: ${({ theme }) => theme.colors.headerGrey};
    font-size: 14px;
    font-style: italic;
  }
  input {
    color: ${({ theme }) => theme.colors.white};
    font-size: 14px;
  }
`;

export const Bio = styled.div`
  label {
    margin-bottom: 11px;
    color: ${({ theme }) => theme.colors.headerGrey};
    font-size: 14px;
    font-style: italic;
  }
  textarea {
    border: 2px solid ${({ theme }) => theme.colors.defaultPlaceholder};
    border-radius: 10px;
    height: 94px;
    padding: 10px 15px;
    background-color: ${({ theme }) => theme.colors.transparent};
    width: 100%;
    margin-bottom: 20px;
    resize: none;
    outline: none;

    &::placeholder {
      color: ${({ theme }) => theme.colors.white_6};
      font-style: italic;
    }
  }
`;

export const Info = styled.div`
  margin-top: 10px;

  a {
    transition: border-color 0.2s ease;
    border-bottom: 1px solid ${({ theme }) => theme.colors.white_6};

    &:hover,
    &:focus {
      transition: border-color 0.2s ease;
      border-color: ${({ theme }) => theme.colors.white};
    }
  }
`;
