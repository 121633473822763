import styled from 'styled-components';

export const PopupContainer = styled.div`
  top: 0;
  pointer-events: all;
  position: absolute;
  z-index: 24;
  width: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  border: 1px solid ${({ theme }) => theme.colors.hoverGrey};
  padding: 20px;
  border-radius: 0;
  background-color: ${({ theme }) => theme.colors.organizationGreen};

  @media ${({ theme }) => theme.sizes.tablet} {
    top: 50px;
    width: 720px;
    max-height: calc(100vh - 100px);
    left: 50%;
    margin-left: -318px;
    border-radius: 20px;
  }
`;

export const PopupClose = styled.span`
  position: absolute;
  top: 25px;
  right: 25px;
  font-weight: 400;
  font-size: 20px;
  width: 16px;
  height: 16px;
  background-color: ${({ theme }) => theme.colors.transparent};
  opacity: 0.5;
  transition: opacity 0.2s ease-in-out;
  cursor: pointer;
  background-image: url('/assets/images/theme-2/close-black.svg');

  &:hover,
  &:focus {
    opacity: 1;
    transition: opacity 0.2s ease-in-out;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  @media ${({ theme }) => theme.sizes.tablet} {
    justify-content: space-between;
  }
`;

export const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const SelectionHeader = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 10px;
`;

export const SelectionPanel = styled.div`
  display: flex;
  flex-direction: column;
`;

const LinkPanel = styled.div`
  display: flex;
  min-height: 50px;
  border: 2px solid ${({ theme }) => theme.colors.iconButtonBorder};
  border-radius: 10px;
  padding: 10px;
  align-items: center;
  margin: 5px 0;
  justify-content: space-between;
`;

export const EmbedPanel = styled(LinkPanel)`
  align-items: flex-start;

  ${Wrapper} {
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 10px;
  }

  @media ${({ theme }) => theme.sizes.tablet} {
    align-items: center;
  }
`;

export const Embed = styled.div`
  iframe {
    width: 100%;
  }
`;

export const BasicLinkPanel = styled(LinkPanel)`
  align-items: flex-start;

  @media ${({ theme }) => theme.sizes.tablet} {
    align-items: center;
  }
`;

export const CustomLinkPanel = styled(LinkPanel)``;

export const LoadingPanel = styled(LinkPanel)``;

export const LinkText = styled.div`
  display: flex;
  width: 100%;

  @media ${({ theme }) => theme.sizes.tablet} {
    margin-right: 20px;

    input {
      width: 100%;
    }
  }

  div {
    margin: 0;
    width: 100%;
  }

  input {
    font-family: ${({ theme }) => theme.fonts.roboto};
    color: ${({ theme }) => theme.colors.black};
    background-color: ${({ theme }) => theme.colors.white};
    padding: 5px;
    border-radius: 8px;
    border: 1px solid {({ theme }) => theme.colors.black_3};
    font-size: 14px;
    width: 80%;

    &::selection {
      color: ${({ theme }) => theme.colors.white};
      background-color: ${({ theme }) => theme.colors.standardComponentBackground};
    }

    &::placeholder {
      color: ${({ theme }) => theme.colors.black_7};
      font-style: italic;
    }
  }
`;

export const Description = styled.div`
  display: flex;

  @media ${({ theme }) => theme.sizes.tablet} {
    margin-right: 10px;
    white-space: nowrap;
  }
`;

export const PanelHeader = styled.div`
  font-size: 16px;
  font-weight: bold;
`;
