import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ContentEditor from '../../ContentEditor';
import NodeActivityEditor from '../../NodeActivityEditor';
import HandIcon from '../../../../public/assets/images/theme-2/icons/hand.svg';
import ToolbarIcon from '../../../../public/assets/images/theme-2/icons/tools.svg';
import CloseToolbarIcon from '../../../../public/assets/images/theme-2/icons/close-modal.svg';
import SecondaryButton from '../../SecondaryButton';
import PrimaryButton from '../../PrimaryButton';
import { ViewSection, HeaderRequestFeedback, AutoSaveStatus, EditorToolbarButton } from '../styles';
import { Questions, Question, SubmitPanel } from '../../NodeContentPreview/styles';

const WrittenQuizContentViewer = ({
  type,
  report,
  setReport,
  data,
  fileService,
  autoSaveStatus,
  integrations,
  panel,
  isTeacher,
}) => {
  const [showToolbar, setShowToolbar] = useState({});

  const onChangeReport = (value, id) => {
    const answers = { ...report.answers, [id]: { body: value, isDraft: true } };
    setReport({ ...report, answers, editorId: id });
  };

  const revokeReport = () => {
    Object.values(report.answers).forEach((answer) => {
      answer.isDraft = true;
    });
    setReport({ ...report, state: 'active', answers: report.answers });
  };

  const submitReport = () => {
    Object.values(report.answers).forEach((answer) => {
      answer.isDraft = false;
    });
    setReport({ ...report, state: 'done', answers: report.answers });
    panel.hide();
  };

  const toggleToolbar = (id) => {
    setShowToolbar({ ...showToolbar, [id]: !showToolbar[id] });
  };

  const toggleComments = (answerId) => {
    document.dispatchEvent(
      new CustomEvent('toggleComments', { detail: { entityId: answerId, entityType: 'quiz_answer' } }),
    );
  };

  if (!report || !data) {
    return null;
  }

  const isEditable = isTeacher || ['blank', 'active'].includes(report.state);

  if (!report.answers) {
    report.answers = {};

    if (!isTeacher) {
      report.QuizAnswers.forEach((answer) => {
        report.answers[`_${answer.question_id}`] = { body: answer.text, uuid: answer.uuid };
      });
    }
  }

  const getAnswer = (question) => {
    if (report.answers && report.answers[`_${question.id}`]) {
      return report.answers[`_${question.id}`];
    } else {
      return {};
    }
  };

  const getAnswerId = (question) => {
    const answer = getAnswer(question);
    return answer.uuid;
  };

  const getAnswerText = (question) => {
    const answer = getAnswer(question);
    return answer.body || '';
  };

  const getViewForQuestion = (question, index) => {
    const editorPanel = (
      <>
        {isEditable && (
          <EditorToolbarButton
            title={i18n.__('editor_tooltip_show_toolbar')}
            onClick={() => toggleToolbar(question.id)}
            showToolbar={showToolbar[question.id]}
            src={showToolbar[question.id] ? CloseToolbarIcon : ToolbarIcon}
          />
        )}
        <ContentEditor
          id={`_${question.id}`}
          type={type}
          panelType={panel.type}
          aria-label={i18n.__('placeholder_description')}
          placeholder={i18n.__('placeholder_description')}
          value={getAnswerText(question)}
          onChange={(value, id) => isEditable && onChangeReport(value, id)}
          integrations={integrations}
          fileService={fileService}
          disabled={!isEditable}
          showToolbar={showToolbar[question.id]}
        />
      </>
    );

    const headerRightPanel = (
      <HeaderRequestFeedback onClick={() => toggleComments(getAnswerId(question))} src={HandIcon} />
    );

    const buttonPanel = <AutoSaveStatus>{autoSaveStatus[`_${question.id}`]}</AutoSaveStatus>;

    return (
      <NodeActivityEditor
        isQuestion={true}
        questionIndex={index}
        headerText={question.body}
        headerRightPanel={headerRightPanel}
        editorPanel={editorPanel}
        buttonPanel={buttonPanel}
      />
    );
  };

  const questions = data.QuizQuestions.map((question, index) => (
    <Question key={question.id}>{getViewForQuestion(question, index)}</Question>
  ));

  return (
    <>
      <ViewSection>
        <Questions>{questions}</Questions>
      </ViewSection>
      <SubmitPanel>
        {isEditable ? (
          <SecondaryButton text={i18n.__('app_handin')} onClick={submitReport} disabled={isTeacher} />
        ) : (
          <PrimaryButton text={i18n.__('app_edit')} onClick={revokeReport} />
        )}
      </SubmitPanel>
    </>
  );
};

export default WrittenQuizContentViewer;

WrittenQuizContentViewer.propTypes = {
  type: PropTypes.string.isRequired,
  report: PropTypes.object,
  data: PropTypes.object,
  fileService: PropTypes.object.isRequired,
  panel: PropTypes.object.isRequired,
  integrations: PropTypes.object.isRequired,
  autoSaveStatus: PropTypes.object.isRequired,
  isTeacher: PropTypes.bool.isRequired,
};
