import styled, { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  body {
    .cursor {
      display: none;
    }
    
    &:hover,
    &:focus {
      .cursor {
        display: none;
      }
    }
  }
`;

export const PopupContainer = styled.div`
  top: 0;
  pointer-events: all;
  position: absolute;
  z-index: 24;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  border: 1px solid ${({ theme }) => theme.colors.hoverGrey};
  padding: 20px;
  border-radius: 0;
  background-color: ${({ theme }) => theme.colors.organizationGreen};

  @media ${({ theme }) => theme.sizes.desktop} {
    top: 50px;
    left: 50px;
    width: calc(100% - 100px);
    height: calc(100% - 100px);
    border-radius: 20px;
  }
`;

export const PopupClose = styled.span`
  position: absolute;
  top: 25px;
  right: 25px;
  font-weight: 400;
  font-size: 20px;
  width: 16px;
  height: 16px;
  background-color: ${({ theme }) => theme.colors.transparent};
  opacity: 0.5;
  z-index: 1;
  transition: opacity 0.2s ease-in-out;
  cursor: pointer;
  background-image: url('/assets/images/theme-2/close-black.svg');

  &:hover,
  &:focus {
    opacity: 1;
    transition: opacity 0.2s ease-in-out;
  }
`;

export const Wrapper = styled.div`
  display: flex;
`;

export const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;

  .canvas {
    border-width: 2px !important;
    cursor: none;
  }
`;

export const Header = styled.h1`
  font-size: 24px;
  font-weight: bold;
  margin: 10px 0 20px;
`;

export const ToolBar = styled.div`
  display: flex;
  flex-direction: column;
  width: 200px;
  padding: 0 20px;

  label:not(:first-of-type),
  button {
    margin-top: 20px;
  }

  input,
  button {
    color: ${({ theme }) => theme.colors.black_9};
    border-color: ${({ theme }) => theme.colors.black_9};

    &:hover:not(&:disabled),
    &:focus:not(&:disabled) {
      color: ${({ theme }) => theme.colors.black};
      border-color: ${({ theme }) => theme.colors.black};
    }
  }

  button {
    width: 100%;

    &:hover:not(&:disabled),
    &:focus:not(&:disabled) {
      background-color: ${({ theme }) => theme.colors.white};
    }
  }

  .icon {
    padding: 20px;
    margin-top: 0;
    border-radius: 50%;
    z-index: 0;

    &:hover:not(&:disabled),
    &:focus:not(&:disabled) {
      background-color: ${({ theme }) => theme.colors.white};
    }

    &:after {
      left: 10px;
      top: 10px;
      margin-top: 0;
    }
  }

  .swatch {
    border-radius: 50%;
    width: 44px;
    height: 44px;
  }

  > .width {
    margin: 0 10px 20px;
    width: calc(100% - 20px);
  }

  .color-picker {
    left: initial;
    right: 0;
  }
`;

export const SpaceWrapper = styled(Wrapper)`
  justify-content: space-around;
  margin-bottom: 20px;

  label {
    color: ${({ theme }) => theme.colors.black_9};
  }
`;

export const CanvasWrapper = styled.div`
  width: 100vw;
  height: calc(100vh - 130px);

  @media ${({ theme }) => theme.sizes.desktop} {
    height: calc(100vh - 230px);
  }

  &:hover,
  &:focus {
    .cursor {
      display: block;
    }
  }
`;

export const Cursor = styled.div`
  position: fixed;
  top: -100;
  left: -100;
  width: ${({ width }) => `${width}px`};
  height: ${({ width }) => `${width}px`};
  background-color: ${({ color }) => color};
  border-radius: 100%;
  transform: translate(-50%, -50%);
  z-index: 999;
  border: 1px solid ${({ theme }) => theme.colors.black_9};
  pointer-events: none;
`;
