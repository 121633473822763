import Remote from './remote';

const Embedder = {
  url: {
    base: null,
    path: null,
  },

  getRemote: function () {
    if (!this.remote) {
      let embed_service = (window.editor || window.viewer || window.tp).options.embed_service.url;
      let parts = embed_service.match(/(http[s]?:\/\/[^\/]+?)\/(.*)/);
      this.url.base = parts[1];
      this.url.path = parts[2];
      this.remote = new Remote(this.url.base);
    }
    return this.remote;
  },
  embed: function (url) {
    return this.getRemote().get(this.url.path, {}, { url: url });
  },
};

export default Embedder;
