import styled from 'styled-components';

export const Wrapper = styled.div`
  background-color: {({ theme }) => theme.colors.black_8};
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-end;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 20;
`;

export const PopupClose = styled.span`
  position: absolute;
  top: 24px;
  right: 30px;
  font-weight: 400;
  font-size: 20px;
  width: 18px;
  height: 18px;
  background-color: ${({ theme }) => theme.colors.transparent};
  opacity: 0.7;
  transition: opacity 0.2s ease-in-out;
  cursor: pointer;
  background-image: url('/assets/images/theme-2/close-white.svg');

  &:hover,
  &:focus {
    opacity: 1;
    transition: opacity 0.2s ease-in-out;
  }
`;

export const RecorderWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.transparent};
  border-radius: 10px;
  flex: 1;
  top: 400px;
  width: 100%;

  .camera_tag {
    background: ${({ theme }) => theme.colors.black};
    border-radius: 10px;
    display: block;
    font-family: Roboto, sans-serif;
    position: relative;
    top: 200px;
    width: 100% !important;
  }
`;

export const OuterScreenWrapper = styled.div``;

export const ScreenWrapper = styled.div`
  background: ${({ theme }) => theme.colors.black} !important;
  border-radius: 10px;
  display: none;

  &.transparent {
    background: transparent !important;
  }
`;

export const RecorderFlexCenterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  cursor: pointer;
  height: 100%;
`;

export const RecorderFlexBottomWrapper = styled(RecorderFlexCenterWrapper)`
  justify-content: flex-end;
`;

export const RecorderIndicator = styled.div`
  background-image: url(/assets/images/theme-2/loops-icon-white.svg);
  background-size: 15px 20px;
  margin: 16px 0px auto 15px;
  height: 20px;
  background-repeat: no-repeat;

  @keyframes blink {
    50% {
      opacity: 0;
    }
  }
`;

export const RecorderIndicatorLED = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin: 6px 0px 0px 20px;
  background-color: ${({ theme }) => theme.colors.orange};
  animation: blink 2s step-start 0s infinite;
`;

export const RecorderToolbar = styled.div`
  height: 50px;
  background-color: ${({ theme }) => theme.colors.progressBarGreen};
  border-radius: 0px 0px 10px 10px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
`;

export const RecorderDuration = styled.time``;

export const RecorderWaitIndicator = styled.div`
  background-image: url(/assets/images/theme-2/loops-icon-white.svg);
  background-size: 15px 20px;
  width: 15px;
  height: 20px;
  align-self: center;
  margin: 0px 0px 25px 0px;
  background-repeat: no-repeat;
  animation: rotating 2s linear infinite;
`;

export const RecorderToolbarItem = styled.button`
  outline: none;
  border: none;
  background-color: ${({ theme }) => theme.colors.transparent};
`;

export const RecorderWaitMessage = styled.p`
  margin: 0px;
`;

export const MediaProcessing = styled.div`
  &__icon {
      background-image: url(/assets/images/loaders/loader.gif);
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;
      height: 50px;
      width: 50px;
      margin: 20px;

      .circle-pane & {
        margin-top: 0;
        height: 40px;
        width: 40px;
      }
    }

    &__save-text {
      display: none;

      .pr2--paneEditMode & {
        display: block;
      }
    }
  }
`;

export const MediaProcessingIcon = styled.div`
  background-image: url(/assets/images/loaders/loader.gif);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  height: 50px;
  width: 50px;
  margin: 20px auto;
`;

export const MediaProcessingText = styled.div``;

export const RecorderToolbarStop = styled(RecorderToolbarItem)`
  margin: 0px 0px 0px 20px;
`;
