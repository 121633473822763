import styled, { css } from 'styled-components';

export const ImageSelectorWrapper = styled.div`
  margin: 0;
  display: flex;
  align-items: center;

  ${({ whiteTheme }) =>
    whiteTheme &&
    css`
      button {
        &:hover:not([disabled]),
        &:focus:not([disabled]) {
          background-color: ${({ theme }) => theme.colors.standardTextGrey};
        }
      }
    `}
`;
