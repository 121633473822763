import React from 'react';

import { ViewSection } from '../styles';

const ChunkContentTeacherPanel = () => {
  return <ViewSection></ViewSection>;
};

export default ChunkContentTeacherPanel;

ChunkContentTeacherPanel.propTypes = {};
