import React, { useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { InputFieldWrapper, InputRadioButton, RadioButtonLabel, Icon, Label } from './styles';

const RadioButton = ({ id, name, text, value, defaultChecked, onClick, tabIndex = 0, ...rest }) => {
  const radiobuttonRef = useRef(null);
  const triggerClick = useCallback(() => {
    radiobuttonRef.current.click();
  });
  const onClickHandler = (ev) => {
    ev.target.parentNode.blur();
    onClick(ev);
  };

  return (
    <InputFieldWrapper>
      <RadioButtonLabel tabIndex={tabIndex} onKeyPress={triggerClick} htmlFor={id || value}>
        <InputRadioButton
          ref={radiobuttonRef}
          name={name}
          id={id || value}
          value={value}
          type="radio"
          defaultChecked={defaultChecked}
          onClick={onClickHandler}
          {...rest}
        />
        <Icon />
        <Label>{text}</Label>
      </RadioButtonLabel>
    </InputFieldWrapper>
  );
};

RadioButton.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  defaultChecked: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  tabIndex: PropTypes.number,
  rest: PropTypes.object,
};

export default RadioButton;
