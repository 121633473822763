import Quill from '../../../app/assets/js/shared/components/quill.extended';

const Delta = Quill.import('delta');

const lineBreakMatcher = () => {
  var newDelta = new Delta();
  newDelta.insert({ break: '' });
  return newDelta;
};

const clipboard = {
  matchers: [['BR', lineBreakMatcher]],
  matchVisual: false,
};

export default clipboard;
