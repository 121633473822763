import React from 'react';
import Popup from '../Popup';
import PrimaryButton from '../PrimaryButton';
import SecondaryButton from '../SecondaryButton';

import { isWindows } from '../../utils';

import { Container, Wrapper, TextBlock, ButtonWrapper } from './styles';

const ConfirmPopup = ({ popupIsOpen, message, title, onConfirm, onCancel, onClose = onCancel }) => {
  const contentPanel = (
    <Container>
      <Wrapper>
        <TextBlock dangerouslySetInnerHTML={{ __html: message }} />
      </Wrapper>
    </Container>
  );

  const buttons = [
    <PrimaryButton
      key={0}
      text={i18n.__('app_cancel')}
      onClick={() => {
        if (onCancel) {
          onCancel();
        }
      }}
    />,
    <SecondaryButton key={1} text={i18n.__('app_ok')} onClick={() => onConfirm()} />,
  ];
  const buttonList = isWindows() ? [...buttons].reverse() : buttons;

  const buttonPanel = <ButtonWrapper>{buttonList}</ButtonWrapper>;

  return (
    <Popup
      popupIsOpen={popupIsOpen}
      heading={title ? title : i18n.__('app_confirm')}
      id="confirm-popup"
      contentPanel={contentPanel}
      buttonPanel={buttonPanel}
      onClose={onClose}
    />
  );
};

export default ConfirmPopup;
