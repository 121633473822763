import styled, { css } from 'styled-components';

const Icon = css`
  content: '';
  width: 16px;
  height: 16px;
  background-repeat: no-repeat;
  background-position: center;

  &:disabled {
    opacity: 0.5;
  }

  ${({ icon }) =>
    icon &&
    css`
      background-image: url(${icon});
    `}
`;

const ButtonIcon = styled.div`
  display: flex;
  align-items: center;
`;

export const ButtonIconLeft = styled(ButtonIcon)`
  button::before {
    ${Icon}
    margin-left: -5px;
    margin-right: 5px;
    transform: rotate(-90deg);
  }
`;

export const ButtonIconRight = styled(ButtonIcon)`
  button::after {
    ${Icon}
    margin-left: 5px;
    margin-right: -5px;
    transform: rotate(90deg);
  }
`;
