import React from 'react';
import PropTypes from 'prop-types';
import { ProgressBarWrapper, Progress } from './styles';

const ProgressBar = ({ progress, progressColor, highlightOverflow, width, height, ...rest }) => {
  return (
    <ProgressBarWrapper width={width} height={height}>
      <Progress
        progress={progress}
        highlightOverflow={highlightOverflow}
        progressColor={progressColor}
        {...rest}
      ></Progress>
    </ProgressBarWrapper>
  );
};

ProgressBar.propTypes = {
  progress: PropTypes.number.isRequired,
  progressColor: PropTypes.string,
  highlightOverflow: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
  rest: PropTypes.object,
};

export default ProgressBar;
