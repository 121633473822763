import styled, { css } from 'styled-components';

export const InputFieldWrapper = styled.div`
  display: inline-block;
  margin-top: ${({ top }) => (top ? `${top}px` : 0)};
`;

export const InputCheckBox = styled.input`
  display: none;

  &:checked ~ div:first-of-type {
    background-image: url(/assets/images/icons/checkbox-selected.svg);
  }

  &:disabled ~ div:first-of-type {
    filter: grayscale(1);
  }
`;

export const Icon = styled.div`
  background-image: url(/assets/images/icons/checkbox-unselected.svg);
  background-position: 0 4px;
  background-repeat: no-repeat;
  background-size: 14px 14px;
  content: '';
  padding-left: ${({ noLabel }) => (noLabel ? '14px' : '24px')};
  min-height: 18px;
  display: inline-block;
`;

export const Label = styled.span`
  border: 1px solid ${({ theme }) => theme.colors.transparent};
  transition:
    color 0.2s ease,
    border-bottom 0.2s ease;
`;

export const CheckBoxLabel = styled.label`
  margin-right: ${({ noLabel }) => (noLabel ? 'none' : '30px')};
  display: flex;
  color: ${({ theme, lightMode }) => (lightMode ? theme.colors.black_9 : theme.colors.standardTextGrey)};
  font-weight: normal;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};

  ${({ tabIndex }) =>
    tabIndex === 0 &&
    css`
      &:hover,
      &:focus {
        ${Label} {
          color: ${({ theme, lightMode }) => (lightMode ? theme.colors.black : theme.colors.white)};
          border-bottom: 1px solid ${({ theme }) => theme.colors.white};
          transition:
            color 0.2s ease,
            border-bottom 0.2s ease;
        }
      }
    `}
`;
