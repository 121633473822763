import React from 'react';
import PropTypes from 'prop-types';
import { ErrorMessageWrapper } from './styles';

const ErrorMessage = ({ message, ...rest }) => {
  return <ErrorMessageWrapper {...rest}>{message}</ErrorMessageWrapper>;
};

ErrorMessage.propTypes = {
  message: PropTypes.string.isRequired,
  rest: PropTypes.object,
};

export default ErrorMessage;
