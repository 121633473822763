import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { InputFieldWrapper, InputTextField, TextFieldLabel } from './styles';

const TextField = forwardRef(
  (
    {
      id,
      name = id,
      type = 'text',
      label,
      placeholder,
      defaultValue,
      onChange,
      onKeyPress,
      autocomplete = 'off',
      children,
      className,
      autoFocus,
      ...rest
    },
    ref,
  ) => {
    const textLabel = label ? <TextFieldLabel htmlFor={id}>{label}</TextFieldLabel> : null;
    return (
      <InputFieldWrapper className={className}>
        {textLabel}
        <InputTextField
          autoFocus={autoFocus}
          autoComplete={autocomplete}
          id={id}
          ref={ref}
          name={name}
          type={type}
          defaultValue={defaultValue}
          placeholder={placeholder}
          onChange={onChange}
          onKeyPress={onKeyPress}
          {...rest}
        />
        {children}
      </InputFieldWrapper>
    );
  },
);

TextField.displayName = 'TextField';

TextField.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  type: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func,
  onKeyPress: PropTypes.func,
  autocomplete: PropTypes.string,
  children: PropTypes.node,
  autoFocus: PropTypes.bool,
  rest: PropTypes.object,
};

export default TextField;
