import styled, { css } from 'styled-components';
import { animated } from '@react-spring/web';

export const TopPanelWrapper = styled.section`
  color: ${({ theme }) => theme.colors.white};
  height: 60px;
  display: flex;
  z-index: ${({ isMenuOpen }) => (isMenuOpen ? '25' : '7')};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  align-items: center;
  justify-content: space-between;
  padding-right: 0;

  .show-mask & {
    z-index: 0;
  }

  .show-mask.scrollbar & {
    padding-right: 16px;
  }

  background: ${({ theme, horizon }) =>
    horizon ? theme.colors.darkHorizonBackground : theme.colors.standardBlackBackground};
`;

export const LogoPanelWrapper = styled.div`
  margin: 0 20px;
  display: flex;
  height: 100%;
  align-items: center;
`;

export const LogoPanel = styled.div`
  cursor: pointer;
  display: inline-block;
  background-image: url('/assets/images/theme-2/loops-product-logo.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 113px;
  height: 42px;
  transform: scale(1);
  transition: transform 0.2s ease;

  &:hover,
  &:focus {
    transform: scale(1.1);
    transition: transform 0.2s ease;
  }
`;

export const LoopName = styled.h1`
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
  font-weight: 700;
  padding: 5px 0;
  border-bottom: 2px solid ${({ theme }) => theme.colors.transparent};
  transition: border-color 0.2s ease;

  @media ${({ theme }) => theme.sizes.desktop} {
    font-size: 22px;
  }
`;

export const LoopPanel = styled.div`
  display: flex;
  align-items: center;

  &:hover,
  &:focus {
    ${LoopName} {
      border-color: ${({ theme }) => theme.colors.white};
      transition: border-color 0.2s ease;
    }
  }
`;

export const LoopIcon = styled.div`
  @media ${({ theme }) => theme.sizes.desktop} {
    background-image: ${({ icon }) => `url(${icon})`};
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 42px;
    height: 42px;
    cursor: pointer;
    border-radius: 50%;
    margin: 0 15px 0 30px;
    position: relative;
    flex-shrink: 0;
  }
`;

export const LoopPanelIcon = styled.img`
  height: 20px;
  cursor: pointer;
  padding-left: 10px;

  @media ${({ theme }) => theme.sizes.desktop} {
    padding-left: 20px;
  }
`;

export const ProgressWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 20px;

  @media ${({ theme }) => theme.sizes.desktop} {
    margin-left: 40px;
    margin-top: 4px;
  }
`;

export const ProgressText = styled.span`
  margin-left: 10px;
  font-size: 14px;
  font-weight: bold;
`;

export const AutoSaveWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 20px;
  white-space: nowrap;

  label {
    margin-right: 10px;
  }

  @media ${({ theme }) => theme.sizes.desktop} {
    margin-left: 40px;
    margin-top: 5px;

    label {
      margin-right: 20px;
    }
  }
`;

export const AutoSaveStatus = styled.div`
  display: flex;
  align-items: center;
`;

export const TeacherPanelButton = styled.a`
  background-image: ${({ icon }) => `url(${icon})`};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin-left: 10px;
  width: 26px;
  height: 26px;
  cursor: pointer;
  align-self: center;
  display: block;
  flex-shrink: 0;
  transform: scale(1);
  transition: transform 0.2s ease;

  @media ${({ theme }) => theme.sizes.desktop} {
    margin-left: 20px;
  }

  &:hover,
  &:focus {
    transform: scale(1.2);
    transition: transform 0.2s ease;
  }
`;

export const MenuPanelWrapper = styled.div`
  display: none;

  @media ${({ theme }) => theme.sizes.tablet} {
    display: flex;
    height: 100%;
    align-items: center;
  }
`;

export const MenuPanel = styled.div`
  display: flex;
  opacity: ${({ isMenuOpen }) => (isMenuOpen ? '1' : '0')};
  pointer-events: ${({ isMenuOpen }) => (isMenuOpen ? 'all' : 'none')};
  transition: opacity 0.3s ease-in-out;
  align-items: center;
  font-weight: bold;
  margin: 0;
  top: 60px;
  left: 0;
  background: ${({ theme }) => theme.colors.black};
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  height: ${({ isMenuOpen }) => (isMenuOpen ? '100%' : '60px')};
  position: fixed;

  @media ${({ theme }) => theme.sizes.tablet} {
    position: initial;
    pointer-events: all;
    opacity: 1;
    height: 60px;
    margin: 0 20px;
    align-items: center;
    flex-direction: row;
    background: ${({ theme }) => theme.colors.transparent};
  }
`;

export const HamburgerMenuWrapper = styled.div`
  display: flex;
  background-image: url('/assets/images/theme-2/icons/menu.svg');
  background-size: contain;
  width: 32px;
  height: 32px;
  flex-shrink: 0;
  border: 1.5px solid ${({ theme, isMenuOpen }) => (isMenuOpen ? theme.colors.buttonGreenFront : theme.colors.white)};
  border-radius: 50%;
  margin-right: 10px;
  cursor: pointer;

  ${MenuPanel} {
    overscroll-behavior: contain;
    overflow-y: auto;
  }

  @media ${({ theme }) => theme.sizes.tablet} {
    display: none;
  }
`;

const MenuItem = styled.div`
  font-size: 18px;
  display: flex;
  align-items: center;
  position: relative;
  height: 60px;
  width: calc(100% - 3px);
  margin-left: 1px;
  margin-top: 5px;
  padding: 11px 20px;
  border: 1.5px solid
    ${({ selected, theme }) => (selected ? theme.colors.buttonGreenFront : theme.colors.menuButtonBorder)};
  border-radius: 9px;
  cursor: pointer;
  opacity: ${({ selected }) => (selected ? 1 : 0.8)};
  transition:
    opacity 0.3s ease-in-out,
    border-color 0.3s ease-in-out;
  background: ${({ theme }) => theme.colors.standardBlackBackground};

  :hover,
  :focus {
    opacity: 1;
    border: 1.5px solid ${({ theme }) => theme.colors.buttonGreenFront};
    transition:
      opacity 0.3s ease-in-out,
      border-color 0.3s ease-in-out;
  }

  @media ${({ theme }) => theme.sizes.tablet} {
    height: 50px;
    width: initial;
    font-size: 14px;
    margin-left: 0;
    margin-right: 15px;
    margin-top: 0;
    background: ${({ theme }) => theme.colors.transparent};
  }
`;

export const ButtonMenuItem = styled(MenuItem)`
  ${({ icon }) =>
    icon &&
    css`
      background-image: url(${icon});
      background-repeat: no-repeat;
      background-size: 20px;
      background-position: 20px 16px;
      padding-left: 45px;

      @media ${({ theme }) => theme.sizes.tablet} {
        background-size: 18px;
        background-position: 18px 13px;
        padding-left: 45px;
      }
    `}
`;

export const DropDownMenuItem = styled(MenuItem)`
  display: flex;
  margin-right: 15px;
  padding-right: 42px;

  :after {
    background-image: url('/assets/images/icons/arrow-down.svg');
    width: 12px;
    height: 7px;
    content: ' ';
    position: absolute;
    left: 70px;
    right: auto;

    @media ${({ theme }) => theme.sizes.tablet} {
      right: 20px;
      left: auto;
    }
  }
`;

export const OrganizationDropDownMenuItem = styled(DropDownMenuItem)`
  font-size: 19px;
  padding-left: 55px;

  @media ${({ theme }) => theme.sizes.desktop} {
    padding-left: 45px;
  }

  :before {
    background-image: ${({ icon }) => `url(${icon})`};
    background-size: cover;
    background-repeat: no-repeat;
    width: 36px;
    height: 36px;
    content: ' ';
    position: absolute;
    left: 20px;
    border-radius: 50%;
  }
`;

export const OrganizationName = styled.span`
  display: flex;
  margin-left: 80px;
  pointer-events: none;

  @media ${({ theme }) => theme.sizes.tablet} {
    display: none;
    margin-left: 20px;
  }

  @media ${({ theme }) => theme.sizes.desktop} {
    display: flex;
  }
`;

export const NotificationDropDownMenuItem = styled(DropDownMenuItem)`
  align-items: center;
  display: flex;

  ::before {
    content: ' ';
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-right: 10px;
    background-color: ${({ theme }) => theme.colors.white_5};
    border-radius: 50%;
  }

  ${({ hasUnreadNotifications }) =>
    hasUnreadNotifications &&
    css`{
      :before {
        background-color: ${({ theme }) => theme.colors.notification};
      }
    `}
  }

  ::after {
    position: relative;
    left: auto;
    margin-left: 16px;
  }

  @media ${({ theme }) => theme.sizes.tablet} {
    :after {
      position: absolute;
      right: 20px;
    }
  }
`;

export const NotificationWrapper = styled(animated.div)`
  width: 100%;
  top: 60px;
  left: 0;

  background-color: ${({ theme }) => theme.colors.white};
  display: block;
  position: absolute;
  right: -25px;
  cursor: pointer;
  pointer-events: all;
  border: 1px solid ${({ theme }) => theme.colors.greyBorder};
  border-radius: 10px;
  z-index: 12;

  @media ${({ theme }) => theme.sizes.tablet} {
    width: 400px;
    top: 57px;
    left: auto;
  }
`;

export const ListWrapper = styled.div``;

export const NotificationsWrapper = styled.div`
  overflow-y: hidden;
  border-radius: 0 0 10px 10px;
`;

export const NotificationContent = styled.ul`
  max-height: 280px;
  overscroll-behavior: contain;
  overflow-y: auto;
  color: ${({ theme }) => theme.colors.black_9};
  font-size: 14px;
  font-weight: 400;

  @media ${({ theme }) => theme.sizes.tablet} {
    max-height: 556px;
  }
`;

export const NotificationItem = styled.li`
  position: relative;
  border-bottom: 1px solid ${({ theme }) => theme.colors.greyBorder};
  padding: 22px 27px;
  background-color: ${({ read, theme }) => (read ? theme.colors.white : theme.colors.notificationBackground)};
  transition: filter 0.2s ease-in-out;
  filter: brightness(1);
  display: flex;
  align-items: center;

  ${({ read }) =>
    !read &&
    css`{
      :before {
        content: ' ';
        position: absolute;
        width: 8px;
        height: 8px;
        background-color: ${({ theme }) => theme.colors.notification};
        left: 10px;
        top: calc(50% - 4px);
        border-radius: 50%;
      }
    `}
  }

  &:hover, &:focus {
    filter: brightness(0.9);
    transition: filter 0.2s ease-in-out;
  }
`;

export const MarkAllAsRead = styled.div`
  text-align: center;
  color: ${({ theme }) => theme.colors.black_9};
  font-size: 14px;
  font-weight: 700;
  padding: 10px;
  border-radius: 10px 10px 0 0;
  border-bottom: 2px solid ${({ theme }) => theme.colors.greyBorder};
  background-color: ${({ theme }) => theme.colors.white};
  filter: brightness(1);
  transition: filter 0.2s ease-in-out;

  :after {
    display: none;
  }

  @media ${({ theme }) => theme.sizes.tablet} {
    :after {
      display: flex;
      top: -19px;
      right: 50px;
      content: ' ';
      height: 0px;
      width: 0px;
      position: absolute;
      pointer-events: none;
      margin-right: -11px;
      border: 11px solid;
      border-color: transparent transparent ${({ theme }) => theme.colors.white};
    }
  }

  :hover,
  :focus {
    filter: brightness(0.9);
    transition: filter 0.2s ease-in-out;
  }
`;

export const NoNotifications = styled.div`
  text-align: center;
  color: ${({ theme }) => theme.colors.black_9};
  padding: 10px;
  font-weight: 400;
  margin: 20px 0;

  :after {
    top: -19px;
    right: 50px;
    content: ' ';
    height: 0px;
    width: 0px;
    position: absolute;
    pointer-events: none;
    margin-right: -11px;
    border: 11px solid;
    border-color: transparent transparent ${({ theme }) => theme.colors.white};
  }
`;

export const LoadingNotifications = styled(NoNotifications)``;

export const NotificationAvatar = styled.div`
  background-image: ${({ icon }) => `url(${icon})`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  flex-shrink: 0;
`;

export const NotificationInfo = styled.div`
  margin-left: 16px;
`;

export const NotificationBody = styled.div``;

export const NotificationTimestamp = styled.div`
  color: ${({ theme }) => theme.colors.black_7};
  font-style: italic;
`;

export const OrganizationWrapper = styled(animated.div)`
  width: 100%;
  top: 60px;
  left: 0;

  background-color: ${({ theme }) => theme.colors.white};
  display: block;
  position: absolute;
  right: -25px;
  cursor: pointer;
  pointer-events: all;
  border-radius: 10px;
  z-index: 12;

  @media ${({ theme }) => theme.sizes.tablet} {
    width: 400px;
    top: 42px;
    left: auto;
  }

  @media ${({ theme }) => theme.sizes.desktop} {
    top: 57px;
  }
`;

export const CurrentOrganization = styled.ul`
  color: ${({ theme }) => theme.colors.black_9};
  font-weight: 400;
  max-height: 400px;
  overflow-y: auto;
  overscroll-behavior: contain;
`;

export const OrganizationsWrapper = styled.div`
  overflow: hidden;
  border-radius: 0 0 10px 10px;
`;

export const OrganizationContent = styled.ul`
  max-height: 120px;
  overscroll-behavior: contain;
  overflow-y: auto;
  color: ${({ theme }) => theme.colors.black_9};
  font-size: 14px;
  font-weight: 400;
  border-top: 1px solid ${({ theme }) => theme.colors.organizationGreenBorder};

  @media ${({ theme }) => theme.sizes.tablet} {
    max-height: 250px;
  }
`;

export const Panel = styled.li`
  background-color: ${({ theme }) => theme.colors.white};
  height: 80px;
  padding: 0 18px 0 43px;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-bottom: 1px solid ${({ theme }) => theme.colors.greyBorder};
  transition: filter 0.2s ease-in-out;
  filter: brightness(1);

  :hover,
  :focus {
    filter: brightness(0.9);
    transition: filter 0.2s ease-in-out;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Header = styled.span`
  font-size: 19px;
  font-weight: bold;
`;

export const Roles = styled.span`
  font-size: 12px;
`;

export const OrganizationHeader = styled(Panel)`
  background-color: ${({ theme }) => theme.colors.organizationGreen};
  border-color: ${({ theme }) => theme.colors.organizationGreenBorder};
  padding-left: 101px;
  justify-content: space-between;

  
    :before {
      background-image: ${({ icon }) => `url(${icon})`};
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      width: 42px;
      height: 42px;
      content: ' ';
      position: absolute;
      top: 19px;
      margin-left: -58px;
      border-radius: 50%;
    }

  ${({ isFirst }) =>
    isFirst &&
    css`
       {
        border-radius: 10px 10px 0 0;

        :after {
          display: none;
        }

        @media ${({ theme }) => theme.sizes.tablet} {
          :after {
            display: block;
            top: -19px;
            right: 51px;
            content: ' ';
            height: 0px;
            width: 0px;
            position: absolute;
            pointer-events: none;
            margin-right: -11px;
            border: 11px solid;
            border-color: transparent transparent ${({ theme }) => theme.colors.organizationGreen};
          }
        }
    `}
  }

  ${({ isLast }) =>
    isLast &&
    css`{
      border-radius: 0 0 10px 10px;
    `}
  }

  ${({ isTrial }) =>
    isTrial &&
    css`{
      background-color: ${({ theme }) => theme.colors.greyBorder};

      @media ${({ theme }) => theme.sizes.tablet} {
        :after {
          border-color: transparent transparent ${({ theme }) => theme.colors.greyBorder};
        }
        }
    `}
  }
`;

export const Text = styled.div`
  font-size: 14px;
  line-height: 27px;
`;

export const OrganizationLibraryWrapper = styled(Panel)``;

export const LibraryHeader = styled(Text)``;

export const LoopCount = styled(Text)``;

export const OrganizationAdministrationWrapper = styled(Panel)`
  ${({ isLast }) =>
    isLast &&
    css`{
      border-radius: 0 0 10px 10px;
    `}
  }
`;

export const AdministrationHeader = styled(Text)``;

export const ParticipantCount = styled(Text)``;

export const OrganizationSettings = styled(Panel)`
  font-size: 14px;
  
  ${({ isLast }) =>
    isLast &&
    css`{
      border-radius: 0 0 10px 10px;
    `}
  }
`;

export const OrganizationTrialEnded = styled(Panel)`
  font-size: 14px;
  background-color: ${({ theme }) => theme.colors.organizationPurple};
`;

export const TrialDaysWrapper = styled.div`
  width: 94px;
  height: 51px;
  border: 2px solid ${({ theme }) => theme.colors.trialBorder};
  border-radius: 16px;
  display: flex;
  color: ${({ theme }) => theme.colors.black};
  align-items: center;
  text-align: center;
`;

export const TrialDays = styled.div`
  border: 2px solid ${({ theme }) => theme.colors.trialBorder};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: bold;
  margin-left: 9px;
  flex-shrink: 0;
  width: 32px;
  height: 32px;
`;

export const TrialDaysText = styled.div`
  font-size: 12px;
  font-weight: normal;
  padding: 5px;
`;
