import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '../../IconButton';
import CheckIcon from '../../../../public/assets/images/theme-2/icons/handin-hover.svg';
import { PreviewSection, ChunkButtonWrapper } from '../styles';
import { PreviewText } from '../../NodeActivityEditor/styles';

const ChunkContentPreview = ({ previewText }) => {
  return (
    <PreviewSection>
      <ChunkButtonWrapper>
        <IconButton icon={CheckIcon} disabled={true}>
          {i18n.__('app_done')}
        </IconButton>
      </ChunkButtonWrapper>
      <PreviewText dangerouslySetInnerHTML={{ __html: previewText }} />
    </PreviewSection>
  );
};

export default ChunkContentPreview;

ChunkContentPreview.propTypes = {
  previewText: PropTypes.string.isRequired,
};
