import './shared/global_shims';
import './shared/templates';
import './tooltips';
import Paywall from './shared/paywall';
import Utils from './shared/utils';
import CollectionAdder from './shared/components/collection_adder';
import Authenticate from './shared/authenticate';
import routie from '../libs/vendor/routie/routie';
import NotificationCenter from './notifications';
import Uploader from './shared/uploader';
import PurpleGuideRenderer, { BottomPanelRenderer, TopPanelRenderer } from './shared/reactLoader';
import { CategoryPicker } from './shared/components/category_picker';
import 'canvallax/dist/Canvallax.js';
import * as microsoftTeams from '@microsoft/teams-js';
import i18n, { mustacheProcessor, __ } from 'i18n-for-browser';
import { checkInTeams } from '../../../src/utils/teams';
import { syncConfirmPopup } from './shared/reactLoader';
import flash from './shared/flash';

window.NotificationCenter = NotificationCenter;
window.CategoryPicker = CategoryPicker;
window.Utils = Utils;
window.Uploader = Uploader;
window.PurpleGuideRenderer = PurpleGuideRenderer;
window.BottomPanelRenderer = BottomPanelRenderer;
window.TopPanelRenderer = TopPanelRenderer;
window.Authenticate = Authenticate;
window.i18n = i18n;
window.syncConfirmPopup = syncConfirmPopup;

if ('ontransitionend' in window) {
  window.transition_event_name = 'transitionend';
} else if ('onwebkittransitionend' in window) {
  window.transition_event_name = 'webkitTransitionEnd';
} else if ('onotransitionend' in document.createElement('div') || navigator.appName == 'Opera') {
  window.transition_event_name = 'oTransitionEnd';
} else {
  window.transition_event_name = 'transitionend';
}
const classChildren = (x) => document.getElementsByClassName(`navbar-${x}`)[0];

const locale = $(document.currentScript).attr('data-locale');
window.locale = locale;
window.baseLocale = $('body').attr('data-user-base-locale') || locale;
const lang = window.baseLocale.split('-')[0];
if (lang !== 'en') {
  window.datepickerLocale = require(`jquery-ui/ui/i18n/datepicker-${lang}`);
}

const currentLocale = require(`./shared/locales/${locale}.json`);
i18n.__ = __;
i18n.configure({
  locales: { [locale]: currentLocale },
  defaultLocale: locale,
  objectNotation: true,
  processors: [mustacheProcessor],
});

$(function () {
  Paywall.setup();

  if (classChildren('toggle')) {
    classChildren('toggle').addEventListener(
      'click',
      () => {
        classChildren('collapse').classList.toggle('collapse');
        classChildren('fullscreen').classList.toggle('fullscreen');
        classChildren('toggle').classList.toggle('active');
      },
      false,
    );
  }

  let loadReadability = (callback = null) => {
    if (checkInTeams && checkInTeams()) {
      const inViewer = document.querySelector('body').classList.contains('viewer');
      if (!inViewer) {
        // in Teams but NOT in viewer, do not load readability css
        return;
      }
    }

    let style = $('<link>')
      .attr({ href: window.readabilityCss, rel: 'stylesheet' })
      .on('load', (ev) => {
        $('body').addClass('readability readability-loaded');
        window.localStorage.setItem('readability', '1');
        if (callback) {
          callback();
        }
      });
    $('head').append(style);
  };

  let readability = window.localStorage.getItem('readability');

  if (readability == '1') {
    loadReadability();
  }

  const toggleReadableFont = () => {
    const body = $('body');
    const enable = !body.hasClass('readability');

    if (enable) {
      if (body.hasClass('readability-loaded')) {
        body.addClass('readability');
        $(document).trigger('dyslexicFontChange', true);
      } else {
        loadReadability(() => {
          $(document).trigger('dyslexicFontChange', true);
        });
      }
    } else {
      $('body').removeClass('readability');
      $(document).trigger('dyslexicFontChange', false);
    }
    window.localStorage.setItem('readability', enable ? '1' : '0');
  };

  $(document).on(Utils.interactionEvent, '.pr2--panelHeaderToolReadableFont', (ev) => {
    ev.target.blur();
    toggleReadableFont();
  });

  $(document).on('toggleDyslexicFont', () => {
    toggleReadableFont();
  });

  var collection_adder = new CollectionAdder();
  collection_adder.attach('.collection-adder');

  $('.lp--js-loginLink').on('keypress ' + Utils.tapInteractionEvent + '.authenticate', function (e) {
    e.preventDefault();
    Authenticate.login();
  });

  $('.lp--js-signupLink').on('keypress ' + Utils.tapInteractionEvent + '.authenticate', function (e) {
    e.preventDefault();
    Authenticate.request_signup();
  });

  const userData = Utils.getCurrentUserData();

  const loggedOutItem = window.localStorage.getItem('loggedOut');
  let loggedOut;
  if (loggedOutItem) {
    loggedOut = JSON.parse(loggedOutItem);
  }

  if (loggedOut === true && userData.uuid) {
    window.localStorage.removeItem('loggedOut');
    loggedOut = false;
  }

  routie({
    authenticate: function () {
      if (!userData.uuid) {
        Authenticate.request_signup();
      }
    },
    'signup_code/:email?/:uuid?': function (email, uuid) {
      if (!userData.uuid) {
        Authenticate.signup_code(email, uuid);
      }
    },
    signup: function () {
      if (!userData.uuid) {
        Authenticate.request_signup();
      }
    },
    createAccount: function () {
      if (!userData.uuid) {
        Authenticate.signup();
      }
    },
    teamsSelectPath: function () {
      if (!userData.uuid) {
        Authenticate.teamsSelectPath();
      }
    },
    invite: function () {
      Authenticate.acceptInvite();
    },
    'code/:code?': function (code) {
      if (window.code_token) {
        Authenticate.joinByCode({ code });
      } else {
        window.location.assign('/#login');
      }
    },
    login: function () {
      if (checkInTeams && checkInTeams()) {
        return window.location.assign('/#teams');
      }
      if (!userData.uuid) {
        Authenticate.login();
      }
    },
    teams: function () {
      if (!userData.uuid) {
        Authenticate.signInWithTeams({
          azureClientId: document.body.getAttribute('data-azure-client-id'),
          appUrl: window.location.origin,
          skipSilentLogin: loggedOut,
        });
      } else {
        // User logged in, look at context to see where to redirect user to
        microsoftTeams.initialize();
        microsoftTeams.getContext(async (context) => {
          if (context.subEntityId) {
            const path = context.subEntityId.split('/');
            const code = path.length === 2 && path[0] === 'code' ? path[1] : '';
            const deep = path.length > 1 && path[0] === 'deep' ? path.slice(1).join('/') : '';
            if (code) {
              return window.location.assign(`/code/${code}`);
            } else if (deep) {
              return window.location.assign(deep);
            }
          }
          window.location.assign(`/me`);
        });
      }
    },
    'resetPassword/:email?': function (email) {
      if (!userData.uuid) {
        Authenticate.resetPassword({ email });
      }
    },
    'reset_password/:token': function (token) {
      Authenticate.resetPasswordConfirm({ token: token });
    },
    'microsoft/:email?': function (email) {
      const appUrl = window.location.origin;
      const options = {
        azureClientId: document.body.getAttribute('data-azure-client-id'),
        appUrl,
        popUp: false,
        redirectUri: `${appUrl}/`,
      };
      if (email) {
        options.loginHint = email;
      }
      Authenticate.signInMS(options);
    },
    '*id_token=*': function () {
      Authenticate.signInMSCallback(location.hash.substring(1));
    },
    'expiredAccount/:type?': function (type) {
      Authenticate.expiredAccountPopup(type);
    },
    'fail/:code?': function (code) {
      const keyMap = {
        sso: 'error_sso_login',
        unauthorized: 'app_error_unauthorized',
        default: 'app_error_alert',
      };
      const key = keyMap[code] || keyMap['default'];
      flash(i18n.__(key, { ttl: 5000 }));

      if (!userData.uuid) {
        Authenticate.login();
      }
    },
  });
});
