import React from 'react';
import PropTypes from 'prop-types';
import PrimaryButton from '../PrimaryButton';

const SecondaryButton = ({ text, type = 'button', onClick, ...rest }) => {
  return <PrimaryButton text={text} type={type} onClick={onClick} secondary={true} {...rest} />;
};

SecondaryButton.propTypes = {
  text: PropTypes.string.isRequired,
  type: PropTypes.string,
  onClick: PropTypes.func,
  rest: PropTypes.object,
};

export default SecondaryButton;
