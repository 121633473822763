import styled, { keyframes, css } from 'styled-components';

export const PageWrapper = styled.div``;

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const OverviewHeaderWrapper = styled.div`
  display: flex;

  h1 {
    width: 265px;
  }
`;

export const FilterPanelWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media ${({ theme }) => theme.sizes.tablet} {
    margin-bottom: 56px;
  }
`;

export const Filters = styled.div`
  display: flex;
  flex-direction: row;
  font-family: ${({ theme }) => theme.fonts.robotoLight};
  font-size: 14px;
  color: ${({ theme }) => theme.colors.headerGrey};
  flex-wrap: wrap;
  white-space: nowrap;
  width: 100%;
`;

export const EmptyListPanelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  div {
    max-width: 800px;
  }
`;

export const LeftButtonPanel = styled.div`
  display: flex;
  justify-content: center;
  margin: 20px 0;

  button:after {
    background-size: initial;
  }
`;

export const ButtonPanel = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 0;

  div:not(:last-child) {
    margin-right: 0;
  }

  div:last-child {
    margin-top: 5px;
  }

  @media ${({ theme }) => theme.sizes.tablet} {
    flex-direction: row;
    align-items: flex-start;
    margin-top: 3px;

    div:not(:last-child) {
      margin-right: 34px;
    }

    div:last-child {
      margin-top: 0;
    }
  }
`;

export const Group = styled.div`
  margin-top: 40px;
  width: 150px;
`;

export const GroupIcon = styled.div`
  width: 18px;
  margin-right: 5px;
  opacity: 0.8;
  transition: opacity 0.2s ease-in-out;
  flex-shrink: 0;

  &:before {
    ${({ icon }) =>
      icon &&
      css`
        background: url(${icon}) center left no-repeat;
        content: '';
        width: 18px;
        height: 18px;
        position: absolute;
        cursor: pointer;
        margin-top: 3px;
      `}
  }

  &:hover,
  &:focus {
    opacity: 1;
    transition: opacity 0.2s ease-in-out;
  }

  .show-mask & {
    z-index: 0;
  }
`;

export const GroupHeader = styled.div`
  font-size: 19px;
  margin-bottom: 16px;
  white-space: nowrap;
  display: flex;
`;

export const Members = styled.div``;

export const MappedGroups = styled.div`
  display: flex;

  ${Group} {
    margin-right: 115px;
  }
`;

export const Ungrouped = styled.div`
  display: flex;
  flex-flow: wrap;
  width: auto;

  @media ${({ theme }) => theme.sizes.tablet} {
    ${MappedGroups} {
      flex-flow: row;
      width: 530px;
    }
  }

  ${GroupIcon} {
    padding-right: 18px;
  }
`;

export const Member = styled.div`
  margin-bottom: 12px;
  color: ${({ theme }) => theme.colors.headerGrey};
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: none;

  &:nth-child(-n + 3) {
    display: block;
  }

  ${({ expanded }) =>
    expanded &&
    css`
      &:nth-child(n) {
        display: block;
      }
    `}
  }

  ${Ungrouped} & {
    width: 400px;
  }
`;

export const Groups = styled.div`
  margin-top: -32px;

  ${MappedGroups} {
    flex-flow: wrap;
  }
`;

export const ExpandGroup = styled.div`
  position: relative;
  width: 10px;
  height: 10px;
  cursor: pointer;
  opacity: 0.8;
  transition: opacity 0.2s ease-in-out;

  &:after {
    background-image: url('/assets/images/icons/arrow-down.svg');
    width: 12px;
    height: 7px;
    content: ' ';
    position: absolute;
    bottom: -10px;
    left: -10px;
    padding: 15px;
    background-repeat: no-repeat;
    background-position: center center;
  }

  &:hover,
  &:focus {
    opacity: 1;
    transition: opacity 0.2s ease-in-out;
  }
`;

export const Wrapper = styled.div`
  display: flex;
`;

export const CenterWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const TopWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

export const ListHeader = styled.div`
  ${({ width }) =>
    width &&
    css`
      width: ${width};
    `}
  }
`;

export const DateListHeader = styled(ListHeader)`
  white-space: nowrap;
  text-align: right;
`;

export const ListHeaderPanel = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${({ theme }) => theme.colors.headerGrey};
  border-bottom: 1px solid ${({ theme }) => theme.colors.headerGrey};
  margin-bottom: 25px;
  padding-bottom: 13px;

  div:last-child() {
    display: none;

    @media ${({ theme }) => theme.sizes.tablet} {
      display: flex;
      white-space: nowrap;
      justify-content: flex-end;
    }
  }
`;

export const SessionListHeaderPanel = styled(ListHeaderPanel)`
  div:last-child() {
    display: flex;
    white-space: nowrap;
    justify-content: flex-end;
  }
`;

export const ColumnWrapper = styled(Wrapper)`
  flex-direction: column;
  width: 100%;
`;

export const LeftColumnWrapper = styled(ColumnWrapper)`
  width: 100%;

  @media ${({ theme }) => theme.sizes.tablet} {
    width: 40%;
  }
`;

export const RightColumnWrapper = styled(ColumnWrapper)`
  width: 100%;

  @media ${({ theme }) => theme.sizes.tablet} {
    width: 50%;
  }
`;

export const RowWrapper = styled(Wrapper)`
  flex-direction: row;
  justify-content: space-between;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const TextBlock = styled.label`
  margin-bottom: 34px;
  font-style: ${({ italic }) => (italic ? 'italic' : 'normal')};
`;

export const FileInput = styled.input`
  display: none;
`;

export const TemplatePanel = styled.div`
  margin-bottom: 34px;
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.colors.buttonGreenFront};
`;

export const DownloadTemplateButton = styled.a`
  padding-left: 30px;
  position: relative;
  cursor: pointer;
  filter: brightness(1);
  transition: filter 0.2s ease-in-out;

  &:before {
    position: absolute;
    background-image: url('/assets/images/icons/download-file-green.svg');
    width: 26px;
    height: 26px;
    content: '';
    left: 0;
    margin-top: -1px;
    background-repeat: no-repeat;
    background-size: contain;
  }

  &:hover,
  &:focus {
    filter: brightness(1.2);
    transition: filter 0.2s ease-in-out;
  }
`;

export const UploadTemplateButton = styled(DownloadTemplateButton)`
  &:before {
    transform: rotate(180deg);
  }
`;

export const FileName = styled.div`
  margin-top: 20px;
  border: 2px solid ${({ theme }) => theme.colors.buttonGreenFront};
  padding: 10px;
  border-radius: 10px;
  color: ${({ theme }) => theme.colors.white};
  font-style: italic;
`;

export const GroupEdit = styled.div`
  display: ${({ visible }) => (visible ? 'flex' : 'none')};
  flex-direction: column;
  color: ${({ theme }) => theme.colors.buttonGreenFront};
  font-weight: bold;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.buttonGreenFront};

  label {
    color: ${({ theme }) => theme.colors.buttonGreenFront};
    font-weight: bold;
    white-space: nowrap;
    margin-bottom: 10px;
    transition: opacity 0.2s ease-in-out;

    span {
      background-position: initial;
      background-size: 18px 18px;
    }
  }
`;

export const RemoveSelected = styled.div`
  position: relative;
  padding-left: 24px;
  cursor: pointer;
  transition: color 0.2s ease-in-out;

  &:before {
    ${({ icon }) =>
      icon &&
      css`
        background-image: url(${icon});
        position: absolute;
        content: '';
        left: -3px;
        top: -2px;
        width: 20px;
        height: 20px;
        background-repeat: no-repeat;
        background-size: cover;
      `}
  }

  ${({ disabled }) =>
    !disabled &&
    css`
      &:hover,
      &:focus {
        color: ${({ theme }) => theme.colors.white};
        transition: color 0.2s ease-in-out;
      }
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: default;
      opacity: 0.5;

      &:hover,
      &:focus {
        opacity: 0.5;
      }
    `}
`;

export const ParticipantPanelWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${({ theme }) => theme.colors.headerGrey};
  transition:
    color 0.2s ease-in-out,
    background-color 0.2s ease-in-out;
  cursor: pointer;
  padding: 14px 15px;
  margin: 2px -15px;
  background-color: ${({ theme }) => theme.colors.darkHorizonBackground};

  &:after {
    background-image: url('/assets/images/icons/arrow-down.svg');
    width: 12px;
    height: 7px;
    content: ' ';
    position: absolute;
    right: 20px;
    padding: 15px;
    background-repeat: no-repeat;
    background-position: center center;
  }

  @media ${({ theme }) => theme.sizes.tablet} {
    padding: 4px 15px;
    background-color: ${({ theme }) => theme.colors.transparent};

    &:after {
      content: initial;
    }
  }

  &:hover,
  &:focus {
    transition:
      color 0.2s ease-in-out,
      background-color 0.2s ease-in-out;
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.white_1};
  }

  ${({ selected, theme }) =>
    selected &&
    css`
      color: ${theme.colors.white};
      background-color: ${theme.colors.white_1};

      @media ${({ theme }) => theme.sizes.tablet} {
        background-color: ${theme.colors.white_1};
      }

      &:after {
        transform: rotate(180deg);
      }
    `};

  > div:last-child {
    display: none;

    @media ${({ theme }) => theme.sizes.desktop} {
      display: flex;
    }
  }
`;

export const SelectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
`;

export const SelectionHeader = styled.div``;

export const SelectionList = styled.div`
  max-height: 250px;
  overflow-y: auto;
  margin-bottom: 20px;

  ${CenterWrapper} {
    margin-left: 10px;
  }
`;

export const SessionHeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media ${({ theme }) => theme.sizes.tablet} {
    margin-left: 0;
  }

  @media ${({ theme }) => theme.sizes.desktop} {
    margin-left: 0;
  }

  img {
    margin-top: 12px;
    width: 52px;
    height: 52px;
    display: none;

    @media ${({ theme }) => theme.sizes.tablet} {
      display: block;
    }
  }
`;

export const GroupHeaderWrapper = styled(SessionHeaderWrapper)``;

export const AddParticpantWrapper = styled.div``;

export const ParticipantInfoPanel = styled.div`
  display: flex;
  align-items: center;

  ${GroupHeaderWrapper} & {
    padding: 6px;
    line-height: 27px;
  }

  ${SelectionWrapper} & {
    border-top: 1px solid ${({ theme }) => theme.colors.headerGrey};
    height: 45px;
    justify-content: space-between;

    &:last-child {
      border-bottom: 1px solid ${({ theme }) => theme.colors.headerGrey};
    }
  }
`;

export const EmailWrapper = styled.div`
  display: flex;
  align-items: center;

  border-top: 1px solid ${({ theme }) => theme.colors.headerGrey};
  height: 45px;
  justify-content: space-between;

  &:first-child {
    border-top: none;
  }

  &:last-child {
    border-bottom: 1px solid ${({ theme }) => theme.colors.headerGrey};
  }
`;

export const ParticipantIcon = styled.div`
  width: ${({ size }) => (size ? `${size}px` : '25px')};
  height: ${({ size }) => (size ? `${size}px` : '25px')};
  border-radius: ${({ active }) => (active ? '50%' : '0')};
  margin-right: 15px;
  flex-shrink: 0;
  align-self: center;
  position: relative;

  ${({ icon, active, online, theme }) =>
    icon &&
    css`
      background: url(${icon}) center center no-repeat;
      background-size: ${active ? 'cover' : 'contain'};

      &::after {
        content: ' ';
        display: inline-block;
        width: 8px;
        height: 8px;
        background-color: ${online ? theme.colors.loopsGreen : theme.colors.transparent};
        border-radius: 50%;
        position: absolute;
        bottom: 0;
        right: 0;
      }
    `}
`;

export const ParticipantName = styled.div`
  color: ${({ theme }) => theme.colors.white};
`;

export const Email = styled(ParticipantName)``;

export const StatusPanel = styled.div`
  color: ${({ selected, theme, online }) => (online && !selected ? theme.colors.buttonGreenFront : theme.colors.white)};

  @media ${({ theme }) => theme.sizes.tablet} {
    display: none;
  }
`;

export const GroupPanelWrapper = styled(ParticipantPanelWrapper)`
  padding: 14px 15px;

  @media ${({ theme }) => theme.sizes.tablet} {
    padding: 4px 15px;
  }
`;
export const GroupName = styled(ParticipantName)`
  font-size: 19px;
`;
export const ParticipantsPanel = styled(StatusPanel)``;

export const RightPanel = styled.div``;

export const ListItemWrapper = styled.div``;

export const SubPanelWrapper = styled.div`
  margin: 0 -15px;
  padding: 30px 15px;

  @media ${({ theme }) => theme.sizes.tablet} {
    margin: 0;
    padding: 0;
  }
`;

export const SessionFilterPanel = styled.div`
  display: flex;
  flex-direction: row;
  margin: 10px 0 20px;
`;

export const SessionParticipantName = styled.div`
  font-size: 19px;
  line-height: 23px;
  margin-bottom: 12px;
`;

export const SessionParticipantInfoPanel = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 5px;
`;

export const SessionHeaderButtonPanel = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  button {
    margin-bottom: 10px;
  }

  @media ${({ theme }) => theme.sizes.desktop} {
    flex-direction: column;
    margin-left: 10px;
    align-items: flex-end;
    justify-content: initial;

    button {
      margin-top: 0;
    }
  }
`;

export const GroupHeaderButtonPanel = styled(SessionHeaderButtonPanel)``;

export const SessionPanelWrapper = styled.div`
  display: flex;
  padding: 10px 0;
  justify-content: space-between;
  flex-direction: row;
`;

export const ParticipantsPanelWrapper = styled(ColumnWrapper)`
  justify-content: flex-start;
`;

export const TeacherWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  color: ${({ theme }) => theme.colors.headerGrey};
  width: 28%;
  margin-right: 5px;
`;

export const ProgressWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  white-space: nowrap;
  justify-content: flex-end;
  width: 22%;
  margin-top: 6px;
`;

export const SessionItemWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  color: ${({ theme }) => theme.colors.headerGrey};

  ${({ width }) =>
    width &&
    css`
      width: ${width};
    `}
  }
`;

export const GroupItemWrapper = styled(SessionItemWrapper)`
  margin-right: 5px;
`;

export const DateItemWrapper = styled(SessionItemWrapper)`
  white-space: nowrap;
  justify-content: flex-end;
`;

export const StickyPanel = styled.div`
  position: sticky;
  top: 100px;
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  margin-right: 5px;
`;

export const SessionTitle = styled.div`
  font-size: 14px;
  line-height: 17px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 130px;

  @media ${({ theme }) => theme.sizes.tablet} {
    white-space: initial;
    overflow: initial;
    text-overflow: initial;
    width: 100%;
  }
`;

export const SessionSubtitle = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.headerGrey};
  display: none;

  @media ${({ theme }) => theme.sizes.tablet} {
    display: flex;
  }
`;

export const ProgressText = styled.div`
  font-size: 10px;
  font-weight: bold;
  line-height: 10px;
  min-width: 30px;
  margin-right: 5px;
  color: ${({ theme }) => theme.colors.progressBarGreen};
`;

export const ParticipantInfo = styled.div`
  line-height: 22px;
  max-width: 250px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  @media ${({ theme }) => theme.sizes.tablet} {
    max-width: 270px;
  }

  @media ${({ theme }) => theme.sizes.desktop} {
    max-width: 300px;
  }
`;

export const PopupColumnWrapper = styled(ColumnWrapper)`
  min-width: 100%;

  input,
  input ~ label {
    margin-top: -30px;
    margin-bottom: 20px;

    ::placeholder {
      color: ${({ theme }) => theme.colors.buttonGreenFront};
      font-style: italic;
    }
  }

  label {
    margin-bottom: 40px;
  }

  ${AddParticpantWrapper} input {
    margin: 0;
  }
`;

export const RelativeWrapper = styled.div`
  position: relative;
`;

export const ShowPasswordIcon = styled.span`
  ${({ icon }) =>
    icon &&
    css`
      background: url(${icon}) center center no-repeat;
      background-size: contain;
      width: 20px;
      height: 20px;
      position: absolute;
      top: -7px;
      right: 0;
      cursor: pointer;
      opacity: 0.8;
      transition: opacity 0.2s ease;

      &:hover,
      &:focus {
        opacity: 1;
        transition: opacity 0.2s ease;
      }
    `}
`;

export const CheckboxWrapper = styled(PopupColumnWrapper)`
  margin: 10px 0;

  label {
    font-weight: 300;
    line-height: 21px;
    margin-bottom: 10px;
    width: fit-content;
  }
`;

export const GroupNameWrapper = styled.div`
  input {
    font-size: 18px;
    margin-bottom: 0px;

    & ~ div {
      margin: 10px;
    }
  }

  ${({ edit }) =>
    edit &&
    css`
      input {
        font-size: 24px;
        font-family: ${({ theme }) => theme.fonts.roboto};
      }
    `}
`;

export const InvitationPanel = styled(PopupColumnWrapper)`
  input {
    margin-bottom: 0px;

    & ~ div {
      margin: 10px;
    }
  }
`;

export const JoinSessionButton = styled.img`
  height: 20px;
  width: 20px;
  margin-left: 10px;
  margin-right: -30px;
  object-fit: cover;
  flex-shrink: 0;
  cursor: pointer;
`;

export const CollaborationPopupColumnWrapper = styled(PopupColumnWrapper)`
  label {
    margin-bottom: 20px;
  }

  div {
    background-position: 0 5.5px;
  }

  ${ButtonPanel} {
    justify-content: flex-end;
  }
`;

export const CollaborationButtonPanel = styled(ButtonPanel)`
  justify-content: flex-end;
`;

export const Loops = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 0;
  margin-bottom: 20px;
  height: calc(100vh - 600px);
  min-height: 220px;
  overflow-y: auto;
  border: 1px solid var(--white_5);
  padding: 10px;
`;

export const Loop = styled.div`
  label {
    margin-bottom: 0;
  }
`;

const rotating = keyframes`
  from {
    transform: rotateY(0deg);
  }
  to {
    transform: rotateY(360deg);
  }
`;

export const Placeholder = styled.div`
  width: 21px;
  height: 21px;
  margin: 70px auto;
  background-size: 21px 21px;
  background-position: center !important;
  animation: ${rotating} 2s linear infinite;

  ${({ icon }) =>
    icon &&
    css`
      background-image: url(${icon});
    `}
`;
