import Panel from './panels';
import Utils from '../shared/utils';
import key from 'keymaster';

export default class Modal extends Panel {
  get keyScope() {
    return 'modals';
  }

  constructor(content, opts = {}) {
    super(Panel.MODAL, content, opts);

    const body = document.querySelector('body');
    const hasScrollbar = window.innerWidth > document.documentElement.clientWidth;
    const maskClass = ['show-mask'].concat(hasScrollbar ? 'scrollbar' : []);
    body.classList.add(...maskClass);

    const disableElements = (selector) => {
      const region = document.querySelector(selector);
      if (!region) {
        return;
      }
      region.setAttribute('aria-hidden', true);
      const not = 'not([disabled], [tabindex="-1"])';
      const allTabableElements = region.querySelectorAll(
        `a[href]:${not}, button:${not}, input:${not}, textarea:${not}, select:${not}, details:${not}, [tabindex]:${not}`,
      );
      allTabableElements.forEach((element) => {
        if (!element.classList.contains('scrollable')) {
          element.classList.add('tab-disabled');
          element.setAttribute('tabindex', -1);
          element.setAttribute('disabled', true);
        }
      });
      $(region).find('[contenteditable]').attr('contenteditable', false);
    };

    const enableElements = (selector) => {
      const region = document.querySelector(selector);
      if (!region) {
        return;
      }
      region.removeAttribute('aria-hidden');
      const allTabDisabledElements = region.querySelectorAll('.tab-disabled');
      allTabDisabledElements.forEach((element) => {
        element.classList.remove('tab-disabled');
        element.setAttribute('tabindex', 0);
        element.removeAttribute('disabled');
      });
      $(region).find('[contenteditable]').attr('contenteditable', true);
    };

    const disableBackground = () => {
      disableElements('#headerContainer');
      disableElements('[role="main"]');
      disableElements('[role="region"]');
      disableElements('#footerContainer');
    };

    const enableBackground = () => {
      enableElements('#headerContainer');
      enableElements('[role="main"]');
      enableElements('[role="region"]');
      enableElements('#footerContainer');
    };

    if ($('.alexandria.open').length) {
      disableElements('.alexandria.open');
    } else {
      disableBackground();
    }

    const classes = opts.class ? opts.class.split(' ') : [];
    if (opts.disableClose) {
      classes.push('disableClose');
    }
    if (opts.hideBackground) {
      classes.push('hideBackground');
    }

    this.content = $(
      Handlebars.templates['panel']({
        type: Panel.typeClass(Panel.MODAL),
        class: classes.join(' '),
      }),
    );
    this.content.html(content);

    const closeButton = this.content.find('.pr2--js-closeBtn');
    if (!opts.disableClose) {
      closeButton.one('keypress ' + Utils.tapInteractionEvent + '.panel', this.hide.bind(this));
    } else {
      closeButton.remove();
    }

    this.eventSource.on('didHide.panel', () => {
      if ($('.alexandria.open').length) {
        enableElements('.alexandria.open');
      } else {
        body.classList.remove('show-mask', 'scrollbar');
        key.setScope('panels');
        enableBackground();
      }
    });
  }
}
