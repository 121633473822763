import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ViewSection, ContentWrapper } from '../styles';

import CheckBox from '../../CheckBox';

const ChecklistContentTeacherPanel = ({ data, report }) => {
  if (!report) {
    return null;
  }

  const [items, setItems] = useState([]);
  useEffect(() => {
    setItems(
      [...(data.ChecklistItems || [])]
        .sort((a, b) => a.order - b.order)
        .map((item) => {
          const { id, content, order } = item;
          return {
            id,
            content,
            order,
            checked: (report.list || {})[item.id] || false,
          };
        }),
    );
  }, [report, data]);

  const list = items.map((item, index) => {
    return (
      <CheckBox
        key={index}
        id={`${item.id}`}
        text={item.content}
        defaultChecked={item.checked}
        onChange={() => {}}
        value={1}
        lightMode={true}
        disabled={true}
      />
    );
  });

  return (
    <ViewSection>
      <ContentWrapper>{list}</ContentWrapper>
    </ViewSection>
  );
};

export default ChecklistContentTeacherPanel;

ChecklistContentTeacherPanel.propTypes = {
  data: PropTypes.object.isRequired,
  report: PropTypes.object.isRequired,
};
