import styled from 'styled-components';

export const ProgressBarWrapper = styled.div`
  height: ${({ height }) => (height ? height : '10px')};
  background: ${({ theme }) => theme.colors.white_5};
  border-radius: ${({ height }) => (height ? `${Math.round(parseInt(height) / 2)}px` : '5px')};
  position: relative;
  overflow: hidden;
  width: ${({ width }) => (width ? width : '50px')};

  .show-mask & {
    z-index: 0;
  }

  @media ${({ theme }) => theme.sizes.tablet} {
    width: ${({ width }) => (width ? width : '70px')};
  }
`;

export const Progress = styled.div`
  height: 100%;
  position: absolute;
  background-color: ${({ theme, progress, highlightOverflow, progressColor }) =>
    highlightOverflow
      ? progress <= 100
        ? progressColor || theme.colors.progressBarInformation
        : theme.colors.progressBarOverflow
      : progressColor || theme.colors.progressBarGreen};
  border-radius: 4px;
  transition: 0.5s;
  width: ${({ progress }) => `${Math.min(progress, 100)}%` || '0%'};
`;
