import Utils from '../utils';
import flash from '../flash';
import getDomPath from './get_dom_path';

const newCommentClickHandler = (selectionManager) => {
  const informBackendOfUpdate = (wrapper, path, entityId) => {
    selectionManager.remote
      .post(selectionManager.options.viewer.options.session_id + '/selections', {
        data: wrapper.get(0).querySelector(path).outerHTML,
        xpath: path,
        selection: selectionManager._range.toString(),
        context_type: wrapper.attr('data-selection-entity'),
        context_uuid: wrapper.attr('data-selection-uuid'),
        uuid: entityId,
      })
      .catch(() => {
        flash(i18n.__('error_selection_create_failed'));
      });
  };

  const onCommentClick = (startBlock) => {
    const entityId = Utils.generateElementID();
    const commentInstance = selectionManager.options.commentInstance;
    const commentEventSource = commentInstance.eventSource;
    const selectedTextMarker = ({ entity_uuid, data: { matchingId } }) => {
      if (matchingId !== entity_uuid) {
        return;
      }
      const parent = $(startBlock);
      if (parent.has(`mark[data-selection-uuid=${entityId}]`).length > 0) {
        return;
      }
      const wrapper = parent.parents('[data-selection-entity]');
      if (wrapper.is('[data-selection-inert]')) {
        return;
      }
      const path = getDomPath(parent.get(0), wrapper.get(0));
      const mark = document.createElement('mark');
      mark.dataset['selectionUuid'] = entityId;
      mark.dataset['selectionCreatorUuid'] = selectionManager.currentUserUUID;
      const content = selectionManager._range.extractContents();
      mark.appendChild(content);
      selectionManager._range.insertNode(mark);
      informBackendOfUpdate(wrapper, path, entityId);
    };
    commentEventSource.on('comment.posted.selection', { matchingId: entityId }, selectedTextMarker);
    commentEventSource.on(
      'comment.empty.selection',
      { matchingId: entityId },
      ({ entity_id, data: { matchingId } }) => {
        if (matchingId === entity_id) {
          $(startBlock).find(`mark[data-selection-uuid=${entityId}]`).contents().unwrap();
        }
      },
    );
    commentInstance.open('selection', entityId, {
      temporaryContext: selectionManager._range.toString().substr(0, 120) + '...',
    });
  };
  return {
    onCommentClick,
  };
};
export default newCommentClickHandler;
