import React from 'react';
import PropTypes from 'prop-types';
import NavigationIcon from '../../../public/assets/images/theme-2/icons/arrow.svg';

import {
  FooterSection,
  NodeIcon,
  NavigationUp,
  NavigationLink,
  NavigationWrapperUp,
  NavigationWrapperRight,
  UpArrowIcon,
  HubIcon,
  HubName,
  NavigationNext,
  NodeName,
  RightArrowIcon,
} from './styles';

const NodeContentFooterSection = ({ navigation, useCommonSubmit = false }) => {
  const getNodeIcon = (nodeType) => `/assets/images/theme-2/icons/${nodeType}.svg`;
  const nextNodeIcon = navigation.next && getNodeIcon(navigation.next.type);

  const footerSection = (
    <FooterSection useCommonSubmit={useCommonSubmit}>
      <NavigationUp title={i18n.__('app_next_hub')}>
        <NavigationLink href={`#${navigation.parent.uuid}`} className="navigation-arrow--up">
          <NavigationWrapperUp>
            <UpArrowIcon src={NavigationIcon} />
            <HubIcon src={navigation.parent.image} />
            <HubName>{navigation.parent.name}</HubName>
          </NavigationWrapperUp>
        </NavigationLink>
      </NavigationUp>
      {navigation.next && (
        <NavigationNext title={i18n.__('app_next_node')}>
          <NavigationLink href={`#${navigation.next.uuid}`} className="navigation-arrow--right">
            <NavigationWrapperRight>
              <NodeIcon src={nextNodeIcon} className={navigation.next.type} />
              <NodeName>{navigation.next.name}</NodeName>
              <RightArrowIcon src={NavigationIcon} />
            </NavigationWrapperRight>
          </NavigationLink>
        </NavigationNext>
      )}
    </FooterSection>
  );

  return footerSection;
};

export default NodeContentFooterSection;

NodeContentFooterSection.propTypes = {
  navigation: PropTypes.object.isRequired,
  useCommonSubmit: PropTypes.bool,
};
