import styled, { css } from 'styled-components';

export const Placeholder = styled.div`
  width: 21px;
  height: 21px;
  position: absolute;
  left: 50%;
  top: calc(50% - 12px);
  background-size: 21px 21px;
  animation: rotating 2s linear infinite;

  ${({ icon }) =>
    icon &&
    css`
      background-image: url(${icon});
    `}
`;

export const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  ${({ width }) =>
    width &&
    css`
      width: ${width};
    `}
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ScrollWrapper = styled(Wrapper)`
  position: relative;
  width: 100%;

  ${({ scroll }) =>
    scroll &&
    css`
      transition: 1s transform ease-in-out;
      transform: translate(${scroll}px);
    `}
`;

export const OuterWrapper = styled(Wrapper)`
  position: relative;
`;

export const HubImage = styled.img`
  border-radius: 50%;
  height: 88px;
  width: 88px;
  object-fit: cover;
  flex-shrink: 0;
  margin-right: 15px;
  margin-bottom: 17px;
  transition: 0.2s transform ease-in-out;
  transform: scale(1);
  display: none;

  ${({ src }) =>
    src &&
    css`
      display: flex;

      &:hover,
      &:focus {
        cursor: pointer;
        transition: 0.2s transform ease-in-out;
        transform: scale(1.1);
      }
    `}
`;

export const HubImageWrapper = styled(Wrapper)`
  padding: 15px 6px;
  height: 120px;
  margin-bottom: 30px;
  overflow: hidden;
  width: 100%;
`;

export const GeneralHubImageWrapper = styled(HubImageWrapper)`
  height: 100%;
  min-height: 240px;
  flex-wrap: wrap;
  margin-right: -15px;
`;

export const SectionHeader = styled.div`
  font-size: 20px;
  color: ${({ theme }) => theme.colors.white};
  margin-bottom: 20px;
`;

export const Categories = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 12px;
`;

export const Category = styled.div`
  display: flex;
  flex: 1;
  border: 1.5px solid ${({ theme }) => theme.colors.buttonGreenFront};
  height: 34px;
  justify-content: center;
  border-radius: 7px;
  align-items: center;
  margin-right: 10px;
  margin-bottom: 8px;
  padding-left: 4px;
  padding-right: 4px;
  transition: 0.2s box-shadow ease-in-out;
  cursor: pointer;

  &:hover,
  &:focus {
    transition: 0.2s box-shadow ease-in-out;
    box-shadow: 0 0 0 2px ${({ theme }) => theme.colors.buttonGreenFront};
  }

  ${({ selected }) =>
    selected &&
    css`
      box-shadow: 0 0 0 2px ${({ theme }) => theme.colors.buttonGreenFront};
    `}

  &:last-of-type {
    margin-right: 0;
  }
`;

export const ScrollLeft = styled.img`
  transform: rotate(-90deg);
  cursor: pointer;
  position: absolute;
  width: 16px;
  top: 54px;
  left: -25px;

  ${({ canScroll }) =>
    !canScroll &&
    css`
      cursor: default;
      opacity: 0.5;
    `}
`;

export const ScrollRight = styled(ScrollLeft)`
  transform: rotate(90deg);
  right: -25px;
  left: initial;
`;

export const BottomWrapper = styled.div`
  display: flex;
  margin-bottom: -35px;
  flex-wrap: wrap;
  flex-direction: column;
  @media ${({ theme }) => theme.sizes.tablet} {
    flex-direction: row;
  }
`;

export const UploadButton = styled.div`
  height: 60px;
  margin-bottom: 24px;

  @media ${({ theme }) => theme.sizes.tablet} {
    height: 150px;
    margin-bottom: 0;
  }

  div {
    height: 100%;
    align-items: center;
  }

  button {
    .show-mask & {
      z-index: 0;
    }
  }
`;

export const Preview = styled.div`
  align-self: center;
  display: flex;

  ${HubImage} {
    margin: 13px 40px;
  }

  ${Placeholder} {
    position: initial;
    left: 0;
    margin-top: 0;
    margin-left: 75px;
  }
`;

export const PreviewCrop = styled.div`
  display: flex;
  align-items: center;

  ${HubImage} {
    margin: 0 40px;

    &:hover,
    &:focus {
      transform: scale(1);
      cursor: default;
    }
  }
`;
