import React from 'react';
import { Link, LinkWrapper, LinkLabel } from './styles';

const FooterLink = ({ href, text, label, showLink = true, target = 'blank', icon }) => {
  return (
    showLink && (
      <LinkWrapper>
        {label && <LinkLabel>{label}</LinkLabel>}
        <Link href={href} target={target} rel="noreferrer" icon={icon}>
          {text}
        </Link>
      </LinkWrapper>
    )
  );
};

export default FooterLink;
