import styled, { css } from 'styled-components';

export const BottomPanelWrapper = styled.footer`
  margin: 40px 24px;
  padding-bottom: 200px;
  font-size: 17px;
  font-weight: normal;
  color: {({ theme }) => theme.colors.grey};
  display: block;

  .show-mask & {
    z-index: 0;
  }

  @media ${({ theme }) => theme.sizes.desktop} {
    margin: 0 auto;
    max-width: 1160px;
    margin-top: 135px;
    padding-bottom: 100px;
  }
`;

export const Container = styled.div`
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
`;

export const ContactPanel = styled.div`
  margin-top: 60px;
  background-image: url(/assets/images/loops-icon-green.svg);
  background-size: 48px 48px;
  background-repeat: no-repeat;
  height: 48px;
  display: flex;
  flex-direction: column;
  padding-left: 65px;

  @media ${({ theme }) => theme.sizes.desktop} {
    flex-direction: row;
    align-items: center;
  }

  a {
    margin-bottom: 0;
    display: inline-block;
    border-bottom: 1px solid ${({ theme }) => theme.colors.hoverGrey};
    transition:
      0.2s color ease-in-out,
      0.2s border-bottom ease-in-out;

    &:hover,
    &:focus {
      border-bottom: 1px solid ${({ theme }) => theme.colors.white};
      transition:
        0.2s color ease-in-out,
        0.2s border-bottom ease-in-out;
    }
  }
`;

export const ContactAddress = styled.div`
  display: flex;
  align-items: center;
`;

export const ContactLinks = styled.div`
  display: flex;
  margin-top: 5px;
  margin-left: 0;
  flex-direction: column;

  @media ${({ theme }) => theme.sizes.tablet} {
    margin-top: 2px;
    flex-direction: row;
    align-items: center;
  }

  @media ${({ theme }) => theme.sizes.desktop} {
    margin-left: 5px;
  }
`;

export const LinkPanel = styled.div`
  padding-top: 95px;
  background-image: url(/assets/images/theme-2/loops-icon.svg);
  background-size: 24px 35px;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const LinkColumn = styled.div`
  flex: 1;
  min-width: 200px;
`;

export const LinkWrapper = styled.div`
  display: flex;
  margin-top: 10px;

  @media ${({ theme }) => theme.sizes.tablet} {
    margin-top: 0;
  }
`;

export const LinkLabel = styled.span`
  margin-right: 5px;
  color: ${({ theme }) => theme.colors.headerGrey};
`;

export const LinkDelimiter = styled.span`
  display: none;
  margin-right: 5px;

  @media ${({ theme }) => theme.sizes.tablet} {
    display: block;
  }
`;

export const Link = styled.a`
  cursor: pointer;
  color: ${(props) => (props.selected ? props.theme.colors.white : props.theme.colors.headerGrey)};
  transition: 0.2s color ease-in-out;
  margin-bottom: 18px;
  position: relative;
  display: block;
  width: fit-content;

  .show-mask & {
    z-index: 0;
  }

  :hover,
  :focus {
    color: ${({ theme }) => theme.colors.hoverGrey};
    transition: 0.2s color ease-in-out;

    :after {
      opacity: 1;
      transition: 0.2s opacity ease-in-out;
    }
  }

  :after {
    ${({ icon }) =>
      icon &&
      css`
        position: absolute;
        width: 15px;
        height: 15px;
        content: ' ';
        right: -25px;
        top: 50%;
        margin-top: -7px;
        opacity: 0.5;
        transition: 0.2s opacity ease-in-out;
        background-repeat: no-repeat;
        background-image: url(${icon});
      `}
  }
`;
