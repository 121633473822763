import styled, { css } from 'styled-components';

export const HeaderTool = styled.div`
  width: 18px;
  height: 18px;
  opacity: 0.6;
  transition: 0.2s opacity ease-in-out;
  background-repeat: no-repeat;
  display: block;
  cursor: pointer;

  &:hover,
  &:focus {
    opacity: 1;
    transition: 0.2s opacity ease-in-out;
  }

  margin: 0 18px 0 0;

  ${({ src }) =>
    src &&
    css`
      background-image: url(${src});
    `}
`;
