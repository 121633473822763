import React, { useState, useContext } from 'react';
import Popup from '../Popup';

import useApi from '../../hooks/useApi';
import { GlobalContext } from '../../GlobalContext';

import { Container, TextBlock } from './styles';

const TrialExtendPopup = ({ popupIsOpen, userToken, onClose, organizationUuid }) => {
  const { postData, loading } = useApi(userToken);
  const [disableSendButton, setDisableSendButton] = useState(false);

  const context = useContext(GlobalContext);

  const handleSubmit = async () => {
    try {
      const result = await postData(`/api/v3/organisation/${organizationUuid}/extendTrial`, {
        flash: true,
      });

      if (result.success) {
        setDisableSendButton(true);
        location.reload();
      } else {
        throw new Error();
      }
    } catch (error) {
      context.showFlashError({
        message: error.message ? error.message : i18n.__('app_error_alert'),
        error: true,
      });
    }
  };

  const contentPanel = (
    <Container>
      <TextBlock>{i18n.__('trialExtend.body')}</TextBlock>
    </Container>
  );

  return (
    <Popup
      popupIsOpen={popupIsOpen}
      heading={i18n.__('trialExtend.header')}
      id="trial-extend-popup"
      width={675}
      contentPanel={contentPanel}
      buttonLabel={i18n.__('app_start')}
      onClose={onClose}
      onClick={handleSubmit}
      buttonDisabled={loading || disableSendButton}
    />
  );
};

export default TrialExtendPopup;
