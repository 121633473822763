export const defaultTheme = {
  sizes: {
    mobile: `(min-width: 320px)`,
    tablet: `(min-width: 768px)`,
    desktop: `(min-width: 1160px)`,
  },
  colors: {
    behaviorBlue: '#40b4e5',
    behaviorBlueBack: '#2d7ea0',
    behaviorBlueFront: '#40b4e5',
    behaviorGreen: '#00b550',
    behaviorGreenBack: '#007f38',
    behaviorGreenFront: '#00b550',
    behaviorPink: '#ef3fa9',
    behaviorPinkBack: '#bf3287',
    behaviorPinkFront: '#f152b2',
    behaviorPurple: '#783cbd',
    behaviorPurpleFront: '#ae8ad7',
    behaviorPurpleBack: '#783cbd',
    behaviorYellow: '#ffc627',
    behaviorYellowBack: '#806314',
    behaviorYellowFront: '#ffc627',
    black: '#000000',
    black_1: 'rgba(0, 0, 0, 0.1)',
    black_2: 'rgba(0, 0, 0, 0.2)',
    black_3: 'rgba(0, 0, 0, 0.3)',
    black_4: 'rgba(0, 0, 0, 0.4)',
    black_5: 'rgba(0, 0, 0, 0.5)',
    black_6: 'rgba(0, 0, 0, 0.6)',
    black_7: 'rgba(0, 0, 0, 0.7)',
    black_8: 'rgba(0, 0, 0, 0.8)',
    black_9: 'rgba(0, 0, 0, 0.9)',
    blueGrey: '#5d6d7f',
    buttonGreenBack: '#32cd7e',
    buttonGreenFront: '#32cd7e',
    collectionBlue: '#38b5e6',
    darkHorizonBackground: '#0f151c',
    defaultPlaceholder: '#757575',
    errorFlash: '#b03b3b',
    ghostWhite: '#fcfcfc',
    grey: '#808080',
    greyBorder: '#e5e5e5',
    guidePurple: '#623bb0',
    headerGrey: '#7D8787',
    hoverGrey: '#cccccc',
    iconButtonBorder: 'rgba(136, 150, 166, 0.5)',
    iconButtonBorderHover: 'rgba(136, 150, 166, 1)',
    loopBorder: 'rgba(124, 197, 246, 0.5)',
    loopBorderHover: 'rgba(124, 197, 246, 1)',
    loopsGreen: '#05CE7C',
    menuButtonBorder: '#444B53',
    minimumBlack: 'rgba(0, 0, 0, 0.54)',
    minimumBlueIcon: '#2A9ECF',
    minimumGreenIcon: '#00AB46',
    minimumYellowIcon: '#C48B00',
    minimumWhite: 'rgba(255,255,255, 0.46)',
    notification: '#ef3ea9',
    notificationBackground: '#fdebf6',
    orange: '#FD643B',
    organizationGreen: '#e2f5cf',
    organizationGreenBorder: '#418e4c',
    organizationPurple: '#ae8ad7',
    progressBarGreen: '#00db5f',
    progressBarInformation: '#623bb0',
    progressBarOverflow: '#b03b3b',
    searchFieldPlaceholder: '#9C9C9C',
    sessionBorder: 'rgba(32, 206, 124, 0.5)',
    sessionBorderHover: 'rgba(32, 206, 124, 1)',
    slateGrey: '#a6bbc3',
    standardBlackBackground: '#151e28',
    standardComponentBackground: '#1f2937',
    standardTextGrey: '#eeeeee',
    studentSessionBorder: 'rgba(249, 221, 83, 0.5)',
    studentSessionBorderHover: 'rgba(249, 221, 83, 1)',
    toolbarButtonBlue: '#0066cc',
    transparent: 'transparent',
    trialBorder: '#5ec179',
    white: '#ffffff',
    white_1: 'rgba(255, 255, 255, 0.1)',
    white_2: 'rgba(255, 255, 255, 0.2)',
    white_3: 'rgba(255, 255, 255, 0.3)',
    white_4: 'rgba(255, 255, 255, 0.4)',
    white_5: 'rgba(255, 255, 255, 0.5)',
    white_6: 'rgba(255, 255, 255, 0.6)',
    white_7: 'rgba(255, 255, 255, 0.7)',
    white_8: 'rgba(255, 255, 255, 0.8)',
    white_9: 'rgba(255, 255, 255, 0.9)',
  },
  fonts: {
    helveticaNeue: '"Helvetica Neue", sans-serif',
    readability: 'opendyslexic3regular',
    roboto: 'Roboto, sans-serif',
    robotoLight: '"Roboto Light", sans-serif',
    robotoMono: '"Roboto Mono", sans-serif',
    sansSerif: 'sans-serif',
    timesNewRoman: 'Times New Roman',
  },
  profile: {
    backgroundColor: 'transparent',
    backgroundImageSize: 'cover',
    borderBottomColor: 'transparent',
    borderBottomWidth: 0,
    borderTopColor: 'transparent',
    borderTopWidth: 0,
    foregroundColor: '#ffffff',
    height: 200,
    loopBackgroundColor: 'transparent',
    loopPathColor: '#757575',
    gamificationFrontColor: '#ffc627',
  },
  getSelectedFrontColor: (selected, defaultColor) => (selected ? defaultTheme.colors.buttonGreenFront : defaultColor),
  getSelectedBackColor: (selected, defaultColor) => (selected ? defaultTheme.colors.buttonGreenBack : defaultColor),
};

export const highContrastTheme = {
  ...defaultTheme,
  colors: {
    ...defaultTheme.colors,
    behaviorBlueBack: '#205a73',
    behaviorBlueFront: '#66c3ea',
    behaviorGreenBack: '#005b28',
    behaviorGreenFront: '#4dcb85',
    behaviorPinkBack: '#8f2665',
    behaviorPinkFront: '#f79fd4',
    behaviorPurpleBack: '#6c36aa',
    behaviorPurpleFront: '#c9b1e5',
    behaviorYellowBack: '#664f10',
    buttonGreenBack: '#145232',
    buttonGreenFront: '#32cd7e',
    errorFlash: '#8d2f2f',
    grey: '#cccccc',
    guidePurple: '#3b236a',
    headerGrey: '#bec3c3',
    searchFieldPlaceholder: '#cccccc',
    toolbarButtonBlue: '#0052a3',
  },
  getSelectedFrontColor: (selected, defaultColor) =>
    selected ? highContrastTheme.colors.buttonGreenFront : defaultColor,
  getSelectedBackColor: (selected, defaultColor) =>
    selected ? highContrastTheme.colors.buttonGreenBack : defaultColor,
};
