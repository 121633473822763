import styled from 'styled-components';

export const InputFieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
  margin-bottom: 20px;
  max-width: ${({ maxWidth }) => (maxWidth ? `${maxWidth}px` : 'initial')};
  width: 100%;
`;

export const InputDropDown = styled.select`
  background-color: ${({ theme }) => theme.colors.transparent};
  border: none;
  outline: none;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey};
  font-family: ${({ theme }) => theme.fonts.robotoLight};
  font-size: 19px;
  color: ${({ theme }) => theme.colors.white};
`;

export const DropDownItem = styled.option`
  background-color: ${({ theme }) => theme.colors.standardBlackBackground};
  outline: none;
  font-family: ${({ theme }) => theme.fonts.robotoLight};
  font-size: 19px;
  color: ${({ theme }) => theme.colors.white};
`;

export const DropDownLabel = styled.label`
  color: ${({ theme }) => theme.colors.standardTextGrey};
  font-weight: normal;
  margin-bottom: 5px;
`;
