import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '../../IconButton';
import SecondaryButton from '../../SecondaryButton';
import CheckIcon from '../../../../public/assets/images/theme-2/icons/handin-hover.svg';
import { ViewSection, ButtonWrapper } from '../styles';

const ChunkContentViewer = ({ report, setReport, isTeacher }) => {
  const handinReport = () => {
    setReport({ ...report, state: 'done' });
  };

  const resetReport = () => {
    setReport({ ...report, state: 'active' });
  };

  if (!report && !isTeacher) {
    return null;
  }

  return (
    <ViewSection>
      <ButtonWrapper>
        {isTeacher || ['blank', 'active'].includes(report.state) ? (
          <IconButton icon={CheckIcon} onClick={handinReport} disabled={isTeacher}>
            {i18n.__('app_done')}
          </IconButton>
        ) : (
          <SecondaryButton onClick={resetReport} text={i18n.__('app_reset')} />
        )}
      </ButtonWrapper>
    </ViewSection>
  );
};

export default ChunkContentViewer;

ChunkContentViewer.propTypes = {
  report: PropTypes.object,
  setReport: PropTypes.func.isRequired,
  isTeacher: PropTypes.bool.isRequired,
};
