import useFetch from 'use-http';

const useUploader = ({ signature, timestamp, url }) => {
  const { post, response, loading, error, cache } = useFetch(url + 'upload');
  cache.clear();

  const uploadFile = (file, fileName) => {
    const form = new FormData();
    form.append('signature', signature);
    form.append('timestamp', timestamp);
    form.append('file', file, fileName);
    post(form);
  };

  return { uploadFile, response, loading, error };
};

export default useUploader;
