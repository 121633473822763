import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { PreviewSection, ContentWrapper, ChecklistWrapper, ChecklistHeader, ChecklistBody } from '../styles';
import CheckBox from '../../CheckBox';

const ChecklistContentPreview = ({ data }) => {
  const [items, setItems] = useState([]);
  useEffect(() => {
    setItems(
      [...(data.ChecklistItems || [])]
        .sort((a, b) => a.order - b.order)
        .map((item) => {
          const { id, content, order } = item;
          return {
            id,
            content,
            order,
            checked: false,
          };
        }),
    );
  }, [data]);

  const list = items.map((item, index) => {
    return (
      <CheckBox
        key={index}
        id={`${item.id}`}
        text={item.content}
        defaultChecked={item.checked}
        onChange={() => {
          item.checked = !item.checked;
        }}
        value={1}
        lightMode={true}
      />
    );
  });

  return (
    <PreviewSection>
      <ContentWrapper>
        <ChecklistWrapper>
          <ChecklistHeader>
            <h2>{i18n.__('checklists.header')}</h2>
          </ChecklistHeader>
          <ChecklistBody>{list}</ChecklistBody>
        </ChecklistWrapper>
      </ContentWrapper>
    </PreviewSection>
  );
};

export default ChecklistContentPreview;

ChecklistContentPreview.propTypes = {
  data: PropTypes.object.isRequired,
};
