import React from 'react';
import PropTypes from 'prop-types';
import { ButtonWrapper, Button } from './styles';

const PrimaryButton = ({ text, type = 'button', onClick, secondary = false, ...rest }) => {
  const onClickHandler = (ev) => {
    ev.target.blur();
    onClick && onClick(ev);
  };

  return (
    <ButtonWrapper>
      <Button
        type={type}
        onClick={onClickHandler}
        onKeyPress={onClickHandler}
        tabIndex={0}
        secondary={secondary}
        {...rest}
      >
        {text}
      </Button>
    </ButtonWrapper>
  );
};

PrimaryButton.propTypes = {
  text: PropTypes.string.isRequired,
  type: PropTypes.string,
  onClick: PropTypes.func,
  secondary: PropTypes.bool,
  rest: PropTypes.object,
};

export default PrimaryButton;
