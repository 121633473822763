import colorPalette from './colors';

const toolbar = [
  [
    'bold',
    'italic',
    { list: 'ordered' },
    { list: 'bullet' },
    { textsize: ['small', false, 'large', 'huge'] },
    { align: [] },
  ],
  [{ color: colorPalette }, { background: colorPalette }],
  ['link', 'divider', 'emoji'],
  ['image', 'video', 'audio'],
  ['attachment', 'dropbox', 'gdrive', 'onedrive'],
];

export default toolbar;
