import React from 'react';
import Popup from '../Popup';

import { Container, TextBlock } from './styles';

const ExpiredAccountPopup = ({ popupIsOpen, onClose, loopsSupport, type = null }) => {
  const headerKey = type ? `expiredAccount.${type}.header` : `expiredAccount.header`;
  const bodyKey = type ? `expiredAccount.${type}.body` : `expiredAccount.body`;

  const contentPanel = (
    <Container>
      <TextBlock
        dangerouslySetInnerHTML={{
          __html: i18n.__(bodyKey, {
            loopsSupport,
          }),
        }}
      />
    </Container>
  );

  return (
    <Popup
      popupIsOpen={popupIsOpen}
      heading={i18n.__(headerKey)}
      id="expired-account-popup"
      contentPanel={contentPanel}
      buttonLabel={i18n.__('app_close')}
      width={500}
      onClose={onClose}
      onClick={onClose}
    />
  );
};

export default ExpiredAccountPopup;
