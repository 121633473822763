'use strict';

function ListeningPromise(executor) {
  var promise = new Promise(function (resolve, reject) {
    return executor(resolve, reject);
  });

  this.default_callback = function (data) {
    throw new Error('Not authorized, 401');
  };
  $(this).on('noAccess', this.default_callback);

  promise = promise.catch((data) => {
    if (data && data.code == 401) {
      $(this).trigger({
        type: 'noAccess',
        message: data.message,
      });
    } else {
      throw data;
    }
    $(this).off('noAccess');
  });

  promise = promise.then((data) => {
    $(this).off('noAccess');
    return data;
  });

  ListeningPromise.prototype.on = (event, callback) => {
    $(this).off(event, this.default_callback);
    $(this).on(event, callback);
    return promise;
  };

  promise.__proto__ = ListeningPromise.prototype;
  return promise;
}

ListeningPromise.__proto__ = Promise;
ListeningPromise.prototype.__proto__ = Promise.prototype;

const errorMessage = (xhr) => {
  if (xhr.status === 401) {
    return {
      error: true,
      message: 'No Access, could be session timeout',
      code: '401',
    };
  } else if (xhr.status === 402) {
    return (location.href = `/#expiredAccount`);
  } else if (xhr.responseJSON) {
    return xhr.responseJSON;
  } else {
    return {
      error: true,
      message: 'An error occurred',
      code: xhr.status,
      xhr: xhr,
    };
  }
};

const onError = (reject) => (xhr) => {
  // Do not reject errors from statusCode.
  if (!(xhr.status === 401 || xhr.status === 402)) {
    reject(errorMessage(xhr));
  }
};

const statusCodeHandlers = (reject) => ({
  401: (xhr) => {
    reject(errorMessage(xhr));
  },
  402: (xhr) => {
    reject(errorMessage(xhr));
  },
});

// Small utility for wrapping asynchronous, Promise-based server-communication. Can be independently tested :)
export default class Remote {
  constructor(url, options) {
    this.baseURL = url;
    this.options = $.extend({}, options);
    return this;
  }
  get(url, options = { type: 'json' }, data = {}) {
    return new ListeningPromise((resolve, reject) => {
      $.ajax({
        url: `${this.baseURL}/${url}`,
        cache: false,
        type: 'GET',
        data: data,
        headers: { 'X-Requested-With': 'XMLHttpRequest' },
        dataType: options.type,
        success: resolve,
        statusCode: statusCodeHandlers(reject),
        error: onError(reject),
      });
    });
  }

  post(url, data) {
    return new ListeningPromise((resolve, reject) => {
      $.ajax({
        url: `${this.baseURL}/${url}`,
        data: data,
        headers: { 'X-Requested-With': 'XMLHttpRequest' },
        type: 'POST',
        dataType: 'json',
        success: resolve,
        statusCode: statusCodeHandlers(reject),
        error: onError(reject),
      });
    });
  }

  put(url, data) {
    return new ListeningPromise((resolve, reject) => {
      $.ajax({
        url: `${this.baseURL}/${url}`,
        data: data,
        headers: { 'X-Requested-With': 'XMLHttpRequest' },
        type: 'PUT',
        dataType: 'json',
        success: resolve,
        statusCode: statusCodeHandlers(reject),
        error: onError(reject),
      });
    });
  }

  delete(url, data) {
    return new ListeningPromise((resolve, reject) => {
      $.ajax({
        url: `${this.baseURL}/${url}`,
        data: data,
        headers: { 'X-Requested-With': 'XMLHttpRequest' },
        type: 'DELETE',
        dataType: 'json',
        success: resolve,
        statusCode: statusCodeHandlers(reject),
        error: onError(reject),
      });
    });
  }
}
