import React from 'react';
import PropTypes from 'prop-types';
import CommentsIcon from '../../../public/assets/images/theme-2/icons/asterisk-white.svg';

import { HeaderSection, TitleText, CommentsLink, NodeToolbar, ContentToggle } from './styles';

const NodeContentHeaderSection = ({ title, toggleComments, showContent = undefined, setShowContent = undefined }) => {
  const headerSection = (
    <HeaderSection>
      <TitleText>{title}</TitleText>
      <NodeToolbar>
        {toggleComments && (
          <CommentsLink href="#" onClick={toggleComments} src={CommentsIcon}>
            {i18n.__('app_discuss')}
          </CommentsLink>
        )}
        {setShowContent && (
          <ContentToggle onClick={() => setShowContent(!showContent)}>
            {showContent ? i18n.__('hide_assignment') : i18n.__('show_assignment')}
          </ContentToggle>
        )}
      </NodeToolbar>
    </HeaderSection>
  );

  return headerSection;
};

export default NodeContentHeaderSection;

NodeContentHeaderSection.propTypes = {
  title: PropTypes.string.isRequired,
  toggleComments: PropTypes.func,
  showContent: PropTypes.bool,
  setShowContent: PropTypes.func,
};
