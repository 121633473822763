import React, { useRef, useState } from 'react';
import { withTheme } from 'styled-components';
import Popup from '../Popup';
import ColorPicker from '../ColorPicker';
import PrimaryButton from '../PrimaryButton';
import SecondaryButton from '../SecondaryButton';
import DropDown from '../DropDown';
import IconButton from '../IconButton';
import CanvasCursor from './CanvasCursor';
import PencilIcon from '../../icons/pencil.svg';
import EraserIcon from '../../icons/eraser.svg';
import UndoIcon from '../../icons/undo.svg';
import RedoIcon from '../../icons/redo.svg';
import { ReactSketchCanvas } from 'react-sketch-canvas';
import {
  PopupContainer,
  PopupClose,
  ColumnWrapper,
  Wrapper,
  Header,
  ToolBar,
  SpaceWrapper,
  CanvasWrapper,
  GlobalStyle,
} from './styles';

const CanvasPopup = ({ popupIsOpen, onSave, onClose, theme, ...rest }) => {
  const [strokeColor, setStrokeColor] = useState(theme.colors.black);
  const [canvasColor, setCanvasColor] = useState(theme.colors.white);
  const [width, setWidth] = useState(4);
  const [eraseMode, setEraseMode] = useState(false);

  const canvas = useRef(null);

  const onClick = async () => {
    const svg = await canvas.current.exportSvg();
    onSave(svg);
  };
  const clearCanvas = () => {
    canvas.current.clearCanvas();
  };

  const undo = () => {
    canvas.current.undo();
  };

  const redo = () => {
    canvas.current.redo();
  };

  const useEraser = (value) => {
    setEraseMode(value);
    canvas.current.eraseMode(value);
  };

  const headerPanel = (
    <Wrapper>
      <Header>{i18n.__('canvas.header')}</Header>
    </Wrapper>
  );

  const getSvg = (value) => (
    <svg width="100%" height="20">
      <line x1="0" y1="50%" x2="100%" y2="50%" style={{ stroke: theme.colors.black, strokeWidth: value }} />
    </svg>
  );

  const widthItems = Array.from({ length: 11 }, (_, i) => (i === 0 ? 1 : i * 2)).map((value) => ({
    value,
    label: getSvg(value),
  }));

  const contentPanel = (
    <Wrapper>
      <CanvasWrapper>
        <CanvasCursor color={eraseMode ? canvasColor : strokeColor} width={width} />
        <ReactSketchCanvas
          className="canvas"
          ref={canvas}
          strokeWidth={width}
          eraserWidth={width}
          strokeColor={strokeColor}
          canvasColor={canvasColor}
        />
      </CanvasWrapper>
      <ToolBar>
        <SpaceWrapper>
          <IconButton
            icon={PencilIcon}
            backgroundColor={eraseMode ? theme.colors.transparent : theme.colors.white}
            onClick={() => useEraser(false)}
            title={i18n.__('canvas.usePen')}
            aria-label={i18n.__('canvas.usePen')}
            className="icon pen"
          />
          <IconButton
            icon={EraserIcon}
            backgroundColor={eraseMode ? theme.colors.white : theme.colors.transparent}
            onClick={() => useEraser(true)}
            title={i18n.__('canvas.useEraser')}
            aria-label={i18n.__('canvas.useEraser')}
            className="icon eraser"
          />
        </SpaceWrapper>
        <SpaceWrapper>
          <ColorPicker
            title={i18n.__('canvas.strokeColor')}
            aria-label={i18n.__('canvas.strokeColor')}
            color={strokeColor}
            onChange={(value) => setStrokeColor(value)}
            showInputField={false}
          />
          <ColorPicker
            title={i18n.__('canvas.canvasColor')}
            aria-label={i18n.__('canvas.canvasColor')}
            color={canvasColor}
            onChange={(value) => setCanvasColor(value)}
            showInputField={false}
          />
        </SpaceWrapper>
        <SpaceWrapper>
          <IconButton
            icon={UndoIcon}
            backgroundColor={theme.colors.transparent}
            onClick={() => undo()}
            title={i18n.__('canvas.undo')}
            aria-label={i18n.__('canvas.undo')}
            className="icon undo"
          />
          <IconButton
            icon={RedoIcon}
            backgroundColor={theme.colors.transparent}
            onClick={() => redo()}
            title={i18n.__('canvas.redo')}
            aria-label={i18n.__('canvas.redo')}
            className="icon redo"
          />
        </SpaceWrapper>
        <DropDown
          id="width"
          className="width"
          title={i18n.__('canvas.width')}
          aria-label={i18n.__('canvas.width')}
          items={widthItems}
          selectedValue={width}
          colors={{ back: theme.colors.organizationGreen, front: theme.colors.black }}
          onChange={(ev) => setWidth(ev.target.value)}
        />
        <PrimaryButton text={i18n.__('canvas.clearCanvas')} onClick={clearCanvas} />
        <SecondaryButton text={i18n.__('app_save')} onClick={onClick} />
      </ToolBar>
    </Wrapper>
  );

  const popupContent = (
    <PopupContainer className="canvas-popup" {...rest}>
      <GlobalStyle />
      <PopupClose tabIndex={0} onClick={onClose} onKeyPress={onClose} role="button" aria-label={i18n.__('app_close')} />
      <ColumnWrapper>
        {headerPanel}
        {contentPanel}
      </ColumnWrapper>
    </PopupContainer>
  );

  return <Popup popupIsOpen={popupIsOpen} id="canvas-popup" fullBody={popupContent} onClose={onClose} />;
};

export default withTheme(CanvasPopup);
