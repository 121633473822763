import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ContentEditor from '../../ContentEditor';
import NodeActivityEditor from '../../NodeActivityEditor';
import HandIcon from '../../../../public/assets/images/theme-2/icons/hand.svg';
import ToolbarIcon from '../../../../public/assets/images/theme-2/icons/tools.svg';
import CloseToolbarIcon from '../../../../public/assets/images/theme-2/icons/close-modal.svg';
import SecondaryButton from '../../SecondaryButton';
import PrimaryButton from '../../PrimaryButton';
import {
  ViewSection,
  HeaderUserImage,
  HeaderUserName,
  HeaderRequestFeedback,
  AutoSaveStatus,
  EditorToolbarButton,
} from '../styles';
import { EditorHeaderRowLeft, EditorHeaderRowRight } from '../../NodeActivityEditor/styles';

const TaskContentViewer = ({
  type,
  report,
  setReport,
  user,
  fileService,
  integrations,
  panel,
  autoSaveStatus,
  isTeacher,
}) => {
  const [showToolbar, setShowToolbar] = useState(false);

  const { full_name, image = {} } = user || {};

  const onChangeReport = (value) => {
    setReport({ ...report, body: value, draft: true });
  };

  const submitReport = () => {
    setReport({ ...report, state: 'done', draft: false });
    panel.hide();
  };

  const revokeReport = () => {
    setReport({ ...report, state: 'active', draft: true });
  };

  const toggleToolbar = () => {
    setShowToolbar(!showToolbar);
  };

  const toggleComments = () => {
    document.dispatchEvent(
      new CustomEvent('toggleComments', { detail: { entityId: report.uuid, entityType: report.type } }),
    );
  };

  if (!report) {
    return null;
  }

  const isEditable = isTeacher || ['blank', 'active'].includes(report.state);

  const headerPanel = (
    <>
      <EditorHeaderRowLeft>
        <HeaderUserImage src={image._url && `${fileService.url}60x60/${image._url}`} />
        <HeaderUserName>{full_name}</HeaderUserName>
      </EditorHeaderRowLeft>
      <EditorHeaderRowRight>
        <HeaderRequestFeedback onClick={toggleComments} src={HandIcon} />
      </EditorHeaderRowRight>
    </>
  );

  const editorPanel = (
    <>
      {isEditable && (
        <EditorToolbarButton
          title={i18n.__('editor_tooltip_show_toolbar')}
          onClick={toggleToolbar}
          showToolbar={showToolbar}
          src={showToolbar ? CloseToolbarIcon : ToolbarIcon}
        ></EditorToolbarButton>
      )}
      <ContentEditor
        type={type}
        panelType={panel.type}
        aria-label={i18n.__('placeholder_description')}
        placeholder={i18n.__('placeholder_description')}
        value={report.body || ''}
        onChange={(value) => {
          onChangeReport(value);
        }}
        integrations={integrations}
        fileService={fileService}
        disabled={!isEditable}
        showToolbar={showToolbar}
      />
    </>
  );

  const buttonPanel = (
    <>
      <AutoSaveStatus>{autoSaveStatus['contentEditor']}</AutoSaveStatus>
      {isEditable ? (
        <SecondaryButton text={i18n.__('app_handin')} onClick={submitReport} disabled={isTeacher} />
      ) : (
        <PrimaryButton text={i18n.__('app_edit')} onClick={revokeReport} />
      )}
    </>
  );

  return (
    <ViewSection>
      <NodeActivityEditor headerPanel={headerPanel} editorPanel={editorPanel} buttonPanel={buttonPanel} />
    </ViewSection>
  );
};

export default TaskContentViewer;

TaskContentViewer.propTypes = {
  type: PropTypes.string.isRequired,
  report: PropTypes.object,
  setReport: PropTypes.func.isRequired,
  user: PropTypes.object,
  fileService: PropTypes.object.isRequired,
  panel: PropTypes.object.isRequired,
  integrations: PropTypes.object.isRequired,
  autoSaveStatus: PropTypes.object.isRequired,
  isTeacher: PropTypes.bool.isRequired,
};
