import _ from 'underscore';
import Remote from '../remote';
import Authenticate from '../authenticate';
import flash from '../flash';
import Utils from '../utils';

export default class CollectionAdder {
  constructor(options) {
    this.options = options;
    this.remote = new Remote('/collections');
  }

  toggle(el, circle_id) {
    let wrapper = $('body');
    let open = wrapper.find('.lp--js--add-to-collection').length == 0;

    if (this.toolbox) {
      this.detach();
    }

    if (open) {
      this.active_wrapper = wrapper;
      Authenticate.getAuthenticatedUser().then((data) => {
        this.remote
          .get('json/' + circle_id)
          .then((collections) => {
            let include_plannings = false;
            for (var i = 0; i < collections.length; i++) {
              if (collections[i].type == 'playlist') {
                collections[i].toggable = true;
              }

              if (collections[i].type == 'planning') {
                include_plannings = true;
                if (collections[i].incollection) {
                  collections[i].toggable = false;
                } else {
                  collections[i].toggable = collections[i].role == 'teacher';
                }
              }
            }

            this.toolbox = $(
              Handlebars.templates['add_to_collection']({
                collections: collections,
                include_plannings: include_plannings,
              }),
            );
            this.toolbox.offset(el.offset());

            $(document).on('keyup.collection', (e) => {
              if (e.which == 27) {
                this.detach();
              }
            });

            let input = this.toolbox.find('input');

            input.on(
              'keyup.collection',
              Utils.debounce((e) => {
                if (input.val().trim() == '') {
                  input.parent().removeClass('selected');
                } else {
                  input.parent().addClass('selected');
                }
              }, 100),
            );

            this.toolbox.find('.lp--js-close').on('keypress ' + Utils.tapInteractionEvent + '.collection', (e) => {
              e.preventDefault();
              this.toggle(el, circle_id);
            });

            this.toolbox
              .find('.lp--js--add-to-collection-save')
              .on('keypress ' + Utils.tapInteractionEvent + '.collection', (e) => {
                e.preventDefault();
                let collection_uuids = _.map(this.toolbox.find('li.selected'), (el) => {
                  return $(el).attr('data-collection-uuid');
                });
                let create_new = this.toolbox.find('input').val();
                this.remote
                  .put('add/to/collections', {
                    collection_uuids: collection_uuids,
                    new_collections: create_new.trim() != '' ? [create_new] : [],
                    circle_id: circle_id,
                  })
                  .then((collections) => {
                    if (window.location.href.indexOf('/my-loops') >= 0) {
                      $.get('', function (data) {
                        $('.lp--js-collectionsList').html($(data).find('.lp--js-collectionsList').html());
                      });
                    }
                    flash(i18n.__('collection_items_added'), { ttl: 3000, type: 'status' });
                    this.detach();
                  })
                  .catch((err) => {
                    flash(i18n.__('error_collection_add_failed'), {
                      ttl: 3000,
                    });
                  });
              });

            this.toolbox
              .find('li[data-toggable="true"]:not(.new)')
              .on('keypress ' + Utils.tapInteractionEvent + '.collection', (e) => {
                e.preventDefault();
                $(e.currentTarget).toggleClass('selected');
              });

            wrapper.addClass('lp--loopListItem-add-open');
            wrapper.prepend(this.toolbox);
            $('.new input').focus();
          })
          .catch((err) => {
            flash(i18n.__('error_collection_load_failed'), { ttl: 3000 });
          });
      });
    }
  }

  attach(selection) {
    $(document).on(Utils.tapInteractionEvent + '.no_unbind_collection', selection, (e) => {
      e.preventDefault();
      this.toggle($(e.currentTarget), $(e.currentTarget).attr('data-circle-id'));
    });
    $('.collection-adder').on('keypress', (e) => {
      e.preventDefault();
      e.stopPropagation();
      this.toggle($(e.currentTarget), $(e.currentTarget).attr('data-circle-id'));
      return false;
    });
  }

  detach() {
    this.active_wrapper = undefined;
    $(document).unbind('.collection');
    this.toolbox.find('a, input').unbind('.collection');
    this.toolbox.parent().removeClass('lp--loopListItem-add-open');
    this.toolbox.remove();
  }
}
