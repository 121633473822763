import Vue from 'vue';
import Remote from '../remote';
import Modal from '../modals2';
import Utils from '../utils';
import flash from '../flash';
import './category_picker/picker';

export class CategoryPicker {
  get eventSource() {
    if (!this._eventsource) {
      this._eventsource = $({});
    }
    return this._eventsource;
  }

  constructor(preSelectedCategoryUUIDs = [], options = {}) {
    this.options = $.extend(
      {
        singleCategory: false,
        selectedGapType: '1-3',
        selectOnPick: false,
        requireDocument: false,
        excludeCategories: [],
        allowStacking: false,
        sizeClass: 'small',
      },
      options,
    );
    this.selectedCategoryUUIDs = preSelectedCategoryUUIDs;
    this.categoryLookup = {};
  }

  show(options = {}) {
    let remote = new Remote(this.options.library_slug ? `/library/${this.options.library_slug}` : '/library', {});
    return new Promise((resolve, reject) => {
      return remote
        .get('categories', { type: 'json' }, this.options.requireDocument ? { requireDocument: true } : {})
        .then((libraryCategories) => {
          let filteredGapTypes = {};
          let gapTypes = libraryCategories
            .map((el) => {
              return { key: el.gap_type, value: el.gap_type_name };
            })
            .filter(function (el, idx, self) {
              if (!filteredGapTypes[el.key]) {
                filteredGapTypes[el.key] = true;
                return true;
              } else {
                return false;
              }
            });

          let modal = new Modal($(Handlebars.templates['category_picker']({})), {
            allowStacking: this.options.allowStacking,
            class: 'categoryPicker',
          });
          this.modal = modal;

          modal = this.configureModal(modal, gapTypes, libraryCategories);
          resolve(modal);
        })
        .catch(function (err) {
          console.log(err);
          flash(i18n.__('error_category_load_failed'));
        });
    }).then((modal) => {
      $('.alexandria.open').addClass('hideScroll');
      modal.show();
      return modal;
    });
  }

  getElement() {
    return this.modal.content.find('.lp--js-pickerHook')[0];
  }

  configureModal(modal, gapTypes, libraryCategories) {
    let that = this;
    let preSelectedGapType = window.localStorage['gap_type'];
    if (preSelectedGapType && !gapTypes.includes(preSelectedGapType)) {
      preSelectedGapType = undefined;
    }
    preSelectedGapType = preSelectedGapType || (that.options.sizeClass == 'small' ? '' : gapTypes[0].key);
    if (preSelectedGapType) {
      for (var i = 0; i < gapTypes.length; i++) {
        if (gapTypes[i].key == preSelectedGapType) {
          preSelectedGapType = gapTypes[i];
          break;
        }
      }
    }

    this.picker = new Vue({
      el: this.getElement(),
      template: `
        <category-picker :categories="categories" :selectedCategories="selectedCategories" :excludedCategories="excludedCategories" :selectedGap="selectedGap" :gapTypes="gapTypes" :gapLabel="gapLabel" @toggleCategory="toggleCategory" @resetCategory="resetCategory" @gapSelected="gapSelected" @close="closePicker" :sizeClass="sizeClass" />
      `,
      methods: {
        resetCategory: function () {
          this.selectedGap = preSelectedGapType;
        },
        gapSelected: function (gap) {
          window.localStorage['gap_type'] = gap.key;
          this.selectedGap = gap;
        },
        toggleCategory: function (category) {
          let categoryPosition = this.selectedCategories.indexOf(category);
          if (categoryPosition > -1) {
            this.selectedCategories.splice(categoryPosition, 1);
          } else {
            this.selectedCategories.push(category);
          }
          this.$emit('update', this.selectedCategories);
          if (that.options.selectOnPick) {
            this.selectedCategories = [];
            this.$emit('close');
          }
        },
        closePicker: function () {
          this.$emit('close');
        },
      },
      data: function () {
        return {
          categories: libraryCategories,
          gapTypes: that.options.requireDocument ? gapTypes.filter((type) => type.key !== 'teacher') : gapTypes,
          gapLabel: that.options.gapLabel,
          sizeClass: that.options.sizeClass,
          selectedGap: preSelectedGapType,
          excludedCategories: that.options.excludeCategories,
          preselected: that.selectedCategoryUUIDs,
          selectedCategories: [],
        };
      },
      mounted: function () {
        for (var i = 0; i < this.categories.length; i++) {
          if (this.categories[i].children) {
            for (var j = 0; j < this.categories[i].children.length; j++) {
              if (this.preselected.indexOf(this.categories[i].children[j].uuid) > -1) {
                this.selectedCategories.push(this.categories[i].children[j]);
              }
            }
          }
        }
      },
    });

    this.picker.$on('close', () => {
      this.close();
    });

    this.picker.$on('update', (categories) => {
      let data = [];
      categories.forEach(function (category) {
        data.push(Utils.extend({}, category));
      });
      this.eventSource.trigger('update', [data]);
    });

    this.modal = modal;

    return modal;
  }

  close() {
    $('.alexandria.open').removeClass('hideScroll');
    this.eventSource.trigger('close');
    if (this.modal) {
      return this.modal.hide();
    } else {
      return Promise.resolve();
    }
  }
}

export class EmbeddedCategoryPicker extends CategoryPicker {
  constructor(preSelectedCategoryUUIDs = [], options = {}) {
    super(preSelectedCategoryUUIDs, options);
    this.options.sizeClass = 'embedded';
  }

  getElement() {
    return this.el[0];
  }

  show(element, options = {}) {
    this.el = element;
    let url = '/library';
    if (this.options.library_slug) {
      url = `/library/${this.options.library_slug}`;
    } else if (this.options.token_uuid) {
      url = `/invite/token/${this.options.token_uuid}`;
    }
    let remote = new Remote(url, {});
    return new Promise((resolve, reject) => {
      return remote
        .get('categories', { type: 'json' }, this.options.requireDocument ? { requireDocument: true } : {})
        .then((libraryCategories) => {
          if (libraryCategories.length == 0) {
            this.eventSource.trigger('no_categories');
            resolve(this);
          } else {
            this.eventSource.trigger('loaded');
            let filteredGapTypes = {};
            let gapTypes = libraryCategories
              .map((el) => {
                return { key: el.gap_type, value: el.gap_type_name };
              })
              .filter(function (el, idx, self) {
                if (!filteredGapTypes[el.key]) {
                  filteredGapTypes[el.key] = true;
                  return true;
                } else {
                  return false;
                }
              });

            this.configureModal(null, gapTypes, libraryCategories);

            resolve(this);
          }
        })
        .catch(function (err) {
          flash(i18n.__('error_category_load_failed'));
        });
    });
  }
}
