import React, { useState, useEffect } from 'react';
import EditIcon from '../../icons/white-cogwheel.svg';
import DeleteIcon from '../../icons/white-trash.svg';
import {
  ImagePreview,
  Image,
  NoImage,
  TitlePreview,
  DescriptionPreview,
  Wrapper,
  ColumnWrapper,
  Template_0,
  Template_1,
  Template_2,
  Template_3,
  Template_4,
  DeleteButton,
  EditButton,
  ButtonPanel,
} from './styles';

const Template = ({
  id,
  url,
  imageUrl,
  title,
  description,
  onLinkClick,
  onRemove,
  selected,
  preview,
  showPlaceholders,
}) => {
  const [titleDirty, setTitleDirty] = useState(false);
  const [descriptionDirty, setDescriptionDirty] = useState(false);

  useEffect(() => {
    if (title) {
      setTitleDirty(true);
    }
  }, [title]);

  useEffect(() => {
    if (description) {
      setDescriptionDirty(true);
    }
  }, [description]);

  const noImage = (width, height) => (
    <NoImage width={width} height={height} className="no-image">
      {i18n.__('select_template.image')}
    </NoImage>
  );

  const imagePreview = (width, height) => (
    <ImagePreview className="image-preview">
      {imageUrl ? (
        <Image
          alt={description || title || imageUrl}
          title={title}
          src={imageUrl}
          width={width}
          height={height}
          className="image"
        />
      ) : (
        noImage(width, height)
      )}
    </ImagePreview>
  );

  const edit = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    onLinkClick(ev);
  };

  const remove = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    onRemove(ev);
  };

  const buttonPanel = !preview && window.editor && (
    <ButtonPanel>
      <EditButton
        role="button"
        tabIndex={0}
        onClick={edit}
        onKeyPress={edit}
        title={i18n.__('app_edit')}
        src={EditIcon}
        alt={i18n.__('app_edit')}
      />
      <DeleteButton
        role="button"
        tabIndex={0}
        onClick={remove}
        onKeyPress={remove}
        title={i18n.__('app_remove')}
        src={DeleteIcon}
        alt={i18n.__('app_remove')}
      />
    </ButtonPanel>
  );

  const titleTooltip = preview ? null : { title: title };
  const descriptionTooltip = preview ? null : { title: description };

  const showTitlePlaceholder = showPlaceholders && !titleDirty;
  const showDescriptionPlaceholder = showPlaceholders && !descriptionDirty;

  const hasTitle = !!title || showTitlePlaceholder;
  const hasDescription = !!description || showDescriptionPlaceholder;

  const titlePreview = hasTitle && (
    <TitlePreview
      {...titleTooltip}
      hasDescription={hasDescription}
      className={`title-preview ${hasDescription ? 'has-description' : ''}`}
      placeHolder={!title && showTitlePlaceholder}
    >
      {title || (showTitlePlaceholder && i18n.__('select_template.title_placeholder'))}
    </TitlePreview>
  );

  const descriptionPreview = hasDescription && (
    <DescriptionPreview
      {...descriptionTooltip}
      hasTitle={hasTitle}
      className={`description-preview ${hasTitle ? 'has-title' : ''}`}
      placeHolder={!description && showDescriptionPlaceholder}
    >
      {description || (showDescriptionPlaceholder && i18n.__('select_template.description_placeholder'))}
    </DescriptionPreview>
  );

  const linkAttributes = {
    href: url,
    target: '_blank',
    className: `embed-template ${id} ${selected ? 'selected' : ''}`,
    onClick: onLinkClick,
  };

  const template_0 = (
    <Template_0 {...linkAttributes}>
      {titlePreview}
      {buttonPanel}
    </Template_0>
  );

  const template_1 = (
    <Template_1 {...linkAttributes}>
      {imagePreview(160, 105)}
      <ColumnWrapper className="column-wrapper">
        {titlePreview}
        {descriptionPreview}
      </ColumnWrapper>
      {buttonPanel}
    </Template_1>
  );

  const template_2 = (
    <Template_2 {...linkAttributes}>
      {imagePreview(55, 55)}
      <Wrapper className="wrapper">
        {titlePreview}
        {descriptionPreview}
      </Wrapper>
      {buttonPanel}
    </Template_2>
  );

  const template_3 = (
    <Template_3 {...linkAttributes}>
      {imagePreview(95, 95)}
      <ColumnWrapper className="column-wrapper">
        {titlePreview}
        {descriptionPreview}
      </ColumnWrapper>
      {buttonPanel}
    </Template_3>
  );

  const template_4 = (
    <Template_4 {...linkAttributes}>
      {imagePreview(80, 53)}
      <Wrapper className="wrapper">
        {titlePreview}
        {descriptionPreview}
      </Wrapper>
      {buttonPanel}
    </Template_4>
  );

  const template_5 = template_2;

  const template_6 = template_4;

  const templates = {
    template_0,
    template_1,
    template_2,
    template_3,
    template_4,
    template_5,
    template_6,
  };

  return templates[id] || null;
};

export default Template;
