export class PickerPlugin {
  get eventSource() {
    if (!this._eventsource) {
      this._eventsource = $({});
    }
    return this._eventsource;
  }

  constructor(options) {
    this.options = options;
  }

  attach() {
    console.error('not implemented');
  }

  detach() {
    console.error('not implemented');
  }

  choose() {
    console.error('not implemented');
  }
}

export class PickerPluginDropbox extends PickerPlugin {
  attach() {}

  detach() {}

  choose() {
    this.eventSource.trigger($.Event('file-start'));
    const options = {
      success: (files) => {
        const { link, name } = files[0];
        this.eventSource.trigger('file-success', {
          url: link,
          mime_type: undefined,
          filename: name,
        });
      },
      cancel: () => {
        this.eventSource.trigger('file-cancel');
      },
      linkType: 'direct',
      multiselect: false,
    };
    Dropbox.choose(options);
  }
}

export class PickerPluginDrive extends PickerPlugin {
  attach() {}

  detach() {}

  choose() {
    const that = this;
    that.eventSource.trigger('file-start');
    // Authorization scopes required by the API; multiple scopes can be
    // included, separated by spaces.
    const SCOPES = 'https://www.googleapis.com/auth/drive.readonly';

    // Set to client ID and API key from the Developer Console
    const CLIENT_ID = this.options.client_id;
    const API_KEY = this.options.developer_key;

    const gapi = window.gapi;
    const google = window.google;

    let tokenClient;
    let accessToken = null;

    loadGapi();
    loadGis();
    authorize();

    /**
     * Callback after api.js is loaded.
     */
    function loadGapi() {
      gapi.load('client:picker', initializePicker);
    }

    /**
     * Callback after the API client is loaded. Loads the
     * discovery doc to initialize the API.
     */
    async function initializePicker() {
      await gapi.client.load('https://www.googleapis.com/discovery/v1/apis/drive/v3/rest');
    }

    /**
     * Callback after Google Identity Services are loaded.
     */
    function loadGis() {
      tokenClient = google.accounts.oauth2.initTokenClient({
        client_id: CLIENT_ID,
        scope: SCOPES,
        callback: '', // defined later
      });
    }

    /**
     *  Sign in the user.
     */
    function authorize() {
      tokenClient.callback = async (response) => {
        if (response.error !== undefined) {
          throw response;
        }
        accessToken = response.access_token;
        await createPicker();
      };

      if (accessToken === null) {
        // Prompt the user to select a Google Account and ask for consent to share their data
        // when establishing a new session.
        tokenClient.requestAccessToken({ prompt: 'consent' });
      } else {
        // Skip display of account chooser and consent dialog for an existing session.
        tokenClient.requestAccessToken({ prompt: '' });
      }
    }

    /**
     *  Create and render a Picker object.
     */
    async function createPicker() {
      const view = new google.picker.View(google.picker.ViewId.DOCS);
      const picker = new google.picker.PickerBuilder()
        .setDeveloperKey(API_KEY)
        .setOAuthToken(accessToken)
        .addView(view)
        .setCallback(pickerCallback)
        .build();
      picker.setVisible(true);
    }

    function getDownloadFileInfo(fileId, mimeType, exportLinks) {
      const googleMimeType = 'application/vnd.google-apps.';
      const MIME_TYPE_MAP = {
        spreadsheet: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        presentation: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
        document: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      };

      let url = `https://www.googleapis.com/drive/v3/files/${fileId}?key=${API_KEY}&alt=media`;
      let fileMimeType = mimeType;
      if (mimeType.includes(googleMimeType)) {
        const exportMimeType = MIME_TYPE_MAP[mimeType.replace(googleMimeType, '')];
        if (exportMimeType && exportLinks) {
          fileMimeType = exportMimeType;
          url = exportLinks[exportMimeType];
        } else {
          url = null;
        }
      }

      return { url, fileMimeType };
    }

    /**
     * Displays the file details of the user's selection.
     * @param {object} data - Containers the user selection from the picker
     */
    async function pickerCallback(data) {
      if (data.action === google.picker.Action.PICKED) {
        const document = data[google.picker.Response.DOCUMENTS][0];
        const fileId = document[google.picker.Document.ID];
        const fileInfo = await gapi.client.drive.files.get({
          fileId,
          fields: 'name, size, mimeType, exportLinks, webViewLink',
        });
        const { name, mimeType, exportLinks, webViewLink, size } = fileInfo.result;
        const { url, fileMimeType } = getDownloadFileInfo(fileId, mimeType, exportLinks);
        that.eventSource.trigger('file-success', {
          url,
          webViewLink,
          mime_type: fileMimeType,
          filename: name,
          accessToken,
          size,
        });
      }

      if (data[google.picker.Response.ACTION] == google.picker.Action.CANCEL) {
        that.eventSource.trigger('file-cancel');
      }
    }
  }
}
