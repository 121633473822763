import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import CheckBox from '../CheckBox';
import {
  Container,
  Table,
  TableRow,
  TableCell,
  TableCellItem,
  TableHeader,
  TableHeaderCell,
  TableBody,
  TableHeaderCellRotation,
} from './styles';

import useApi from '../../hooks/useApi';

const ChecklistGrid = ({ sessionUuid, nodeUuid, userToken }) => {
  const { getData } = useApi(userToken);

  const fetchData = async () => {
    const response = await getData(`/api/v3/viewer/behaviors/${nodeUuid}/session/${sessionUuid}?all=true`);
    if (response.success && response.body) {
      setItems((response.body.data.ChecklistItems || []).sort((a, b) => a.order - b.order));
      setReports(response.body.reports);
    }
  };

  const [items, setItems] = useState([]);
  const [reports, setReports] = useState([]);

  useEffect(() => {
    fetchData();
  }, [nodeUuid, sessionUuid]);

  const list = items.length > 0 && [
    <TableHeader key={0}>
      <TableRow key={`row0`}>
        <TableHeaderCell></TableHeaderCell>
        {reports.map((report) => {
          return (
            <TableHeaderCell key={`row0-report${report.id}`}>
              <TableHeaderCellRotation>
                {report.ChecklistParticipations.map(
                  ({ Participation }) => Participation.OrganisationParticipation.User.full_name,
                ).join(', ')}
              </TableHeaderCellRotation>
            </TableHeaderCell>
          );
        })}
      </TableRow>
    </TableHeader>,
    <TableBody key={1}>
      {items.map((item, index) => {
        return (
          <TableRow key={`row${index + 1}-item${item.id}`}>
            <TableCellItem>{item.content}</TableCellItem>
            {reports.map((report) => {
              return (
                <TableCell key={`${report.id}`}>
                  <CheckBox
                    key={item.id}
                    id={`${item.id}`}
                    text={''}
                    defaultChecked={report.list && report.list[item.id] ? true : false}
                    onChange={() => {}}
                    value={1}
                    lightMode={true}
                    noLabel={true}
                    disabled={true}
                  />
                </TableCell>
              );
            })}
          </TableRow>
        );
      })}
    </TableBody>,
  ];

  return (
    <>
      <Container>
        <Table>{list}</Table>
      </Container>
    </>
  );
};

ChecklistGrid.propTypes = {
  sessionUuid: PropTypes.string.isRequired,
  nodeUuid: PropTypes.string.isRequired,
  userToken: PropTypes.string.isRequired,
};

export default ChecklistGrid;
