import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  height: 28px;
`;

export const InputField = styled.input`
  background-color: ${({ theme }) => theme.colors.transparent};
  color: ${({ theme }) => theme.colors.hoverGrey};
  border: 1px solid ${({ theme }) => theme.colors.hoverGrey};
  text-align: center;
  transition:
    color 0.2s ease,
    border-color 0.2s ease;

  &:hover:not(&:disabled),
  &:focus:not(&:disabled) {
    color: ${({ theme }) => theme.colors.white};
    border-color: ${({ theme }) => theme.colors.white};
    transition:
      color 0.2s ease,
      border-color 0.2s ease;
  }

  &:disabled {
    opacity: 0.7;
  }

  ${({ hideArrows }) =>
    hideArrows &&
    css`
      /* Chrome, Safari, Edge, Opera */
      ::-webkit-outer-spin-button,
      ::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      /* Firefox */
      & {
        -moz-appearance: textfield;
      }
    `};
`;
