class Tooltip {
  constructor(msg, ctx) {
    this.msg = msg;
    this.ctx = ctx;
  }

  displayFor(el) {
    const bounds = el.getBoundingClientRect();
    if (!this.el) {
      this.el = $('<div>')
        .addClass('lp--toolTip ' + this.ctx.class)
        .html(this.msg);
    }
    $('body').append(this.el);
    let width = this.el.outerWidth();
    let height = this.el.outerHeight();
    var pos;
    if (this.ctx.pos == 'bottom') {
      pos = {
        top: bounds.bottom + 20,
        left: bounds.left + bounds.width / 2 - width / 2,
      };
    } else {
      pos = {
        top: bounds.top - height - 20,
        left: bounds.left + bounds.width / 2 - width / 2,
      };
    }
    this.el.css(pos);
    this.el.addClass('display');
  }

  hide() {
    this.el.remove();
  }
}

$(() => {
  $(document).on('mouseover.tooltip', '.lp--js-tool-tip', function (ev) {
    let tooltip = new Tooltip(this.getAttribute('data-tooltip'), {
      pos: this.getAttribute('data-tooltip-pos'),
      class: this.getAttribute('data-tooltip-ctx'),
    });
    if (tooltip && tooltip.msg) {
      tooltip.displayFor(this);
      $(this).one('mouseout.tooltip', (ev) => {
        tooltip.hide();
      });
      $(document).one('tooltip.hide.all', (ev) => {
        tooltip.hide();
      });
      $(document).one('panel.fullscreen', (ev) => {
        tooltip.hide();
      });
    }
  });
});
