import styled from 'styled-components';
import { animated } from '@react-spring/web';
import { PopupMask } from '../Popup/styles';

import Bullet from '../../icons/play-green.svg';

export const ComponentWrapper = styled.div`
  font-family: ${({ theme }) => theme.fonts.roboto};
  font-weight: 400;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.standardTextGrey};
`;

export const PurpleGuideWrapper = styled(animated.div)`
  background-color: ${({ theme }) => theme.colors.guidePurple};
  border-radius: 0;
  width: 433px;
  position: fixed;
  z-index: 23;
  top: 60px;
  right: 0;
  bottom: 0;
  overflow: auto;
  transition: border-radius 0.3s;
  border: 2px solid ${({ theme }) => theme.colors.white};
  border-right: none;

  &.top {
    transition: border-radius 0.3s;
    border-top-left-radius: 16px;
  }

  &.bottom {
    transition: border-radius 0.3s;
    border-bottom-left-radius: 16px;
  }

  .show-mask & {
    z-index: 5;
  }
`;

export const PurpleGuideTab = styled(animated.div)`
  position: fixed;
  z-index: 7;
  background-color: ${({ theme }) => theme.colors.guidePurple};
  top: 60px;
  right: 0px;
  width: 30px;
  height: 84px;
  border-radius: 16px 0 0 16px;
  align-items: center;
  cursor: pointer;
  transition:
    color 0.2s ease,
    border-color 0.2s ease;
  border: 2px solid ${({ theme }) => theme.colors.headerGrey};
  border-right: none;

  .show-mask & {
    z-index: 5;
  }

  &:hover,
  &:focus {
    border-color: ${({ theme }) => theme.colors.white};
    transition:
      color 0.2s ease,
      border-color 0.2s ease;
  }
`;

export const TabText = styled.span`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%) rotate(-90deg);
`;

export const PurpleGuideContent = styled.div`
  height: 100%;
  padding: 46px 46px 0;
  display: flex;
  flex-direction: column;
`;

export const GuideClose = styled.div`
  position: absolute;
  right: 19px;
  top: 19px;
  width: 25px;
  height: 25px;
  cursor: pointer;
  background-image: url(/assets/images/theme-2/close-white.svg);
  background-size: 16px 16px;
  background-repeat: no-repeat;
  background-position: center center;
  display: block;
  opacity: 0.8;
  transition: opacity 0.2s ease-in-out;

  &:hover, &:focus {
    opacity: 1;
    transition: opacity 0.2s ease-in-out;
  }
}
`;

export const Header = styled.div`
  padding-top: 54px;
  padding-left: 22px;
  font-family: ${({ theme }) => theme.fonts.helveticaNeue};
  font-size: 28px;
  line-height: 34px;
`;

export const SubHeader = styled.div`
  padding-top: 49px;
  padding-left: 22px;
  padding-bottom: 11px;
  font-family: ${({ theme }) => theme.fonts.roboto};
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
`;

export const Collection = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 22px;
  height: 100%;
`;

export const CollectionItem = styled.div`
  padding-bottom: 40px;
  font-size: 16px;
  line-height: 20px;
  font-family: ${({ theme }) => theme.fonts.helveticaNeue};

  :before {
    content: ' ';
    width: 10px;
    height: 10px;
    position: absolute;
    margin-left: -24px;
    margin-top: 3px;
    background: url(${Bullet}) center center no-repeat;
  }

  a {
    text-decoration: underline !important; // override bootstrap
  }

  li {
    list-style-type: disc;
    margin-left: 7px;
    margin-top: 5px;
  }
`;

export const Footer = styled.div`
  padding: 4px 4px 46px;
  font-size: 16px;
  line-height: 18px;
  font-family: ${({ theme }) => theme.fonts.helveticaNeue};
  margin-top: auto;

  a {
    padding-bottom: 2px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.white};
    transition:
      color 0.2s ease,
      border-color 0.2s ease;

    &:hover,
    &:focus {
      border-color: ${({ theme }) => theme.colors.loopsGreen};
      color: ${({ theme }) => theme.colors.loopsGreen};
      transition:
        color 0.2s ease,
        border-color 0.2s ease;
    }
  }
`;

export const Mask = styled(PopupMask)``;
