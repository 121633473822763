import styled, { css } from 'styled-components';

export const ReportStateContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 15px;
`;

export const StateSwitchButton = styled.a`
  margin-left: 10px;
  width: 26px;
  height: 26px;
  position: relative;
  border-radius: 50%;
  display: block;
  flex-shrink: 0;
  cursor: pointer;
  transition: box-shadow 0.2s ease;

  &:after {
    display: block;
    width: 26px;
    height: 26px;
    content: ' ';
    background-size: cover;

    ${({ stateImage }) =>
      stateImage &&
      css`
        background-image: url(${stateImage});
      `}
  }

  &:hover {
    box-shadow: 0 0 0 2px ${({ theme }) => theme.colors.black_5};
    transition: box-shadow 0.2s ease;
  }
`;

export const StateLabel = styled.span``;

export const StateListContainer = styled.div`
  opacity: 1;
  position: absolute;

  bottom: 40px;
  top: auto;
  right: auto;
  left: -10px;

  ${({ open }) => css`
    display: ${open ? 'block' : 'none'};
  `}

  background: ${({ theme }) => theme.colors.white};
  z-index: 4;
  color: ${({ theme }) => theme.colors.black};
  border-radius: 8px;
  box-shadow: 0px 0px 4px ${({ theme }) => theme.colors.black_4};

  &:after,
  &:before {
    border: solid ${({ theme }) => theme.colors.transparent};
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;

    top: 100%;
    bottom: auto;
    left: 20px;
  }

  &:after {
    border-color: ${({ theme }) => theme.colors.transparent};
    border-top-color: ${({ theme }) => theme.colors.white};
    border-bottom: none;
    border-width: 10px;
    margin-left: -10px;
  }

  &:before {
    border-top-color: ${({ theme }) => theme.colors.black_3};
    border-bottom: none;
    border-width: 11px;
    margin-left: -11px;
  }
`;

export const StateList = styled.ul`
  position: relative;
  display: block;
  width: auto;
  padding: 15px 14px;
`;

export const StateListItem = styled.li`
  padding: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.colors.transparent};
  border-radius: 0;
  transition: background-color 0.2s ease;

  &:hover,
  &:focus {
    background-color: ${({ theme }) => theme.colors.black_1};
    border-radius: 8px;
    transition: background-color 0.2s ease;
  }

  ${({ lockItem }) =>
    lockItem &&
    css`
      margin-top: 6px;
      border-top: 1px solid ${({ theme }) => theme.colors.black_2};
    `}
`;

export const StateListItemIcon = styled.div`
  width: 20px;
  height: 20px;
  flex: none;
  margin-left: 10px;
  background-size: 20px 20px;
  border-radius: 50%;

  ${({ src }) =>
    src &&
    css`
      background-image: url(${src});
    `}
`;

export const StateListItemLabel = styled.div`
  flex: none;
  white-space: nowrap;
`;
