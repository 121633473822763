export default (el, p) => {
  if (!el) {
    return;
  }
  var stack = [];
  var isShadow = false;
  while (el.parentNode != null && el != p) {
    var sibCount = 0;
    var sibIndex = 0;
    for (var i = 0; i < el.parentNode.childNodes.length; i++) {
      var sib = el.parentNode.childNodes[i];
      if (sib.nodeName == el.nodeName) {
        if (sib === el) {
          sibIndex = sibCount;
        }
        sibCount++;
      }
    }
    var nodeName = el.nodeName.toLowerCase();
    if (isShadow) {
      nodeName += '::shadow';
      isShadow = false;
    }
    if (sibCount > 1) {
      stack.unshift(nodeName + ':nth-of-type(' + (sibIndex + 1) + ')');
    } else {
      stack.unshift(nodeName);
    }
    el = el.parentNode;
    if (el.nodeType === 11) {
      // for shadow dom, we
      isShadow = true;
      el = el.host;
    }
  }
  return stack.join(' > ');
};
