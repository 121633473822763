import React, { useRef, useMemo, useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Quill from '../../../app/assets/js/shared/components/quill.extended';
import flash from '../../../app/assets/js/shared/flash';
import Utils from '../../../app/assets/js/shared/utils';
import Panel from '../../../app/assets/js/shared/panels';
import { PickerPluginDrive } from '../../../app/assets/js/shared/picker';
import Recorder from '../Recorder';
import PropTypes from 'prop-types';
import { Wrapper } from './styles';
import initializeIcons from './icons';
import clipboard from './clipboard';
import defaultToolbar from './toolbar';

const ContentEditor = (props) => {
  const {
    id = 'contentEditor',
    type = '',
    panelType,
    theme = 'snow',
    value = '',
    placeholder = '',
    onChange,
    integrations,
    fileService,
    disabled,
    canToggleToolbar = true,
    showToolbar,
    toolbar,
    ...rest
  } = props;
  const quillRef = useRef(null);
  const fileRef = useRef(null);
  const [recorderIsOpen, setRecorderIsOpen] = useState('');

  useEffect(() => {
    document.addEventListener('mediaprogress.updated', mediaProgressHandler);
    return () => document.removeEventListener('mediaprogress.updated', mediaProgressHandler);
  }, []);

  useEffect(() => {
    document.addEventListener('quill-embed-load', contentUpdated);
    document.addEventListener('templateSelected', contentUpdated);
    return () => {
      document.removeEventListener('quill-embed-load', contentUpdated);
      document.removeEventListener('templateSelected', contentUpdated);
    };
  }, []);

  const getQuillEditor = () => quillRef && quillRef.current && quillRef.current.getEditor();

  const toggleRecorder = (recordingType) => {
    setRecorderIsOpen(recordingType);
  };

  const contentUpdated = () => {
    const Delta = Quill.import('delta');
    const quillEditor = getQuillEditor();
    quillEditor.updateContents(new Delta().retain(1));
  };

  const onFileChange = (ev) => {
    const file = ev.target.files[0];
    pasteAndDropHandler(file);
  };

  const appId = Utils.getOptions().cameraTag.appId;
  const userId = Utils.getCurrentUserData().uuid;

  const insertMediaHandler = () => {
    fileRef.current.click();
  };

  const attachmentHandler = () => {
    fileRef.current.click();
  };

  const dropboxHandler = () => {
    const options = {
      success: (files) => {
        const { link, icon, name } = files[0];
        embedUploadedFile({
          url: link,
          thumbnail: icon,
          fileName: name,
          source: AttachmentSource.Dropbox,
        });
      },
      cancel: () => {},
      linkType: 'preview',
      multiselect: false,
    };
    window.Dropbox.choose(options);
  };

  const gdriveHandler = () => {
    const picker = new PickerPluginDrive(integrations.drive);
    picker.eventSource.on('file-success', (ev, file) => {
      const { url, filename, mime_type } = file;
      embedUploadedFile({
        url: url,
        fileName: filename,
        mimeType: mime_type,
        source: AttachmentSource.Drive,
      });
    });
    picker.choose();
  };

  const onedriveHandler = () => {
    const options = {
      clientId: integrations.onedrive.client_id,
      action: 'query',
      multiselect: false,
      advanced: {
        queryParameters: 'select=id,name,file,@microsoft.graph.downloadUrl',
        redirectUri: integrations.onedrive.redirect_uri,
      },
      success: (response) => {
        const item = response.value[0];
        const { name, thumbnails, file } = item;
        embedUploadedFile({
          url: item['@microsoft.graph.downloadUrl'],
          thumbnail: thumbnails ? thumbnails[0].small.url : null,
          fileName: name,
          mimeType: file.mimeType,
          source: AttachmentSource.OneDrive,
        });
      },
      cancel: () => {},
      error: () => {
        flash(i18n.__('app_error_alert'));
      },
    };
    window.OneDrive.open(options);
  };

  const recordHandler = (recordingType) => {
    $(document.getElementsByClassName('content-panel')[0]).scrollTop(0, 0);
    toggleRecorder(recordingType);
  };

  const videoHandler = () => {
    recordHandler('video');
  };

  const audioHandler = () => {
    recordHandler('audio');
  };

  const dividerHandler = () => {
    const quillEditor = getQuillEditor();
    const range = quillEditor.getSelection(true);
    quillEditor.insertEmbed(range.index, 'divider', true, Quill.sources.USER);
    quillEditor.setSelection(range.index + 1, Quill.sources.SILENT);
  };

  const linkHandler = (value) => {
    const quillEditor = getQuillEditor();
    if (value) {
      const range = quillEditor.getSelection(true);
      if (range == null || range.length === 0) {
        flash(i18n.__('toolbar_button_link_no_text_selected'));
        return;
      }
      const { tooltip } = quillEditor.theme;
      tooltip.edit('link', '');
      tooltip.textbox.placeholder = location.origin;
    } else {
      quillEditor.format('link', false);
    }
  };

  const textSizeHandler = (value) => {
    const quillEditor = getQuillEditor();
    const range = quillEditor.getSelection(true);
    quillEditor.formatText(range.index, range.length, 'size', value, Quill.sources.USER);
  };

  const keyboard = {
    bindings: {
      handleEnter: {
        key: 13,
        handler: (range, context) => {
          const quillEditor = getQuillEditor();
          if (range.length > 0) {
            quillEditor.scroll.deleteAt(range.index, range.length); // So we do not trigger text-change
          }
          // Stop formatting as list when hitting enter for an empty item
          if (!context.prefix && context.format.list) {
            delete context.format.list;
            quillEditor.removeFormat(range.index, 1);
          }
          const lineFormats = Object.keys(context.format).reduce((lineFormats, format) => {
            const Parchment = Quill.import('parchment');
            if (Parchment.query(format, Parchment.Scope.BLOCK) && !Array.isArray(context.format[format])) {
              lineFormats[format] = context.format[format];
            }
            return lineFormats;
          }, {});
          const previousChar = quillEditor.getText(range.index - 1, 1);
          // Earlier scroll.deleteAt might have messed up our selection,
          // so insertText's built in selection preservation is not reliable
          quillEditor.insertText(range.index, '\n', lineFormats, Quill.sources.USER);
          if (previousChar === '' || previousChar === '\n') {
            quillEditor.setSelection(range.index + 2, Quill.sources.SILENT);
          } else {
            quillEditor.setSelection(range.index + 1, Quill.sources.SILENT);
          }
          Object.keys(context.format).forEach((name) => {
            if (lineFormats[name] !== null || Array.isArray(context.format[name]) || name === 'link') {
              return;
            }
            quillEditor.format(name, context.format[name], Quill.sources.USER);
          });
        },
      },
      linebreak: {
        key: 13,
        shiftKey: true,
        handler: (range) => {
          const quillEditor = getQuillEditor();
          const currentLeaf = quillEditor.getLeaf(range.index)[0];
          const nextLeaf = quillEditor.getLeaf(range.index + 1)[0];

          quillEditor.insertEmbed(range.index, 'break', true, 'user');

          // Insert a second break if:
          // At the end of the editor, OR next leaf has a different parent (<p>)
          if (nextLeaf === null || currentLeaf.parent !== nextLeaf.parent) {
            quillEditor.insertEmbed(range.index, 'break', true, 'user');
          }

          // Now that we've inserted a line break, move the cursor forward
          quillEditor.setSelection(range.index + 1, Quill.sources.SILENT);
        },
      },
    },
  };

  const uploadProgressBlot = (progressEvent) => {
    const { loaded, total } = progressEvent;
    const percentDone = Math.floor((loaded * 100) / total);
    $('.lp--activityEditorProgressLabel').text(percentDone + '%');
    $('.lp--activityEditorProgressBar').width(percentDone + '%');
  };

  const pasteAndDropHandler = (file, base64Data) => {
    if (!(file instanceof File)) {
      return;
    }

    const quillEditor = getQuillEditor();
    const range = quillEditor.getSelection(true);
    const key = Date.now();
    insertBlot(quillEditor, 'progress', key, range);
    const onUploadProgress = (progressEvent) => {
      uploadProgressBlot(progressEvent);
    };

    const uploadFile = async () => {
      const data = await Utils.uploadFileToArchivist({ file, onUploadProgress });

      if (data) {
        const { filemime, filename, original_filename, oembed } = data;
        embedUploadedFile({
          url: fileService.url + filename,
          uuid: filename.split('.')[0],
          fileName: original_filename,
          mimeType: filemime,
          oembed,
          key,
          source: AttachmentSource.DropPaste,
        });
      } else if (base64Data) {
        const Delta = Quill.import('delta');
        quillEditor.updateContents(
          new Delta().retain(range.index).delete(range.length).insert({ image: base64Data }),
          Quill.sources.USER,
        );
        quillEditor.setSelection(range.index + 1, Quill.sources.SILENT);
      }
    };
    setTimeout(() => uploadFile(), 200);
  };

  const addClipBoardMatcher = (quillEditor) => {
    quillEditor.clipboard.addMatcher(Node.TEXT_NODE, (node, delta) => {
      if (node.data.match(/^http(s)*\:\/\//)) {
        if (node.data.match(/jp(e)*g|gif|png|webp/)) {
          return { ops: [{ insert: { image: node.data } }, { insert: '\n' }] };
        } else {
          return { ops: [{ insert: { oembed: { url: node.data, newEmbed: true } } }] };
        }
      } else if (node.data.match(/<iframe.*?s*src="http(.*?)".*?<\/iframe>/)) {
        return { ops: [{ insert: { iframe: node.data } }] };
      } else {
        delta.ops = delta.ops.map((el) => {
          if (el.insert && el.insert.normalize) {
            el.insert = el.insert.normalize();
          }
          return el;
        });
        return delta;
      }
    });
  };

  const AttachmentSource = {
    Upload: 'upload',
    Drive: 'drive',
    Dropbox: 'dropbox',
    Webcam: 'webcam',
    Microphone: 'microphone',
    OneDrive: 'onedrive',
    DropPaste: 'drop_paste',
  };

  const removeBlot = (quillEditor, blotInstance) => {
    const blot = blotInstance && Quill.find(blotInstance);
    const index = blot && quillEditor.getIndex(blot);
    blot &&
      quillEditor.deleteText({
        index,
        length: 1,
        source: Quill.sources.SILENT,
      });
  };

  const insertBlot = (quillEditor, blotType, key, range, textKey) => {
    quillEditor.insertEmbed(range.index, blotType, key, Quill.sources.SILENT);
    if (textKey) {
      $(`#${blotType}-progress-${key} .lp--activityEditorProgressLabel`).html(i18n.__(textKey));
    }
    return blotType === 'progress' ? $(`#upload-progress-${key}`) : $(`#${blotType}-progress-${key}`);
  };

  const isLinkedFile = (source) =>
    source === AttachmentSource.Dropbox || source === AttachmentSource.Drive || source === AttachmentSource.OneDrive;

  const usesProgressBlot = (source) => !isLinkedFile(source) && source !== AttachmentSource.DropPaste;

  const embedFile = (quillEditor, range, url, fileName) => {
    quillEditor.insertEmbed(range.index, 'file', { url, fileName }, Quill.sources.USER);
  };

  const embedUploadedFile = (data) => {
    const quillEditor = getQuillEditor();
    const { url, uuid, fileName, mimeType, oembed, source, key } = data;

    const range = quillEditor.getSelection(true) || { index: 0, length: 0 };
    const index = range.index;
    const progress = key && document.querySelector(`#upload-progress-${key}, #conversion-progress-${key}`);
    if (!progress && usesProgressBlot(source)) {
      return;
    }
    removeBlot(quillEditor, progress);

    if (isLinkedFile(source)) {
      embedFile(quillEditor, range, url, fileName);
    } else if (mimeType.match(/video/)) {
      const locale = $('body').data('user-base-locale');
      const localizedUrl = `${oembed}/${locale}`;
      quillEditor.insertEmbed(index, 'oembed', { uuid, url: localizedUrl }, Quill.sources.USER);
    } else if (mimeType.match(/image/)) {
      const image = new Image();
      image.onload = () => {
        quillEditor.insertEmbed(index, 'image', url, Quill.sources.USER);
      };
      image.onerror = () => {
        embedFile(quillEditor, range, url, fileName);
      };
      image.src = url;
    } else if (mimeType.match(/audio/)) {
      quillEditor.insertEmbed(index, 'audio', { uuid }, Quill.sources.USER);
    } else {
      embedFile(quillEditor, range, url, fileName);
    }

    quillEditor.setSelection(index + 1, Quill.sources.API);
  };

  const addLinkClickHandlers = (quillEditor) => {
    const root = $(quillEditor.root);
    root.find('a').off('click');
    root.find('a').on(Utils.interactionEvent, (ev) => {
      if ($(ev.target).closest('.lp--activityEditorLinkBox, .file').length > 0) {
        return;
      }
      const { tooltip } = quillEditor.theme;
      const url = root.parent().find('.ql-preview').attr('href');
      tooltip.edit('link', url);
      tooltip.textbox.placeholder = location.origin;
      root.parent().find('.ql-editing input')[0].setSelectionRange(url.length, url.length);
      setTooltipArrowPosition(quillEditor);
    });
  };

  const addTooltips = (quillEditor) => {
    quillEditor.options.modules.toolbar.container.forEach((container) => {
      container.forEach((item) => {
        const key = Object.keys(item)[0];
        const keyPrefix = 'toolbar_button_tooltip_';
        if (typeof item === 'object' && typeof item[key] !== 'object') {
          $(`.ql-toolbar button.ql-${key}[value="${item[key]}"]`).attr(
            'title',
            i18n.__(`${keyPrefix}${key}_${item[key]}`),
          );
        } else if (typeof item === 'object' && typeof item[key] === 'object') {
          $(`.ql-toolbar span.ql-${key}`).attr('title', i18n.__(`${keyPrefix}${key}`));
        } else {
          $(`.ql-toolbar button.ql-${item}`).attr('title', i18n.__(`${keyPrefix}${item}`));
          if (!navigator.appVersion.includes('Win')) {
            const tooltip = $(`button.ql-${item}[title*="Ctrl"]`).attr('title');
            if (tooltip) {
              $(`button.ql-${item}`).attr('title', tooltip.replace('Ctrl', 'Cmd'));
            }
          }
        }
      });
    });
  };

  const addTextSizeCustomizations = (quillEditor) => {
    const textSizeButton = $(quillEditor.container).parent().find('.ql-textsize');
    textSizeButton.find('.ql-picker-item').each((index, item) => {
      const size = item.dataset.value ? item.dataset.value : 'normal';
      item.textContent = i18n.__(`toolbar_button_tooltip_textsize_${size}`);
      item.classList.add(`ql-size-${size}`);
    });
    const toolbarIcons = Quill.import('ui/icons');
    textSizeButton.addClass('ql-icon-picker');
    textSizeButton.find('.ql-picker-label').html(toolbarIcons['textsize']);
    textSizeButton.on(Utils.interactionEvent, () => {
      const range = quillEditor.getSelection(true);
      const rangeFormat = quillEditor.getFormat(range.index, range.length);
      if (rangeFormat && rangeFormat['size']) {
        const sizeClass = `ql-size-${rangeFormat['size']}`;
        textSizeButton.find('.ql-picker-item').removeClass('ql-selected');
        textSizeButton.find(`.ql-picker-item.${sizeClass}`).addClass('ql-selected');
      } else {
        textSizeButton.find('.ql-picker-item.ql-size-normal').addClass('ql-selected');
      }
    });
  };

  const setTooltipArrowPosition = (quillEditor) => {
    const tooltip = $('.ql-tooltip.ql-editing');
    if (tooltip.length > 0) {
      const range = quillEditor.getSelection(true);
      const bounds = quillEditor.getBounds(range.index, range.length);
      const tootipLeft = tooltip.position() ? tooltip.position().left : 0;
      const position = bounds.width / 2 + bounds.left + tootipLeft / 2;
      tooltip.css('--arrowPosition', `${position}px`);
      if (position < 20) {
        const newPosition = Math.max(position + 23, 23);
        tooltip.css({ transform: 'translateX(-20px)', '--arrowPosition': `${newPosition}px` });
      }
    }
  };

  const getPlaceholderText = () => {
    if (window.editor) {
      if (panelType === Panel.CIRCLE || panelType === Panel.HUB) {
        return placeholder;
      } else {
        return i18n.__('default_texteditor_placeholder');
      }
    }
    if (type === 'evaluation') {
      return placeholder;
    } else if (type === 'task' || type === 'written_quiz') {
      return i18n.__('task_texteditor_placeholder');
    } else if (type === 'logbook') {
      return i18n.__('logbook_texteditor_placeholder');
    } else if (type === 'group') {
      return i18n.__('grouptask_texteditor_placeholder');
    } else if (type === 'noticeboard') {
      return i18n.__('noticeboard_texteditor_placeholder');
    } else {
      return i18n.__('default_texteditor_placeholder');
    }
  };

  const mediaProgressHandler = (ev) => {
    const quillEditor = getQuillEditor();
    const { key, fileName } = ev.detail.userMetadata;
    const oembed = $(`.oembed[data-uuid="${key}"]`);
    if (ev.detail.status === 'ERROR') {
      flash(i18n.__('media_conversion_failed'));
      oembed.remove();
      const range = quillEditor.getSelection(true);
      const fileExtension = Utils.GetFileExtension(fileName);
      embedFile(quillEditor, range, `${fileService.url}${key}.${fileExtension}`, fileName);
    } else {
      const iframe = oembed.find('iframe');
      if (iframe.length > 0) {
        iframe.attr('src', iframe.attr('src'));
      }
    }
  };

  const addAriaAttributes = () => {
    const hiddenElements = $('.ql-toolbar > span > span > span');
    hiddenElements.attr('aria-hidden', true);
  };

  const initializeEditor = () => {
    const quillEditor = getQuillEditor();
    if (quillEditor) {
      const quillParent = $(quillEditor.container).parent();

      addTextSizeCustomizations(quillEditor);
      addLinkClickHandlers(quillEditor);
      addTooltips(quillEditor);
      addClipBoardMatcher(quillEditor);
      addAriaAttributes();

      quillParent.find('.ql-color-picker').on(Utils.interactionEvent, () => {
        quillEditor.getSelection(true);
      });

      quillParent.find('.ql-align').on('mouseup', () => {
        quillEditor.getSelection(true);
      });

      quillParent.find('.ql-link').on(Utils.interactionEvent, () => {
        setTooltipArrowPosition(quillEditor);
      });

      quillParent.find('.ql-emoji').on(Utils.interactionEvent, () => {
        const observer = new MutationObserver(() => {
          quillEditor.setSelection(quillEditor.getLength(), Quill.sources.SILENT);
          quillEditor.focus();
          observer.disconnect();
        });

        observer.observe($('.ql-editor')[0], {
          childList: true,
          subtree: true,
        });
      });
    }
  };

  initializeIcons();
  initializeEditor();

  const modules = {
    clipboard,
    keyboard,
    toolbar: {
      container: toolbar || defaultToolbar,
      handlers: {
        attachment: attachmentHandler,
        audio: audioHandler,
        divider: dividerHandler,
        dropbox: dropboxHandler,
        gdrive: gdriveHandler,
        image: insertMediaHandler,
        link: linkHandler,
        onedrive: onedriveHandler,
        textsize: textSizeHandler,
        video: videoHandler,
      },
    },
    imageResize: {},
    'emoji-toolbar': true,
    fileDrop: {
      pasteAndDropHandler,
    },
  };

  const recordingPublished = (recording) => {
    $('.recorder').hide();
    const quillEditor = getQuillEditor();
    const range = quillEditor.getSelection(true);
    if (recording.source == AttachmentSource.Webcam) {
      quillEditor.insertEmbed(range.index, 'webcam', recording, Quill.sources.USER);
    } else if (recording.source == AttachmentSource.Microphone) {
      quillEditor.insertEmbed(range.index, 'microphone', recording, Quill.sources.USER);
    }
    quillEditor.setSelection(range.index + 1, Quill.sources.SILENT);
  };

  const recordingProcessed = (recording) => {
    toggleRecorder('');
    let mediaSelector = recording.type === 'Video' ? '.webcam' : '.microphone';
    mediaSelector += `[data-uuid="${recording.uuid}"]`;
    if ($(mediaSelector).length > 0) {
      $(mediaSelector).removeAttr('data-state').children(recording.type).removeAttr('poster')[0].load();
    }
  };

  return (
    <Wrapper showToolbar={showToolbar} canToggleToolbar={canToggleToolbar}>
      <ReactQuill
        id={id}
        name={id}
        ref={quillRef}
        theme={theme}
        placeholder={getPlaceholderText()}
        value={value}
        onChange={(value, delta, source) => {
          source === 'user' && onChange(value, id);
        }}
        modules={useMemo(() => modules, [id])}
        readOnly={disabled}
        {...rest}
      />
      <input
        id={`file${id}`}
        onChange={onFileChange}
        type="file"
        ref={fileRef}
        style={{ display: 'none' }}
        accept="*/*"
      />
      {recorderIsOpen && (
        <Recorder
          appId={appId}
          userId={userId}
          type={recorderIsOpen}
          onClose={() => toggleRecorder('')}
          onRecordingPublished={recordingPublished}
          onRecordingProcessed={recordingProcessed}
          AttachmentSource={AttachmentSource}
        />
      )}
    </Wrapper>
  );
};

export default ContentEditor;

ContentEditor.propTypes = {
  type: PropTypes.string.isRequired,
  panelType: PropTypes.number.isRequired,
  theme: PropTypes.string,
  value: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  integrations: PropTypes.object,
  fileService: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  showToolbar: PropTypes.bool,
  canToggleToolbar: PropTypes.bool,
  rest: PropTypes.object,
};
