import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Wrapper, InputField } from './styles';

const NumericField = ({
  id,
  name = id,
  type = 'number',
  value = 0,
  min = 0,
  max = 100,
  hideArrows = false,
  validateOnBlur = false,
  onChange,
  ...rest
}) => {
  const clamp = (value) => {
    return value > max ? max : value < min ? min : value;
  };
  const [updatedValue, setUpdatedValue] = useState(value);

  const parseValue = (ev) => {
    return ev.target.value ? parseInt(ev.target.value) : 0;
  };

  const handleChange = (ev) => {
    const newValue = validateOnBlur ? parseValue(ev) : clamp(parseValue(ev));
    setUpdatedValue(newValue);
    if (!validateOnBlur) {
      onChange(newValue);
    }
  };

  const handleBlur = (ev) => {
    if (validateOnBlur) {
      const newValue = clamp(parseValue(ev));
      setUpdatedValue(newValue);
      onChange(clamp(newValue));
    }
  };

  return (
    <Wrapper>
      <InputField
        id={id}
        name={name}
        type={type}
        value={updatedValue}
        min={min}
        max={max}
        hideArrows={hideArrows}
        onChange={handleChange}
        onBlur={handleBlur}
        {...rest}
      />
    </Wrapper>
  );
};

NumericField.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  defaultValue: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  hideArrows: PropTypes.bool,
  validateOnBlur: PropTypes.bool,
  onChange: PropTypes.func,
  rest: PropTypes.object,
};

export default NumericField;
