import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import IconButton from '../IconButton';
import useUploader from '../../hooks/useUploader';
import PlusIcon from '../../../public/assets/images/theme-2/small-plus-circle-green.svg';
import { ImageSelectorWrapper } from './styles';

const ImageSelector = ({ onUpload, onLoading, disabled, fileService, label = i18n.__('selectImage'), ...rest }) => {
  const fileRef = useRef(null);
  const { uploadFile, response, loading } = useUploader(fileService);

  useEffect(() => {
    if (response.ok && !loading && onUpload) {
      onUpload(response.data);
    }
    onLoading && onLoading(loading);
  }, [response.ok, loading]);

  const onClick = (ev) => {
    ev.preventDefault();
    fileRef.current.click();
  };

  const onChange = (ev) => {
    const file = ev.target.files[0];
    uploadFile(file);
  };

  return (
    <ImageSelectorWrapper {...rest}>
      <IconButton icon={PlusIcon} disabled={disabled} onClick={onClick}>
        {label}
      </IconButton>
      <input
        role="textbox"
        tabIndex={-1}
        onChange={onChange}
        type="file"
        ref={fileRef}
        style={{ display: 'none' }}
        accept="image/*"
      />
    </ImageSelectorWrapper>
  );
};

export default ImageSelector;

ImageSelector.propTypes = {
  onUpload: PropTypes.func.isRequired,
  onLoading: PropTypes.func,
  fileService: PropTypes.object.isRequired,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  rest: PropTypes.object,
};
