import styled, { css } from 'styled-components';

export const HubList = styled.ul`
  margin: 10px 0 20px;
  flex: 1;
`;

export const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const Wrapper = styled.div`
  display: flex;
`;

export const InfoPanel = styled.div`
  display: flex;
`;

export const StartHubImage = styled.div`
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: 50%;
  width: 33px;
  height: 33px;
  margin-top: 30px;
  margin-right: 15px;

  ${({ icon }) =>
    icon &&
    css`
      background-image: url(${icon});
    `}
`;

export const HubWrapper = styled.li`
  transition:
    color 0.2s ease-in-out,
    background-color 0.2s ease-in-out;
  font-size: 18px;
  font-weight: bold;
  background-color: ${({ theme }) => theme.colors.standardComponentBackground};
  border: 1px solid ${({ theme }) => theme.colors.iconButtonBorder};
  border-radius: 6px;
  margin: 10px 0;
  padding: 10px 0;
  min-height: 56px;
  position: relative;
  display: flex;
  align-items: center;

  &:hover,
  &:focus {
    transition:
      color 0.2s ease-in-out,
      background-color 0.2s ease-in-out;
    background-color: ${({ theme }) => theme.colors.menuButtonBorder};
    color: ${({ theme }) => theme.colors.white};
  }
`;

export const HubImage = styled.div`
  width: 33px;
  height: 33px;
  border-radius: 50%;
  background-size: cover;
  background-color: ${({ theme }) => theme.colors.iconButtonBorder};
  position: absolute;
  left: 10px;
  flex-shrink: 0;

  ${({ icon }) =>
    icon &&
    css`
      background-image: url(${icon});
    `}
`;

export const HubName = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.standardTextGrey};
  margin-left: 60px;
`;
