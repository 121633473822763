import styled, { css } from 'styled-components';

export const RemoveButtonElement = styled.div`
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};
  margin-right: 10px;
  flex-shrink: 0;
  align-self: center;
  cursor: pointer;
  filter: brightness(1);
  transition: filter 0.2s ease-in-out;

  :hover,
  :focus {
    filter: brightness(1.3);
    transition: filter 0.2s ease-in-out;
  }

  ${({ icon }) =>
    icon &&
    css`
      background: url(${icon}) center center no-repeat;
      background-size: contain;
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: default;
      pointer-events: none;
      opacity: 0.6;
    `}
`;
