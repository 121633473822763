import React, { useEffect } from 'react';
import { SkolonMenuButtonContainer } from './styles';

const SkolonMenuButton = () => {
  useEffect(() => {
    if (global.SkolonMenuButton) {
      global.SkolonMenuButton.render();
    }
  }, []);

  return (
    <SkolonMenuButtonContainer>
      <div className="skolon-menu-button" />
    </SkolonMenuButtonContainer>
  );
};

export default SkolonMenuButton;
