import useApi from './useApi';
import { useNavigate } from 'react-router-dom';

const useNavigateWithData = (href, hasRouter = true) => {
  const { getData } = useApi();
  const navigate = hasRouter ? useNavigate() : {};
  const navigateTo = hasRouter
    ? async (url = href) => {
        const path = url || location.pathname;
        const data = await getData(path);
        if (data.success) {
          navigate(path, { state: data.body });
        } else {
          console.error('Failed to retrieve data from: ', path);
        }
      }
    : (url = href) => navigateAndReload(url);

  const navigateAndReload = (url = href) => {
    location.href = url;
  };

  const reload = () => {
    location.reload();
  };

  return { navigateTo, navigateAndReload, reload };
};

export default useNavigateWithData;
