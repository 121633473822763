import styled, { css } from 'styled-components';

export const SelectionPopup = styled.div`
  top: 0;
  pointer-events: all;
  position: absolute;
  z-index: 24;
  width: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  border: 1px solid ${({ theme }) => theme.colors.hoverGrey};
  padding: 20px;
  border-radius: 0;
  background-color: ${({ theme }) => theme.colors.organizationGreen};

  @media ${({ theme }) => theme.sizes.tablet} {
    top: 50px;
    width: 636px;
    max-height: calc(100vh - 100px);
    left: 50%;
    margin-left: -318px;
    border-radius: 20px;
  }
`;

export const PopupClose = styled.span`
  position: absolute;
  top: 25px;
  right: 25px;
  font-weight: 400;
  font-size: 20px;
  width: 16px;
  height: 16px;
  background-color: ${({ theme }) => theme.colors.transparent};
  opacity: 0.5;
  transition: opacity 0.2s ease-in-out;
  cursor: pointer;
  background-image: url('/assets/images/theme-2/close-black.svg');

  &:hover,
  &:focus {
    opacity: 1;
    transition: opacity 0.2s ease-in-out;
  }
`;

export const SettingsPanel = styled.div`
  display: flex;

  label {
    color: ${({ theme }) => theme.colors.black};
  }
`;

export const SettingsHeader = styled.label`
  font-size: 20px;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 10px;
`;

export const PreviewPanel = styled.div`
  display: flex;
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;

  ${({ top }) =>
    top &&
    css`
      margin-top: ${top}px;
    `}

  ${({ bottom }) =>
    bottom &&
    css`
      margin-bottom: ${bottom}px;
    `}

  ${({ left }) =>
    left &&
    css`
      margin-left: ${left}px;
    `}
`;

export const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 100%;

  @media ${({ theme }) => theme.sizes.tablet} {
    max-width: 592px;
  }
`;

export const Description = styled.span`
  font-weight: normal;
  margin-bottom: 5px;
`;

export const ImageSetting = styled.div`
  margin-right: 20px;
  display: flex;
  flex-direction: column;

  button {
    color: ${({ theme }) => theme.colors.black};
    background-color: ${({ theme }) => theme.colors.white};

    &:hover:not([disabled]),
    &:focus:not([disabled]) {
      color: ${({ theme }) => theme.colors.black};
      background-color: ${({ theme }) => theme.colors.white};
    }

    .show-mask & {
      z-index: 1;
    }
  }

  ${({ top }) =>
    top &&
    css`
      margin-top: ${top}px;
    `}
`;

export const LinkTitle = styled.div`
  max-width: 400px;

  div {
    margin: 0;
  }

  input {
    font-weight: bold;
    font-family: ${({ theme }) => theme.fonts.roboto};
    color: ${({ theme }) => theme.colors.black};
    background-color: ${({ theme }) => theme.colors.white};
    padding: 5px;
    border-radius: 8px;
    border: 1px solid ${({ theme }) => theme.colors.black_3};

    &::selection {
      color: ${({ theme }) => theme.colors.white};
      background-color: ${({ theme }) => theme.colors.standardComponentBackground};
    }

    &::placeholder {
      color: ${({ theme }) => theme.colors.black_7};
      font-style: italic;
    }
  }
`;

export const LinkDescription = styled(LinkTitle)`
  max-width: initial;

  input {
    font-weight: normal;
    font-size: 14px;

    &::placeholder {
      color: ${({ theme }) => theme.colors.black_7};
      font-style: italic;
    }
  }
`;

export const TemplateWrapper = styled.div`
  cursor: pointer;
`;

export const Templates = styled.div`
  width: 100%;
`;

const Template = styled.a``;
export const Template_0 = styled(Template)``;
export const Template_1 = styled(Template)``;
export const Template_2 = styled(Template)``;
export const Template_3 = styled(Template)``;
export const Template_4 = styled(Template)``;
export const ImagePreview = styled.div``;
export const NoImage = styled.div``;
export const Image = styled.img`
  cursor: pointer;
`;
export const DefaultImage = styled(Image)`
  margin-right: 40px;
  width: 63px;
  height: 63px;
  transition:
    border-color 0.2s ease-in-out,
    transform 0.2s ease-in-out;
  border: 2px solid ${({ theme }) => theme.colors.iconButtonBorder};
  border-radius: 18px;
  transform: scale(1);
  outline: none;

  &:hover,
  &:focus {
    transition:
      border-color 0.2s ease-in-out,
      transform 0.2s ease-in-out;
    border-color: ${({ theme }) => theme.colors.iconButtonBorderHover};
    transform: scale(1.1);
  }
`;
export const TitlePreview = styled.div`
  ${({ placeHolder }) =>
    placeHolder &&
    css`
      font-style: italic;
      overflow: visible !important;
    `}
`;
export const DescriptionPreview = styled.div`
  ${({ placeHolder }) =>
    placeHolder &&
    css`
      font-style: italic;
      overflow: visible !important;
    `}
`;

export const Placeholder = styled.div`
  width: 40px;
  height: 40px;
  margin-left: 50px;
  background-size: 21px 21px;
  animation: rotating 2s linear infinite;
  background-color: ${({ theme }) => theme.colors.iconButtonBorder};
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 50%;

  ${({ icon }) =>
    icon &&
    css`
      background-image: url(${icon});
    `}
`;

export const ButtonPanel = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
`;

export const Button = styled.img`
  width: 24px;
  height: 24px;
  padding: 5px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.black_5};
  cursor: pointer !important;
  pointer-events: all;

  &:hover,
  &:focus {
    transition:
      background-color 0.2s ease-in-out,
      transform 0.2s ease-in-out;
    background-color: ${({ theme }) => theme.colors.black_8};
    transform: scale(1.1);
  }
`;

export const EditButton = styled(Button)``;

export const DeleteButton = styled(Button)`
  margin-left: 5px;
`;
