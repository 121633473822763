import React from 'react';
import PropTypes from 'prop-types';

import {
  ActivityEditorContainer,
  ActivityEditorSection,
  ActivityEditorSectionHeader,
  EditorHeaderRow,
  EditorHeaderRowLeft,
  EditorHeaderRowRight,
  QuestionHeaderRow,
  QuestionNumber,
  QuestionBody,
  ActivityEditor,
  ActivityEditorArea,
  ButtonWrapper,
  Editor,
  PreviewText,
} from './styles';

const NodeActivityEditor = ({
  isQuestion = false,
  questionIndex = 0,
  headerPanel,
  headerRightPanel,
  headerText,
  editorPanel,
  editorText,
  sneakPeakPanel,
  buttonPanel,
}) => {
  const HeaderRow = isQuestion ? QuestionHeaderRow : EditorHeaderRow;
  const questionHeaderPanel = (
    <>
      <EditorHeaderRowLeft isQuestion={true}>
        <QuestionNumber>{questionIndex + 1}</QuestionNumber>
        {headerText ? (
          <QuestionBody dangerouslySetInnerHTML={{ __html: headerText || '' }} />
        ) : (
          <QuestionBody>{headerPanel}</QuestionBody>
        )}
      </EditorHeaderRowLeft>
      <EditorHeaderRowRight isQuestion={true}>{headerRightPanel}</EditorHeaderRowRight>
    </>
  );

  return (
    <ActivityEditorContainer>
      <ActivityEditorSection>
        <ActivityEditorSectionHeader>
          <HeaderRow>{isQuestion ? questionHeaderPanel : headerPanel}</HeaderRow>
        </ActivityEditorSectionHeader>
        <ActivityEditor>
          <ActivityEditorArea>
            {editorPanel ? (
              editorPanel
            ) : (
              <Editor>
                <PreviewText dangerouslySetInnerHTML={{ __html: editorText || '' }} />
              </Editor>
            )}
          </ActivityEditorArea>
          {sneakPeakPanel}
        </ActivityEditor>
      </ActivityEditorSection>
      {buttonPanel && <ButtonWrapper>{buttonPanel}</ButtonWrapper>}
    </ActivityEditorContainer>
  );
};

export default NodeActivityEditor;

NodeActivityEditor.propTypes = {
  isQuestion: PropTypes.bool,
  questionIndex: PropTypes.number,
  headerPanel: PropTypes.object,
  headerRightPanel: PropTypes.object,
  editorPanel: PropTypes.object,
  headerText: PropTypes.string,
  editorText: PropTypes.string,
  sneakPeakPanel: PropTypes.object,
  buttonPanel: PropTypes.object,
};
