import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  .quill {
    .ql-toolbar.ql-snow {
      height: 44px;
      display: flex;
      margin-left: -42px;
      margin-right: -42px;
      padding: 8px 42px;
      border-bottom: 2px solid ${({ theme }) => theme.colors.buttonGreenFront};
      border-top: 2px solid ${({ theme }) => theme.colors.buttonGreenFront};
      background: ${({ theme }) => theme.colors.ghostWhite};
      position: sticky;
      top: 66px;
      z-index: 1;
      margin-bottom: 0;
      margin-top: -41px;
      opacity: 0;
      transition:
        margin-top 0.2s ease-in-out,
        margin-bottom 0.2s ease-in-out,
        opacity 0.2s ease-in-out;
      pointer-events: none;

      ${({ showToolbar }) =>
        showToolbar &&
        css`
          margin-top: 0;
          opacity: 1;
          transition:
            margin-top 0.2s ease-in-out,
            margin-bottom 0.2s ease-in-out,
            opacity 0.2s ease-in-out 0.1s;
          pointer-events: all;
        `}

      ${({ canToggleToolbar }) =>
        canToggleToolbar &&
        css`
          margin-left: 0;
          margin-right: -10px;
          border: none;
          padding: 8px;
          border-bottom: 1px solid ${({ theme }) => theme.colors.behaviorGreen};
        `}

      .ql-attachment:not(:hover),
      .ql-audio:not(:hover),
      .ql-dropbox:not(:hover),
      .ql-gdrive:not(:hover),
      .ql-onedrive:not(:hover),
      .ql-video:not(:hover) {
        opacity: 0.9;
      }

      .ql-textsize .ql-picker-label.ql-active .ql-fill {
        fill: ${({ theme }) => theme.colors.black};
      }

      .ql-textsize .ql-picker-label:hover .ql-fill {
        fill: ${({ theme }) => theme.colors.toolbarButtonBlue};
      }

      &.ql-snow .ql-textsize.ql-icon-picker .ql-picker-item {
        width: auto;
        height: auto;
      }

      .ql-size-small {
        font-size: 0.75em;
      }

      .ql-size-normal {
        font-size: 1em;
      }

      .ql-size-large {
        font-size: 1.5em;
      }

      .ql-size-huge {
        font-size: 2.5em;
      }

      .ql-attachment .ql-stroke {
        stroke-width: 3px;
      }

      .ql-attachment svg {
        float: none;
      }

      button svg:not(:root) {
        overflow: visible;
      }
    }

    &.question .ql-blank::before {
      left: 0;
    }

    .ql-container {
      border: none;
      margin-right: 10px;

      .readability & {
        font-family: 'opendyslexic3regular';
      }

      &.ql-disabled {
        opacity: 0.7;
      }

      .lp--activityEditorProgress {
        position: relative;
        width: 100%;
        height: 275px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        box-shadow: none;
        border: none;
        padding: 0px 30px;
        background-color: ${({ theme }) => theme.colors.black_8};

        .lp--activityEditorProgressLabel {
          width: 100%;
          color: ${({ theme }) => theme.colors.white};
          text-align: center;
          margin: 0px 0px 20px 0px;
          font-size: 22px;
        }

        .lp--activityEditorProgressBar {
          height: 30px;
          border-radius: 15px;
          width: 0px;
          background-color: ${({ theme }) => theme.colors.behaviorGreen};
        }

        .media-processing__icon {
          background-image: url(../../../../public/assets/images/loaders/loader.gif);
          background-repeat: no-repeat;
          background-position: center center;
          background-size: contain;
          height: 50px;
          width: 50px;
          margin: 20px auto;
        }
      }

      #emoji-palette .bem {
        margin: 5px;
      }

      iframe {
        max-width: 100% !important;
        width: 100%;
      }

      .ql-tooltip {
        background-color: ${({ theme }) => theme.colors.black_7};
        border-radius: 25px;
        color: ${({ theme }) => theme.colors.white};
        width: 400px;
        left: -30px;

        &::before,
        a.ql-action::after,
        a.ql-action::after,
        a.ql-remove::before {
          content: none;
        }

        &.ql-editing::before {
          border-left: 6px solid ${({ theme }) => theme.colors.transparent};
          border-right: 6px solid ${({ theme }) => theme.colors.transparent};
          border-bottom: 6px solid ${({ theme }) => theme.colors.black_7};
          top: -5px;
          content: ' ';
          display: block;
          left: var(--arrowPosition);
          margin-left: -6px;
          position: absolute;
        }

        input[type='text'] {
          background: ${({ theme }) => theme.colors.transparent};
          border: none;
          color: ${({ theme }) => theme.colors.white};
          display: block;
          font-size: 13px;
          height: 100%;
          outline: none;
          padding: 10px 20px;
          width: 100%;

          .ql-preview {
            display: none;
          }
        }
      }

      &.ql-bubble .ql-tooltip {
        width: auto;
      }
    }
  }
`;
