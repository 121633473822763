import * as microsoftTeams from '@microsoft/teams-js';
let initialized = false;

export const teamsGetApi = () => {
  if (!initialized) {
    microsoftTeams.initialize();
    initialized = true;
  }

  return microsoftTeams;
};

export const teamsContextExec = (callback) => {
  const api = teamsGetApi();
  api.getContext(callback);
};

export const checkInTeams = () => {
  const microsoftTeamsLib = microsoftTeams || window['microsoftTeams'];

  if (!microsoftTeamsLib) {
    return false;
  }

  if (
    (window.parent === window.self && window.nativeInterface) ||
    window.navigator.userAgent.includes('Teams/') ||
    window.name === 'embedded-page-container' ||
    window.name === 'extension-tab-frame' ||
    window.self !== window.top
  ) {
    return true;
  }

  return false;
};

export const isInTeamsChannel = () => {
  return new Promise((resolve) => {
    if (checkInTeams()) {
      teamsContextExec((context) => {
        resolve(!!context.channelId);
      });
    } else {
      resolve(false);
    }
  });
};
