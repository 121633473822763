import React from 'react';
import FooterLink from './FooterLink';
import {
  BottomPanelWrapper,
  ContactPanel,
  ContactLinks,
  Container,
  LinkPanel,
  LinkColumn,
  LinkDelimiter,
} from './styles';

const BottomPanel = (props) => {
  const { translations, organisation, user, baseLocale, contact, company, isInFrame = false } = props;

  if (isInFrame) {
    return null;
  }

  const { footer, app } = translations;
  const isEnterprise = organisation && organisation.type === 'enterprize';
  const hasOrganizationEmail = organisation && organisation.contact_email;
  const isSwedish = baseLocale === 'sv-SE';
  let localizedHomePage = company.url;
  let localizedSupportPage = isEnterprise ? company.support_enterprise : company.support;

  if (baseLocale === 'nb-NO') {
    localizedHomePage += '/no';
    localizedSupportPage += isEnterprise ? '/index.php/en/knowledge-base-2' : '/index.php/nb/loops-stottesider';
  } else if (!isSwedish) {
    localizedHomePage += '/en';
    localizedSupportPage += isEnterprise ? '/index.php/en/knowledge-base-2' : '/index.php/en/loops-support-pages';
  }

  return (
    <BottomPanelWrapper>
      <Container>
        <LinkPanel>
          <LinkColumn>
            <FooterLink href={`/assets/documents/${app.terms_and_conditions_document}`} text={footer.terms} />
            <FooterLink href={`/assets/documents/${app.privacy_policy_document}`} text={footer.privacy} />
          </LinkColumn>
          <LinkColumn>
            <FooterLink
              href={`mailto:${contact.email}`}
              text={
                hasOrganizationEmail
                  ? `${footer.contact_organisation.replace('%s', organisation.name)}`
                  : footer.contact_us
              }
            />
          </LinkColumn>
          <LinkColumn>
            <FooterLink href={localizedSupportPage} text={footer.support} />
          </LinkColumn>
          <LinkColumn>
            <FooterLink
              href={'https://www.facebook.com/groups/loopslarare/?fref=ts'}
              text={footer.facebook}
              showLink={!isEnterprise && isSwedish}
            />
            <FooterLink
              href={user ? '/logout' : '/#login'}
              text={user ? footer.logout : footer.login}
              target={'_self'}
            />
          </LinkColumn>
        </LinkPanel>
      </Container>

      <Container>
        <ContactPanel>
          <ContactLinks>
            <FooterLink label={footer.switchboard} href={footer.phone_link} text={footer.phone_text} target={'_self'} />
            <LinkDelimiter>.</LinkDelimiter>
            <FooterLink href={localizedHomePage} text={footer.homepage} />
            <LinkDelimiter>.</LinkDelimiter>
          </ContactLinks>
        </ContactPanel>
      </Container>
    </BottomPanelWrapper>
  );
};

export default BottomPanel;
