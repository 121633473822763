import React, { useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { HexColorPicker, HexColorInput } from 'react-colorful';
import useClickOutside from '../../hooks/useClickOutside';
import useKeyPress from '../../hooks/useKeyPress';
import { Picker, Swatch, Popover, InputWrapper, Label } from './styles';

export const ColorPicker = ({ color, onChange, disabled, showInputField = true, ...rest }) => {
  const popover = useRef();
  const [isOpen, setIsOpen] = useState(false);
  const labelId = crypto.randomUUID();

  const close = useCallback(() => setIsOpen(false), []);
  useClickOutside(popover, close);

  useKeyPress('Escape', close);

  const openPicker = () => !disabled && setIsOpen(true);

  return (
    <Picker role="dialog" {...rest}>
      <Swatch
        className="swatch"
        color={color}
        tabIndex={0}
        onClick={openPicker}
        onKeyPress={openPicker}
        disabled={disabled}
      />
      {isOpen && (
        <Popover isOpen={isOpen} ref={popover} className="color-picker">
          <HexColorPicker color={color} onChange={onChange} />
          {showInputField && (
            <InputWrapper>
              <Label id={labelId}>{i18n.__('colorPickerPlaceholder')}:</Label>
              <HexColorInput aria-labelledby={labelId} color={color} onChange={onChange} prefixed />
            </InputWrapper>
          )}
        </Popover>
      )}
    </Picker>
  );
};

ColorPicker.propTypes = {
  color: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  showInputField: PropTypes.bool,
  disabled: PropTypes.bool,
  rest: PropTypes.object,
};

export default ColorPicker;
