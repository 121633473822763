import styled, { css } from 'styled-components';

export const Picker = styled.div`
  position: relative;
`;

export const Swatch = styled.div`
  width: 28px;
  height: 28px;
  border-radius: 8px;
  border: 2px solid ${({ theme }) => theme.colors.headerGrey};
  box-shadow: 0 0 0 1px {({ theme }) => theme.colors.black_1}, inset 0 0 0 1px {({ theme }) => theme.colors.black_1};
  cursor: pointer;
  pointer-events: all;
  background-color: ${({ color }) => color};
  transition: border-color 0.2s ease;

  &:hover,
  &:focus {
    border-color: {({ theme }) => theme.colors.white};
    transition: border-color 0.2s ease;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: default;
      pointer-events: none;
      filter: grayscale(1);
    `}
`;

export const Popover = styled.div`
  position: absolute;
  top: calc(100% + 2px);
  left: 0;
  border-radius: 9px;
  box-shadow: 0 6px 12px {({ theme }) => theme.colors.black_1};

  .react-colorful {
    cursor: pointer;
  }

  input {
    width: 70px;
    color: ${({ theme }) => theme.colors.black};
  }

  z-index: ${({ isOpen }) => (isOpen ? 2 : 0)};
`;

export const InputWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.standardBlackBackground};
  display: flex;
  align-items: center;
  justify-content: end;
`;

export const Label = styled.div`
  margin-right: 5px;
`;
