import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import PurpleGuide from '../PurpleGuide';
import PrimaryButton from '../PrimaryButton';
import IconButton from '../IconButton';
import TrashIcon from '../../icons/green-trash.svg';
import useLocalStorage from '../../hooks/useLocalStorage';
import useApi from '../../hooks/useApi';
import defaultHubImage from '../../../public/assets/images/theme-2/placeholders/default-hub-image.jpg';
import PasteIcon from '../../../public/assets/images/icons/paste.svg';
import {
  ClipboardTab,
  ClipboardWrapper,
  ContentWrapper,
  ClipboardItem,
  ItemImage,
  ItemName,
  NodeImage,
  BottomPanel,
  NoItems,
  ColumnWrapper,
  Wrapper,
  ButtonWrapper,
  Header,
  SelectHub,
  AvailableHubsWrapper,
  PasteImage,
} from './styles';

const Clipboard = ({
  userId,
  userToken,
  flash,
  fileService,
  useApiHook = useApi,
  initSnapToGrid = false,
  initGridSize = 30,
}) => {
  const [forceOpen, setForceOpen] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [gridSize, setGridSize] = useState(initGridSize);
  const [snapToGrid, setSnapToGrid] = useState(initSnapToGrid);
  const [selectedNode, setSelectedNode] = useState();
  const [availableHubs, setAvailableHubs] = useState([]);
  const clipboardKey = `clipboard_${userId}`;
  const [items, setItems] = useLocalStorage(clipboardKey, []);
  const { postData } = useApiHook(userToken);

  const clipboardTab = ({ children, role, onClick }) => (
    <ClipboardTab title={i18n.__('app_clipboard')} role={role} onClick={onClick}>
      {children}
    </ClipboardTab>
  );

  const getItems = () => {
    const value = window.localStorage.getItem(clipboardKey);
    return value ? JSON.parse(value) : [];
  };

  const copyToClipboard = (event) => {
    const newItem = event.detail;
    if (newItem && !items.find((item) => newItem.uuid === item.uuid)) {
      setItems([...items, newItem]);
      setForceOpen(true);
    }
  };

  const updateNode = (event) => {
    const data = event.detail;
    const newItems = !data
      ? items
      : getItems().map((item) => {
          if (data.id === item.uuid) {
            const imageName = data.image ? data.image.split('/').reverse()[0] : '';
            const type = data.type ? data.type : '';
            return { ...item, name: data.name, ...(type ? { type } : {}), ...(imageName ? { imageName } : {}) };
          }
          return item;
        });
    setItems(newItems);
  };

  const removeNode = (event) => {
    const nodeId = event.detail;
    if (nodeId) {
      const newItems = getItems().filter((item) => nodeId !== item.uuid);
      setItems(newItems);
      selectNode();
    }
  };

  const panelOpened = () => {
    setDisabled(false);
  };

  const updateGridSize = (ev) => {
    setGridSize(ev.detail);
  };

  const updateSnapToGrid = (ev) => {
    setSnapToGrid(ev.detail);
  };

  const selectNode = (id) => {
    setSelectedNode(id);
    setAvailableHubs(getFreeHubsInLoop());
  };

  const handleError = (response) => {
    console.error(response);
    if (response.message === 'auth_failed') {
      flash(i18n.__('app_error_unauthorized'), { ttl: 5000 });
    } else if (response.message === 'edit_conflict') {
      flash(i18n.__('error_edit_conflict_behavior'), { ttl: 5000 });
    } else if (response.message === 'payload_too_large') {
      flash(i18n.__('error_payload_too_large'), { ttl: 20000 });
    } else {
      flash(i18n.__('error_behavior_save_failed'));
    }
  };

  const pasteToLoop = async (nodeId, hubId) => {
    setDisabled(true);
    const loopId = window.editor.options.circle_uuid;
    const payload = {
      loopId,
      nodeId,
      hubId,
    };
    if (snapToGrid) {
      payload.gridSize = gridSize;
    }
    const response = await postData(`/api/v3/editor/circles/${loopId}/paste`, payload);
    if (response.success) {
      selectNode();
      document.dispatchEvent(new CustomEvent('pasteGraphNode', { detail: { nodes: response.body, hubId } }));
    } else {
      setDisabled(false);
      handleError(response);
    }
  };

  useEffect(() => {
    document.addEventListener('copyToClipboard', copyToClipboard);
    return () => {
      document.removeEventListener('copyToClipboard', copyToClipboard);
    };
  });

  useEffect(() => {
    document.addEventListener('updateNode', updateNode);
    return () => {
      document.removeEventListener('updateNode', updateNode);
    };
  }, []);

  useEffect(() => {
    document.addEventListener('removeNode', removeNode);
    return () => {
      document.removeEventListener('removeNode', removeNode);
    };
  }, []);

  useEffect(() => {
    document.addEventListener('didShow.panel', panelOpened);
    return () => {
      document.removeEventListener('didShow.panel', panelOpened);
    };
  }, []);

  useEffect(() => {
    document.addEventListener('gridSize', updateGridSize);
    return () => {
      document.removeEventListener('gridSize', updateGridSize);
    };
  }, []);

  useEffect(() => {
    document.addEventListener('snapToGrid', updateSnapToGrid);
    return () => {
      document.removeEventListener('snapToGrid', updateSnapToGrid);
    };
  }, []);

  useEffect(() => {
    setAvailableHubs(getFreeHubsInLoop());
  }, []);

  const texts = {
    tabheader: i18n.__('app_clipboard'),
    header: i18n.__('app_clipboard'),
    subheader: i18n.__('app_clipboard_contents') + ':',
    footer: '',
  };

  const dateSorter = (a, b) => a.copiedAt - b.copiedAt;
  const nameSorter = (a, b) => (a.name || '').localeCompare(b.name || '');

  const clearAll = () => {
    setItems([]);
  };

  const removeItem = (nodeId) => {
    setItems(items.filter((item) => item.uuid !== nodeId));
  };

  const onClose = () => {
    setForceOpen(false);
  };

  const removeButton = (uuid) => (
    <ButtonWrapper>
      <IconButton
        icon={TrashIcon}
        onClick={() => removeItem(uuid)}
        title={i18n.__('app_remove')}
        aria-label={i18n.__('app_remove')}
      />
    </ButtonWrapper>
  );

  const getFreeHubsInLoop = () => {
    const graph = window.editor.graph.cy;
    return !graph
      ? []
      : graph
          .nodes('node[!group][initialized]')
          .filter((hub) => hub.neighbourhood('[placeholder]').length > 0)
          .map((hub) => hub.data());
  };

  const getAvailableHubsForNode = (nodeId) =>
    availableHubs.sort(nameSorter).map((hub) => {
      const { id, uuid, name, image } = hub;
      const hubId = id || uuid;
      const paste = () => pasteToLoop(nodeId, hubId);
      return (
        <ClipboardItem
          key={`${nodeId}_${hubId}`}
          title={i18n.__('app_paste_to_hub')}
          tabIndex={0}
          type="availableHub"
          onClick={paste}
          onKeyPress={paste}
        >
          <ItemImage icon={image ? image : defaultHubImage} />
          <ItemName>{name}</ItemName>
          <PasteImage icon={PasteIcon} />
        </ClipboardItem>
      );
    });

  const hubs = items
    .filter((item) => !item.type)
    .sort(dateSorter)
    .map((item) => {
      const { id, uuid, name, imageName } = item;
      const hubId = id || uuid;
      const paste = () => !disabled && pasteToLoop(hubId);
      return (
        <Wrapper key={hubId}>
          <ClipboardItem
            type="hub"
            title={i18n.__('app_paste_to_loop')}
            tabIndex={0}
            onClick={paste}
            onKeyPress={paste}
          >
            <ItemImage icon={imageName ? `${fileService.url}100x100/${imageName}` : defaultHubImage} />
            <ItemName>{name}</ItemName>
            <PasteImage icon={PasteIcon} />
          </ClipboardItem>
          {removeButton(hubId)}
        </Wrapper>
      );
    });

  const nodes = items
    .filter((item) => item.type)
    .sort(dateSorter)
    .map((item) => {
      const { id, uuid, name, type } = item;
      const nodeId = id || uuid;
      const nodeIcon = `/assets/images/theme-2/icons/${type}.svg`;
      const show = selectedNode === nodeId;
      const toggle = () => (show ? selectNode() : selectNode(nodeId));
      return (
        <ColumnWrapper key={nodeId}>
          <Wrapper>
            <ClipboardItem type={type} show={show} tabIndex={0} onClick={toggle} onKeyPress={toggle}>
              <NodeImage icon={nodeIcon} className={type} />
              <ItemName type={type} show={show}>
                {name}
              </ItemName>
            </ClipboardItem>
            {removeButton(nodeId)}
          </Wrapper>
          {show && (
            <AvailableHubsWrapper>
              <SelectHub>
                {availableHubs.length > 0 ? `${i18n.__('app_select_target_hub')}:` : i18n.__('app_no_target_hub')}
              </SelectHub>
              {availableHubs.length > 0 && getAvailableHubsForNode(nodeId)}
            </AvailableHubsWrapper>
          )}
        </ColumnWrapper>
      );
    });

  const content = (
    <ContentWrapper>
      {hubs.length > 0 && <Header>{i18n.__('app_hubs')}</Header>}
      {hubs}
      {nodes.length > 0 && <Header>{i18n.__('app_nodes')}</Header>}
      {nodes}
      {items.length === 0 ? (
        <NoItems>{i18n.__('app_clipboard_empty')} </NoItems>
      ) : (
        <BottomPanel>
          <PrimaryButton text={i18n.__('app_clear_all')} onClick={clearAll} />
        </BottomPanel>
      )}
    </ContentWrapper>
  );

  return (
    <PurpleGuide
      id="clipboard"
      userId={userId}
      forceOpen={forceOpen}
      saveInLocalStorage={false}
      TabComponent={clipboardTab}
      WrapperComponent={ClipboardWrapper}
      openClassName="clipboard-open"
      texts={texts}
      content={content}
      onClose={onClose}
    />
  );
};

Clipboard.propTypes = {
  userId: PropTypes.string.isRequired,
  userToken: PropTypes.string.isRequired,
  flash: PropTypes.func.isRequired,
  fileService: PropTypes.object.isRequired,
  useApiHook: PropTypes.func,
  initSnapToGrid: PropTypes.bool,
  initGridSize: PropTypes.number,
};

export default Clipboard;
