import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Wrapper,
  PopupClose,
  RecorderWrapper,
  OuterScreenWrapper,
  ScreenWrapper,
  RecorderFlexCenterWrapper,
  RecorderFlexBottomWrapper,
  RecorderIndicator,
  RecorderIndicatorLED,
  RecorderToolbar,
  RecorderDuration,
  RecorderWaitIndicator,
  RecorderToolbarItem,
  RecorderWaitMessage,
  MediaProcessing,
  MediaProcessingIcon,
  MediaProcessingText,
  RecorderToolbarStop,
} from './styles';

const Recorder = ({
  appId,
  userId,
  onClose,
  onRecordingPublished,
  onRecordingProcessed,
  AttachmentSource,
  type,
  recorderId = 'recorder',
  minLength = type === 'video' ? '3' : '1',
  maxLength = '600',
  preRollLength = '3',
  autoPreview = 'false',
  ...rest
}) => {
  useEffect(() => {
    const cameraTag = window.CameraTag;
    if (cameraTag) {
      cameraTag.observe(recorderId, 'published', (recording) => {
        let file = {
          filename: recording.uuid,
          uuid: recording.uuid,
          state: 'published',
        };
        if (type === 'video') {
          file = {
            ...file,
            url: recording.medias.qvga_mp4,
            thumb: recording.medias.qvga_thumb,
            mime_type: 'video/mp4',
            source: AttachmentSource.Webcam,
          };
        } else {
          file = {
            ...file,
            url: recording.medias.mp3,
            thumb: recording.medias.negative_waveform,
            mime_type: 'audio/mp3',
            source: AttachmentSource.Microphone,
          };
        }
        onRecordingPublished(file);
      });

      cameraTag.observe(recorderId, 'processed', (recording) => {
        onRecordingProcessed && onRecordingProcessed(recording);
      });

      cameraTag.setup();
    }
    return () => {
      const recorder = getRecorder();
      if (recorder) {
        recorder.destroy();
      }
    };
  }, []);

  const getRecorder = () => window.CameraTag.cameras[recorderId] || window.CameraTag.microphones[recorderId];

  const publish = () => {
    const recorder = getRecorder();
    if (recorder) {
      getRecorder().publish();
    }
  };

  const CameraTag = type === 'video' ? 'camera' : 'microphone';

  return (
    <Wrapper className="recorder">
      <PopupClose onClick={onClose} role="button" />
      <RecorderWrapper>
        <CameraTag
          is="div"
          id={recorderId}
          data-uuid={appId}
          data-app-id={appId}
          data-metadata={JSON.stringify({ userUUID: userId })}
          data-minlength={minLength}
          data-maxlength={maxLength}
          data-pre-roll-length={preRollLength}
          data-autopreview={autoPreview}
          data-sources="record"
          {...rest}
        />

        <OuterScreenWrapper id="camera-tag-custom-components">
          <ScreenWrapper id={`${recorderId}-start-screen`}>
            <RecorderFlexCenterWrapper>
              <div tabIndex="0" className="cameratag_record">
                {i18n.__('cameratag.click_to_start')}
              </div>
            </RecorderFlexCenterWrapper>
          </ScreenWrapper>

          <ScreenWrapper id={`${recorderId}-countdown-screen`}>
            <RecorderFlexCenterWrapper>
              <div className="cameratag_countdown_status"></div>
            </RecorderFlexCenterWrapper>
          </ScreenWrapper>

          <ScreenWrapper id={`${recorderId}-recording-screen`} className="transparent">
            <RecorderFlexBottomWrapper>
              <RecorderIndicator>
                <RecorderIndicatorLED></RecorderIndicatorLED>
              </RecorderIndicator>
              <RecorderToolbar>
                <RecorderDuration className="cameratag_record_timer_prompt">00:00</RecorderDuration>
                <RecorderToolbarStop className="cameratag_stop_recording">
                  {i18n.__('cameratag.stop')}
                </RecorderToolbarStop>
              </RecorderToolbar>
            </RecorderFlexBottomWrapper>
          </ScreenWrapper>

          <ScreenWrapper id={`${recorderId}-playback-screen`} className="transparent">
            <RecorderFlexBottomWrapper>
              <RecorderToolbar>
                <RecorderToolbarItem className="cameratag_stop_playback">
                  {i18n.__('cameratag.stop')}
                </RecorderToolbarItem>
              </RecorderToolbar>
            </RecorderFlexBottomWrapper>
          </ScreenWrapper>

          <ScreenWrapper id={`${recorderId}-error-screen`}>
            <RecorderFlexCenterWrapper>
              <RecorderWaitIndicator></RecorderWaitIndicator>
              <RecorderWaitMessage>{i18n.__('cameratag.hardware_error')}</RecorderWaitMessage>
            </RecorderFlexCenterWrapper>
          </ScreenWrapper>

          <ScreenWrapper id={`${recorderId}-accept-screen`} className="transparent">
            <RecorderFlexBottomWrapper>
              <RecorderToolbar>
                <RecorderToolbarItem className="cameratag_play">{i18n.__('cameratag.replay')}</RecorderToolbarItem>
                <RecorderToolbarItem className="cameratag_record">{i18n.__('cameratag.new')}</RecorderToolbarItem>
                <RecorderToolbarItem onClick={publish}>{i18n.__('cameratag.use')}</RecorderToolbarItem>
              </RecorderToolbar>
            </RecorderFlexBottomWrapper>
          </ScreenWrapper>

          <ScreenWrapper id={`${recorderId}-wait-screen`}>
            <RecorderFlexCenterWrapper>
              <RecorderWaitIndicator></RecorderWaitIndicator>
              <RecorderWaitMessage>{i18n.__('cameratag.a_moment')}</RecorderWaitMessage>
            </RecorderFlexCenterWrapper>
          </ScreenWrapper>

          <ScreenWrapper id={`${recorderId}-upload-screen`}>
            <RecorderFlexCenterWrapper>
              <RecorderWaitIndicator></RecorderWaitIndicator>
              <RecorderWaitMessage className="cameratag_upload_status">
                {i18n.__('cameratag.a_moment')}
              </RecorderWaitMessage>
            </RecorderFlexCenterWrapper>
          </ScreenWrapper>

          <ScreenWrapper id={`${recorderId}-completed-screen`}></ScreenWrapper>
          <ScreenWrapper id={`${recorderId}-processing-screen`}>
            <MediaProcessing>
              <MediaProcessingIcon></MediaProcessingIcon>
              <MediaProcessingText>{i18n.__('cameratag.video_media_processing_message')}</MediaProcessingText>
            </MediaProcessing>
          </ScreenWrapper>
        </OuterScreenWrapper>
      </RecorderWrapper>
    </Wrapper>
  );
};

Recorder.propTypes = {
  appId: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onRecordingPublished: PropTypes.func.isRequired,
  onRecordingProcessed: PropTypes.func,
  recorderId: PropTypes.string,
  minLength: PropTypes.number,
  maxLength: PropTypes.number,
  preRollLength: PropTypes.number,
  autoPreview: PropTypes.bool,
  rest: PropTypes.object,
};

export default Recorder;
