import React, { useState } from 'react';

export const GlobalContext = new React.createContext();

const GlobalProvider = ({ children }) => {
  const [flashMessage, setFlashMessage] = useState({});

  const showFlashError = (messageData) => {
    showFlashMessage(messageData, true);
  };

  const showFlashMessage = (messageData, error = false) => {
    if (typeof messageData === 'string') {
      setFlashMessage({
        show: true,
        message: messageData,
        duration: 3000,
        error,
      });
    } else {
      const { show = true, message = '', duration = 3000 } = messageData || {};
      setFlashMessage({ show, message, duration, error });
    }
  };

  const context = {
    showFlashMessage,
    showFlashError,
    flashMessage,
  };

  return <GlobalContext.Provider value={context}>{children}</GlobalContext.Provider>;
};

export default GlobalProvider;
