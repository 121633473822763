import PlaceholderAvatarImage from '../../../public/assets/images/theme-2/placeholders/user_avatar.jpg';
import { parseImageObjectOrString } from '../../utils';

const srcSelector = (fileService, image, width, height) => {
  if (fileService) {
    return fileService.buildImageUrl(image, width, height, PlaceholderAvatarImage);
  } else {
    const { imageUrl, placeholder } = parseImageObjectOrString(image, PlaceholderAvatarImage);
    return imageUrl || placeholder;
  }
};

export default srcSelector;
