import React from 'react';
import PropTypes from 'prop-types';
import ContentEditor from '../../ContentEditor';
import NodeActivityEditor from '../../NodeActivityEditor';
import RemoveIcon from '../../../../public/assets/images/editor/delete-icon.svg';
import MoveUpIcon from '../../../icons/move-up-black.svg';
import UploadImageIcon from '../../../icons/upload-image.svg';
import {
  ContentSection,
  Questions,
  QuestionWrapper,
  Question,
  QuestionTools,
  DeleteQuestion,
  MoveUpQuestion,
  MoveDownQuestion,
  InsertImage,
} from '../styles';

const WrittenQuizContentEditor = ({
  children,
  questions,
  setQuestions,
  deletions,
  setDeletions,
  type,
  panelType,
  fileService,
  loading,
}) => {
  const onChange = (value, index) => {
    const newQuestions = [...questions];
    newQuestions[index] = { ...questions[index], body: value };
    setQuestions(newQuestions);
  };

  const removeQuestion = (questionId) => {
    const newDeletions = [...deletions];
    newDeletions.push(questionId);
    setDeletions(newDeletions);
    const newQuestions = questions
      .filter((question) => question.id !== questionId)
      .map((question, index) => ({ ...question, order: index }));
    setQuestions(newQuestions);
  };

  const orderQuestion = (index, delta) => {
    if ((index === 0 && delta === -1) || (index === questions.length - 1 && delta === 1)) {
      return;
    }
    const newQuestions = [...questions];
    newQuestions[index].order = index + delta;
    newQuestions[index + delta].order = index;
    setQuestions(newQuestions);
  };

  const uploadImage = (questionId) => {
    const imageButton = document.querySelector(`#_${questionId} .ql-image`);
    imageButton.click();
  };

  const getActivityEditorForQuestion = (question, index) => {
    const headerPanel = (
      <ContentEditor
        id={`_${question.id || index}`}
        type={type}
        panelType={panelType}
        aria-label={i18n.__('placeholder_description')}
        placeholder={i18n.__('placeholder_description')}
        value={question.body || ''}
        onChange={(value) => {
          !loading && onChange(value, index);
        }}
        fileService={fileService}
        canToggleToolbar={false}
        showToolbar={true}
        theme="bubble"
        toolbar={[['bold', 'italic', 'underline'], ['image']]}
        className="question"
      />
    );

    return (
      <NodeActivityEditor
        isQuestion={true}
        questionIndex={index}
        headerPanel={headerPanel}
        editorText={i18n.__('written_quiz_student_input_placeholder')}
      />
    );
  };

  const mappedQuestions = questions.map((question, index) => (
    <QuestionWrapper key={index}>
      <Question>{getActivityEditorForQuestion(question, index)}</Question>
      <QuestionTools>
        <InsertImage src={UploadImageIcon} onClick={() => uploadImage(question.id)} />
        <DeleteQuestion src={RemoveIcon} onClick={() => removeQuestion(question.id)} />
        <MoveUpQuestion src={MoveUpIcon} onClick={() => orderQuestion(index, -1)} />
        <MoveDownQuestion src={MoveUpIcon} onClick={() => orderQuestion(index, 1)} />
      </QuestionTools>
    </QuestionWrapper>
  ));

  return (
    <ContentSection>
      {children}
      <Questions>{mappedQuestions}</Questions>
    </ContentSection>
  );
};

export default WrittenQuizContentEditor;

WrittenQuizContentEditor.propTypes = {
  children: PropTypes.object.isRequired,
  questions: PropTypes.array.isRequired,
  setQuestions: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  panelType: PropTypes.number.isRequired,
  fileService: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
};
