import React from 'react';
import PropTypes from 'prop-types';
import NodeActivityEditor from '../../NodeActivityEditor';
import SecondaryButton from '../../SecondaryButton';
import { PreviewSection, Questions, Question, SubmitPanel } from '../styles';

const WrittenQuizContentPreview = ({ previewText, data }) => {
  const getPreviewForQuestion = (question, index) => {
    return (
      <NodeActivityEditor
        isQuestion={true}
        questionIndex={index}
        headerText={question.body}
        editorText={index === 0 ? previewText : ''}
      />
    );
  };

  const questions = data.QuizQuestions.map((question, index) => (
    <Question key={question.id}>{getPreviewForQuestion(question, index)}</Question>
  ));

  return (
    <>
      <PreviewSection>
        <Questions>{questions}</Questions>
      </PreviewSection>
      <SubmitPanel>
        <SecondaryButton text={i18n.__('app_handin')} disabled={true} />
      </SubmitPanel>
    </>
  );
};

export default WrittenQuizContentPreview;

WrittenQuizContentPreview.propTypes = {
  previewText: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
};
