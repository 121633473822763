import * as microsoftTeams from '@microsoft/teams-js';
import AuthenticationContext from 'adal-angular';
import Utils from '../utils';

const GRAPH_API_BASE_URL = 'https://graph.microsoft.com';
const MICROSOFT_LOGIN_BASE_URL = 'https://login.microsoftonline.com';

class MS {
  constructor(opts = {}) {
    this.opts = opts;

    this.adalBaseConfig = {
      clientId: opts.azureClientId,
      redirectUri: `${this.opts.appUrl}/integrations/microsoft/teams/silent`,
      cacheLocation: 'localStorage',
      navigateToLoginRequestUrl: false,
    };

    microsoftTeams.initialize();
  }

  async adalLogin(options = {}) {
    return new Promise((resolve, reject) => {
      try {
        const authContext = new AuthenticationContext({
          ...this.adalBaseConfig,
          popUp: true,
          callback: async (errorDesc, token, error, tokenType) => {
            try {
              if (token) {
                if (tokenType !== authContext.CONSTANTS.ID_TOKEN) {
                  token = authContext.getCachedToken(this.opts.azureClientId);
                }
                const user = authContext.getCachedUser();
                const tenantId = user && user.profile ? user.profile.tid : null;

                resolve({
                  tenantId,
                  token,
                  user,
                });
              } else {
                throw error;
              }
            } catch (error) {
              reject(error);
            }
          },
          ...options,
        });

        authContext.clearCache();
        authContext.login();
      } catch (error) {
        reject(error);
      }
    });
  }

  getContext() {
    return new Promise((resolve, reject) => {
      microsoftTeams.getContext(async (context) => {
        resolve(context);
      });
    });
  }

  clearCache() {
    let authContext = new AuthenticationContext(this.adalBaseConfig);
    authContext.clearCache();
  }

  teamsSilentLogin() {
    return new Promise((resolve, reject) => {
      microsoftTeams.getContext(async (context) => {
        const { upn: userPrincipalName, tid: tenantId } = context;

        let adalConfig = {
          ...this.adalBaseConfig,
          extraQueryParameter: 'scope=openid+email+profile',
        };
        if (userPrincipalName) {
          adalConfig.extraQueryParameter += '&login_hint=' + encodeURIComponent(userPrincipalName);
        }

        let authContext = new AuthenticationContext(adalConfig);
        let user = authContext.getCachedUser();
        if (user) {
          if (user.profile && user.profile.upn !== userPrincipalName) {
            // user doesnt match upn, clear cache
            authContext.clearCache();
          }
        }

        authContext.acquireToken(this.opts.azureClientId, async (errDesc, token, err, tokenType) => {
          if (token) {
            // make sure token is id token
            if (tokenType !== authContext.CONSTANTS.ID_TOKEN) {
              token = authContext.getCachedToken(this.opts.azureClientId);
            }

            resolve({ token, tenantId });
          } else {
            resolve(false);
          }
        });
      });
    });
  }

  // popup: callback for silent login
  teamsSilentCallback() {
    let authContext = new AuthenticationContext(this.adalBaseConfig);

    if (authContext.isCallback(window.location.hash)) {
      authContext.handleWindowCallback(window.location.hash);
      // popup? notify
      if (window.opener) {
        if (authContext.getCachedUser()) {
          microsoftTeams.authentication.notifySuccess();
        } else {
          microsoftTeams.authentication.notifyFailure(authContext.getLoginError());
        }
      }
    }
  }

  teamsLogin() {
    return new Promise((resolve, reject) => {
      microsoftTeams.authentication.authenticate({
        url: `${this.opts.appUrl}/integrations/microsoft/teams/initialize`,
        width: 600,
        height: 535,
        successCallback: async (result) => {
          microsoftTeams.getContext(async (context) => {
            resolve({
              token: result.idToken,
              tenantId: context.tid,
              accessToken: result.accessToken,
            });
          });
        },
        failureCallback: async (reason) => {
          reject(reason);
        },
      });
    });
  }

  // popup: initiate login
  teamsLoginInitialize(state, nonce, additional_scopes = '') {
    let queryParams = {
      client_id: this.opts.azureClientId,
      response_type: 'id_token token',
      response_mode: 'fragment',
      redirect_uri: `${this.opts.appUrl}/integrations/microsoft/teams/callback`,
      state,
      nonce,
      scope: `${GRAPH_API_BASE_URL}/User.Read openid email profile` + additional_scopes,
    };
    localStorage.setItem('state', state);

    let url = `${MICROSOFT_LOGIN_BASE_URL}/common/oauth2/v2.0/authorize?${Utils.queryParamsFromObject(queryParams)}`;

    microsoftTeams.getContext((context) => {
      const urlWithLoginHint = `${url}&login_hint=${encodeURIComponent(context.upn)}`;
      window.location.assign(urlWithLoginHint);
    });
  }

  // popup: callback for login
  teamsLoginCallback() {
    const params = Utils.hashParams(location.hash.substring(1));

    if (params.error) {
      microsoftTeams.authentication.notifyFailure(params.error);
    } else if (params.access_token) {
      if (params.state !== localStorage.getItem('state')) {
        microsoftTeams.authentication.notifyFailure('StateDoesNotMatch');
      } else {
        microsoftTeams.authentication.notifySuccess({
          idToken: params.id_token,
          accessToken: params.access_token,
          tokenType: params.token_type,
          expiresIn: params.expires_in,
          expiresIn: params.expires_in,
        });
      }
    } else {
      microsoftTeams.authentication.notifyFailure('UnexpectedFailure');
    }
  }

  teamsPostLogin(token, tenantId) {
    return new Promise((resolve, reject) => {
      microsoftTeams.getContext(async (context) => {
        const subEntityId = context ? context.subEntityId : '';

        try {
          const response = await fetch(`/integrations/microsoft/login`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              token,
              tenantId,
              subEntityId: context.subEntityId,
            }),
          });
          const data = await response.json();
          resolve(data);
        } catch (error) {
          reject(error);
        }
      });
    });
  }

  adminConsentCallback(params) {
    if (window.opener && window.opener.azureTenantAdminConsentComplete) {
      window.opener.azureTenantAdminConsentComplete(params);
      window.close();
    }
  }
}

export default MS;
window['MS'] = MS;
