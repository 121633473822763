import React from 'react';
import PropTypes from 'prop-types';
import { RemoveButtonElement } from './styles';
import MinusIcon from '../../icons/green-minus.svg';

const RemoveButton = ({ icon, size = 22, onClick, ...rest }) => {
  const onClickHandler = (ev) => {
    ev.target.blur();
    onClick && onClick(ev);
  };

  return (
    <RemoveButtonElement
      tabIndex={0}
      icon={icon ? icon : MinusIcon}
      onClick={onClickHandler}
      onKeyPress={onClickHandler}
      role="button"
      title={i18n.__('app_remove')}
      aria-label={i18n.__('app_remove')}
      size={size}
      {...rest}
    />
  );
};

RemoveButton.propTypes = {
  onClick: PropTypes.func,
  icon: PropTypes.string,
  size: PropTypes.number,
  rest: PropTypes.object,
};

export default RemoveButton;
