import styled from 'styled-components';
import { PurpleGuideWrapper, PurpleGuideTab, TabText } from '../PurpleGuide/styles';

export const TrialPanelWrapper = styled(PurpleGuideWrapper)`
  top: 256px;
  height: calc(100% - 256px);
`;

export const TrialPanelTab = styled(PurpleGuideTab)`
  top: 256px;
  right: 0;
  width: 50px;
  height: 80px;
  text-align: center;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  flex-direction: column;
  border: 2px solid ${({ theme }) => theme.colors.headerGrey};
  border-right: none;

  ${TabText} {
    transform: translateX(-50%);
    text-align: center;
    margin-top: 5px;
  }
`;

export const TrialDays = styled.div`
  border: 2px solid ${({ theme }) => theme.colors.buttonGreenFront};
  color: ${({ trialEnd, theme }) => (trialEnd ? theme.colors.buttonGreenFront : theme.colors.standardTextGrey)};
  background-color: ${({ trialEnd, theme }) => (trialEnd ? theme.colors.white : theme.colors.transparent)};
  height: 32px;
  width: 32px;
  border-radius: 50%;
  font-family: ${({ theme }) => theme.fonts.roboto};
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
`;
