import React, { useState } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';
import { InputFieldWrapper, DropDownLabel } from './styles';

const AutoComplete = (props) => {
  const {
    label,
    id,
    items = [],
    noHitsMessage = i18n.__('app_no_matches'),
    placeholder = i18n.__('app_select_or_search'),
    onChange,
    maxWidth = 350,
    theme,
    ...rest
  } = props;

  const [value, setValue] = useState(null);
  const key = new Date();

  const dropDownLabel = label ? <DropDownLabel htmlFor={id}>{label}</DropDownLabel> : null;

  const onItemChange = (item) => {
    if (onChange) {
      onChange(item.value);
    }
    setValue(null);
  };

  const dropdownStyle = {
    container: (provided) => ({
      ...provided,
      width: 'auto',
      maxWidth,
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor:
        state.isSelected || state.isFocused
          ? `${state.selectProps.theme.colors.white_1}`
          : `${state.selectProps.theme.colors.standardBlackBackground}`,
      color: `${state.selectProps.theme.colors.white}`,
      padding: 10,
      cursor: 'pointer',
      borderBottom: `1px solid ${state.selectProps.theme.colors.grey}`,
    }),
    control: (provided, state) => ({
      ...provided,
      cursor: 'pointer',
      backgroundColor: `${state.selectProps.theme.colors.standardBlackBackground}`,
      color: `${state.selectProps.theme.colors.white}`,
      borderRadius: 0,
      border: 'none',
      boxShadow: 'none',
      borderBottom: `1px solid ${state.selectProps.theme.colors.grey}`,
      ':hover': {
        borderColor: `${state.selectProps.theme.colors.grey}`,
      },
      maxWidth,
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: `${state.selectProps.theme.colors.white}`,
    }),
    input: (provided) => ({
      ...provided,
      fontSize: '18px',
    }),
    valueContainer: (provided) => ({
      ...provided,
      cursor: 'text',
      padding: 0,
    }),
    menu: (provided, state) => ({
      ...provided,
      backgroundColor: `${state.selectProps.theme.colors.standardBlackBackground}`,
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      display: 'none',
    }),
    placeholder: (provided, state) => ({
      ...provided,
      color: `${state.selectProps.theme.colors.buttonGreenFront}`,
      fontSize: '18px',
      fontStyle: 'italic',
      fontFamily: `${state.selectProps.theme.fonts.robotoLight}`,
      top: '65%',
      margin: 0,
    }),
    noOptionsMessage: (provided, state) => ({
      ...provided,
      color: `${state.selectProps.theme.colors.white}`,
      backgroundColor: `${state.selectProps.theme.colors.blueGrey}`,
    }),
  };

  return (
    <InputFieldWrapper>
      {dropDownLabel}
      <Select
        title={label || placeholder}
        aria-label={label || placeholder}
        inputId={id}
        name={id}
        key={key}
        isSearchable={true}
        openMenuOnClick={false}
        value={value}
        theme={theme}
        options={items}
        noOptionsMessage={() => noHitsMessage}
        onChange={onItemChange}
        styles={dropdownStyle}
        placeholder={placeholder}
        components={{
          IndicatorSeparator: () => null,
        }}
        {...rest}
      />
    </InputFieldWrapper>
  );
};

AutoComplete.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string.isRequired,
  items: PropTypes.array,
  noHitsMessage: PropTypes.string,
  onChange: PropTypes.func,
  maxWidth: PropTypes.number,
  rest: PropTypes.object,
};

export default withTheme(AutoComplete);
