import key from 'keymaster';
import Utils from './utils';
import PurpleGuideRenderer, { ClipboardRenderer } from '../../js/shared/reactLoader';

export default class Panel {
  // By enforcing a non-parallell queue for all panel-animation operation
  // we can enforce that even spamming the button like crazy will, after
  // all animations still result in a deterministic state of panels in the
  // DOM.
  static getQueue() {
    if (!Panel.__queue) {
      Panel.__queue = new Queue(1);
    }
    return Panel.__queue;
  }

  static setup() {
    if (this.loaded) {
      return;
    }
    // Override point!
    this.loaded = true;
    let container = $('#panel-wrapper');
    if (container.length == 0) {
      $('body').append('<div id="panel-wrapper" role="dialog" aria-label="dialog"><div id="panel-mask"></div></div>');
    }
  }

  static typeClass(type) {
    switch (type) {
      case Panel.HUB:
        return 'hub-pane';
      case Panel.NODE:
        return 'node-pane';
      case Panel.CIRCLE:
        return 'circle-pane';
      case Panel.MODAL:
        return 'lp--modal';
      case Panel.COMS:
        return 'coms-pane';
    }
  }

  static isTeacher() {
    return document.body.getAttribute('data-user-is-teacher') === 'true';
  }

  static hasPanelDisplayed() {
    return Panel.currentPanel;
  }

  static getPurpleGuideNode(mode) {
    return $(`#purpleGuide${mode}Wrapper`)[0] || $(`<div id="purpleGuide${mode}Wrapper"></div>`).appendTo('body')[0];
  }

  static show(panel, options = {}) {
    this.setup();
    this.getQueue().add(() => {
      return Promise.resolve(true)
        .then(() => {
          if (this.subPanel) {
            return this.subPanel.hide(undefined, { inShow: true });
          } else {
            return Promise.resolve(true);
          }
        })
        .then(() => {
          if (this.modalPanel && panel.type == Panel.MODAL && !panel.opts.allowStacking) {
            return this.modalPanel.hide(undefined, { inShow: true });
          } else {
            return Promise.resolve(true);
          }
        })
        .then(() => {
          if ([Panel.COMS, Panel.MODAL].indexOf(panel.type) == -1 && this.currentPanel && options.noStack) {
            return this.currentPanel.hide(undefined, { inShow: true });
          } else {
            return Promise.resolve(true);
          }
        })
        .then(() => {
          return new Promise((resolve) => {
            if (panel.type == Panel.COMS) {
              this.currentPanel.subPanel = panel;
              panel.parentPanel = this.currentPanel;
            }

            var container = $('#panel-wrapper');
            let openAnimTriggered = false;

            if (!options.noStack && this.currentPanel && [Panel.COMS, Panel.MODAL].indexOf(panel.type) == -1) {
              this.currentPanel.content[0].style.zIndex = options.stackOnTop ? 2 : 11;
              let previousPanel = this.currentPanel;
              panel.eventSource.one('didShow.panel', function () {
                previousPanel.hide(undefined, { inShow: true });
              });
              if (!options.stackOnTop) {
                panel.content.addClass('open');
                panel.content.addClass('noAnimation');
                openAnimTriggered = true;
                panel.eventSource.trigger('didShow.panel');
                $(window).trigger('didShow.panel', panel);
                document.dispatchEvent(new CustomEvent('didShow.panel'));
                $(window).one('didHide.panel', function () {
                  panel.content.removeClass('noAnimation');
                });
                resolve();
              }
            }

            if (panel.type == Panel.COMS) {
              this.subPanel = panel;
            } else if (panel.type == Panel.MODAL) {
              this.modalPanel = panel;
            } else {
              this.currentPanel = panel;
            }

            if (location.pathname.startsWith('/editor') && [Panel.HUB, Panel.NODE, Panel.CIRCLE].includes(panel.type)) {
              let guideId = '';
              if (panel.type === Panel.CIRCLE) {
                guideId = PurpleGuideRenderer.isSwedishSchool() ? 'creatingALoopWithSubjectTagging' : 'creatingALoop';
              } else if (panel.type === Panel.HUB) {
                guideId = 'creatingAHub';
              } else if (panel.type === Panel.NODE) {
                guideId = 'addingContentsToANode';
              }

              if (guideId) {
                PurpleGuideRenderer.render(guideId, null, Panel.getPurpleGuideNode('Editor'));
              }
              ClipboardRenderer.render();
            }

            panel.eventSource.trigger('willAdd.panel', panel);

            panel.content.one(transition_event_name, function () {
              if (!openAnimTriggered) {
                openAnimTriggered = true;
                panel.eventSource.trigger('didShow.panel');
                $(window).trigger('didShow.panel', panel);
                document.dispatchEvent(new CustomEvent('didShow.panel'));
                resolve();
              }
            });

            container.prepend(panel.content);
            panel.eventSource.trigger('added.panel', panel);
            $(window).trigger('added.panel', panel);

            setTimeout(function () {
              panel.content.addClass('open');
              setTimeout(() => {
                if (!openAnimTriggered) {
                  openAnimTriggered = true;
                  panel.eventSource.trigger('didShow.panel');
                  $(window).trigger('didShow.panel', panel);
                  document.dispatchEvent(new CustomEvent('didShow.panel'));
                  resolve();
                }
                if (panel.type === Panel.CIRCLE) {
                  panel.eventSource.trigger('didShow.circlePanel');
                }
              }, 350);
            }, 10);
          });
        })
        .catch((err) => {
          console.log('err', err);
        });
    });
  }

  static hide(panel, options = {}) {
    return Promise.resolve(true)
      .then(function () {
        if (panel.subPanel) {
          return panel.subPanel.hide();
        } else {
          return true;
        }
      })
      .then(function () {
        return new Promise(function (resolve) {
          panel.eventSource.trigger('willHide.panel', panel);
          $(window).trigger('willHide.panel', panel);
          let closeAnimationTriggered = false;
          panel.content.one(transition_event_name, function () {
            panel.content.remove();
            panel.eventSource.trigger('didHide.panel', panel);
            $(window).trigger('didHide.panel', panel);
            closeAnimationTriggered = true;
            resolve();
          });
          if (panel.parentPanel) {
            panel.parentPanel.subPanel = null;
            panel.parentPanel = null;
          }
          if (panel.type == Panel.COMS) {
            Panel.subPanel = null;
          } else if (panel.type == Panel.MODAL) {
            Panel.modalPanel = null;
          } else if (panel == Panel.currentPanel) {
            Panel.currentPanel = null;
          }

          if (!options.inShow && location.pathname.startsWith('/editor') && panel.type === Panel.HUB) {
            PurpleGuideRenderer.render('creatingANode', null, Panel.getPurpleGuideNode('Editor'));
          }

          key.deleteScope(panel.keyScope);
          panel.content.removeClass('open');
          setTimeout(function () {
            if (!closeAnimationTriggered) {
              panel.content.remove();
              panel.eventSource.trigger('didHide.panel', panel);
              $(window).trigger('didHide.panel', panel);
              closeAnimationTriggered = true;
              resolve();
            }
          }, 450);
        });
      });
  }

  get eventSource() {
    if (!this._eventsource) {
      this._eventsource = $({});
    }
    return this._eventsource;
  }

  get keyScope() {
    return 'panels';
  }

  setupContent(type, content, opts) {
    this.content = $(
      Handlebars.templates['panel']({
        type: Panel.typeClass(type),
        class: opts.class,
        fullscreen: opts.fullscreen,
      }),
    );

    this.content.html(content);

    key.setScope(this.keyScope);
    if (!opts.disableEscape) {
      key('esc', this.keyScope, () => {
        if (!$('.pr2--js-closeBtn').attr('disabled') && !$('body').attr('data-quill-focus')) {
          this.hide();
        }
      });
    }

    this.content
      .find('.pr2--js-closeBtn')
      .one('keypress ' + Utils.tapInteractionEvent + '.panel', this.hide.bind(this));
  }

  updateContent(content) {
    this.content.html(content);
    this.content
      .find('.pr2--js-closeBtn')
      .one('keypress ' + Utils.tapInteractionEvent + '.panel', this.hide.bind(this));
  }

  hideMountPoint(fn) {
    key.unbind('esc');
    key('esc', this.keyScope, (e) => {
      if (!$('.pr2--js-closeBtn').attr('disabled')) {
        fn.bind(this)(e);
      }
    });
    this.content.find('.pr2--js-closeBtn').off('.panel');
    this.content.find('.pr2--js-closeBtn').on('keypress ' + Utils.tapInteractionEvent + '.panel', fn.bind(this));
  }

  constructor(type, content, opts) {
    this.type = type;
    this.opts = opts;

    this.setupContent(type, content, opts);

    this.bindsToMain = opts.bindToMain;
  }

  show(options = {}) {
    return Panel.show(this, options);
  }

  hide(e, options) {
    key.unbind('esc');
    if (e) {
      e.preventDefault();
    }
    return Panel.hide(this, options);
  }
}

Panel.HUB = 0;
Panel.NODE = 1;
Panel.COMS = 2;
Panel.CIRCLE = 3;
Panel.MODAL = 4;

Panel.pipeline = [];
Panel.modals = [];
