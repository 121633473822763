import styled from 'styled-components';

export const Container = styled.div``;

export const TextBlock = styled.p`
  a {
    text-decoration: underline;
  }
`;

export const Wrapper = styled.div`
  margin-bottom: 24px;
`;
