import React from 'react';
import { useTransition } from '@react-spring/web';
import Utils from '../../../app/assets/js/shared/utils';
import useNavigateWith from '../../hooks/useNavigateWithData';
import useApi from '../../hooks/useApi';
import {
  NotificationWrapper,
  ListWrapper,
  NotificationsWrapper,
  NotificationContent,
  NotificationItem,
  NotificationAvatar,
  NotificationInfo,
  NotificationBody,
  NotificationTimestamp,
  MarkAllAsRead,
  NoNotifications,
  LoadingNotifications,
} from './styles';

const NotificationList = React.forwardRef((props, ref) => {
  const { notifications = [], isOpen, hasRouter, userToken, toggleNotificationMenu, isNotificationsLoading } = props;

  const transitions = useTransition(isOpen, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  const { putData } = useApi(userToken);
  const { navigateAndReload, reload } = useNavigateWith('', hasRouter);
  const gotoNotification = (url) => navigateAndReload(url);

  const getReportTemplate = (report) => {
    const { state, teacher, actor, name } = report;
    if (state === 'blank') {
      return null;
    }

    if (teacher) {
      return i18n.__(
        `notification_state_change_teacher_${state}`,
        actor,
        name ? name : i18n.__('notification_no_node_name_holder'),
      );
    } else {
      return i18n.__(
        `notification_state_change_student_${state}`,
        actor,
        name ? name : i18n.__('notification_no_node_name_holder'),
      );
    }
  };

  const getSessionTemplate = (session, sessionStartedMeta) => {
    if (sessionStartedMeta) {
      return i18n.__('notification_session_started', sessionStartedMeta.name);
    } else if (session.circle) {
      return i18n.__('notification_session_started', session.circle.name);
    }
    return null;
  };

  const getCourseCompletedTemplate = (session) => {
    if (session && session.isCertificateEnabled) {
      return i18n.__('notification_course_completed_with_certification', session.name);
    } else if (session) {
      return i18n.__('notification_course_completed', session.name);
    }
    return null;
  };

  const getCollaboratorTemplate = (actor, collaborator, addCollaboratorMeta) => {
    if (collaborator) {
      const { added_user, circle } = collaborator;
      if (actor && circle) {
        return i18n.__('notification_add_collaborator_with_actor', added_user, circle.name, actor.name);
      } else if (circle) {
        return i18n.__('notification_add_collaborator', added_user, circle.name);
      }
    } else {
      const { added_user, name } = addCollaboratorMeta;
      return i18n.__('notification_add_collaborator', added_user, name);
    }
    return null;
  };

  const getDeadlineTemplate = (deadline, node) => {
    const days = Math.ceil((deadline - +new Date()) / (24 * 60 * 60 * 1000));

    const deadlineText = i18n.__(
      days === 1 ? 'notification_deadline_format_single' : 'notification_deadline_format_plural',
      days,
    );

    if (node) {
      return `${i18n('app_you')} ${i18n('notification_verb_deadline')} ${node.name} ${deadlineText}`;
    }
    return null;
  };

  const getReviewTemplate = (circle) => {
    const { name, approved } = circle;
    if (approved) {
      return i18n.__('notification_review_approved_body', name);
    } else {
      return i18n.__('notification_review_rejected_body', name);
    }
  };

  const getUserImportTemplate = (organisation, simulation) => {
    if (organisation) {
      if (simulation) {
        return i18n.__('notification_user_import_simulation_done', organisation.name);
      } else {
        return i18n.__('notification_user_import_done', organisation.name);
      }
    }
    return null;
  };

  const getGeneratedContentTemplate = (organisation) => {
    if (organisation) {
      return i18n.__('notification_ai_content_generated', organisation.name);
    }
    return null;
  };

  const getAnnounceTemplate = (actor, objectName) => {
    return i18n.__('notification_announce', actor.name, objectName);
  };

  const getDefaultTemplate = (actor, node, templateKey) => {
    const verb = i18n.__(`notification_verb_${templateKey}`);
    return `${actor ? actor.name : ''} ${verb} ${node ? node.name : ''}`;
  };

  const formatTimestamp = (timestamp) => {
    return Utils.relativeTime(timestamp);
  };

  const hasNotifications = notifications.filter((notification) => notification.tmpl_key).length > 0;
  const notificationItems = notifications
    .filter((notification) => notification.tmpl_key)
    .map((notification) => {
      try {
        const {
          id,
          actors = [],
          node,
          read,
          deadline,
          report,
          circle,
          session,
          timestamp,
          tmpl_key: templateKey,
          collaborator,
          add_collaborator_meta: addCollaboratorMeta,
          session_started_meta: sessionStartedMeta,
          simulation,
          organisation,
          objectName,
        } = notification || {};
        const actor = actors.length > 0 ? actors[0] : undefined;
        let template = '';
        let iconUrl = actor ? actor.image : '';
        let openInNewWindow = false;

        switch (templateKey) {
          case 'deadline':
            template = getDeadlineTemplate(deadline, node);
            break;
          case 'review_state':
            template = getReviewTemplate(circle);
            iconUrl = circle.image;
            break;
          case 'report_state':
            template = getReportTemplate(report);
            break;
          case 'session_started':
            template = getSessionTemplate(session, sessionStartedMeta);
            iconUrl = sessionStartedMeta ? sessionStartedMeta.image : session.circle.image;
            break;
          case 'add_collaborator':
            template = getCollaboratorTemplate(actor, collaborator, addCollaboratorMeta);
            iconUrl = collaborator ? collaborator.circle.image : addCollaboratorMeta.image;
            break;
          case 'user_import_done':
            template = getUserImportTemplate(organisation, simulation);
            iconUrl = organisation.image;
            break;
          case 'ai_content_generated':
            template = getGeneratedContentTemplate(organisation);
            iconUrl = organisation.image;
            break;
          case 'course_completed':
            template = getCourseCompletedTemplate(session);
            iconUrl = session.image;
            openInNewWindow = session.isCertificateEnabled;
            break;
          case 'announce':
            template = getAnnounceTemplate(actor, objectName);
            break;
          default:
            template = getDefaultTemplate(actor, node, templateKey);
            break;
        }

        const onClick = (ev) => markAsRead(ev, id, openInNewWindow);

        return (
          template && (
            <NotificationItem key={id} read={read} tabIndex={0} onClick={onClick} onKeyPress={onClick}>
              <NotificationAvatar icon={iconUrl} />
              <NotificationInfo>
                <NotificationBody>{template}</NotificationBody>
                <NotificationTimestamp>{formatTimestamp(timestamp)}</NotificationTimestamp>
              </NotificationInfo>
            </NotificationItem>
          )
        );
      } catch (error) {
        console.error(error);
        return null;
      }
    })
    .filter((notification) => !!notification);

  const markAllAsRead = async (ev) => {
    const res = await putData('/notifications/readall');
    if (res.success) {
      toggleNotificationMenu(ev);
      reload();
    }
  };

  const markAsRead = async (ev, id, openInNewWindow) => {
    const url = `/notifications/${id}`;
    const res = await putData(`${url}/read`);
    if (res.success) {
      toggleNotificationMenu(ev);
      if (openInNewWindow) {
        notifications.find((notification) => notification.id === id).read = true;
        window.open(url);
      } else {
        gotoNotification(url);
      }
    }
  };

  return transitions(
    (style, show) =>
      show && (
        <NotificationWrapper style={style} ref={ref}>
          {hasNotifications ? (
            <ListWrapper>
              <MarkAllAsRead tabIndex={0} onClick={(ev) => markAllAsRead(ev)} onKeyPress={(ev) => markAllAsRead(ev)}>
                {i18n.__('notifications_mark_all_as_read')}
              </MarkAllAsRead>
              <NotificationsWrapper>
                <NotificationContent>{notificationItems}</NotificationContent>
              </NotificationsWrapper>
            </ListWrapper>
          ) : !isNotificationsLoading ? (
            <NoNotifications>{i18n.__('notifications_no_pending_items')}</NoNotifications>
          ) : null}
          {isNotificationsLoading && <LoadingNotifications>{i18n.__('notifications_loading')}</LoadingNotifications>}
        </NotificationWrapper>
      ),
  );
});

NotificationList.displayName = 'NotificationList';

export default NotificationList;
