import React from 'react';
import PropTypes from 'prop-types';
import PrimaryButton from '../PrimaryButton';
import ArrowIconBlack from '../../../public/assets/images/icons/arrow-up-black.svg';
import ArrowIconGreen from '../../../public/assets/images/icons/arrow-up.svg';
import { ButtonIconLeft, ButtonIconRight } from './styles';

const WizardButton = ({ text, type = 'button', direction = 'right', secondary = true, onClick, ...rest }) => {
  const ButtonIcon = direction === 'right' ? ButtonIconRight : ButtonIconLeft;
  const ArrowIcon = secondary ? ArrowIconBlack : ArrowIconGreen;
  return (
    <ButtonIcon icon={ArrowIcon}>
      <PrimaryButton text={text} type={type} secondary={secondary} onClick={onClick} {...rest} />;
    </ButtonIcon>
  );
};

WizardButton.propTypes = {
  text: PropTypes.string.isRequired,
  type: PropTypes.string,
  direction: PropTypes.string,
  onClick: PropTypes.func,
  rest: PropTypes.object,
};

export default WizardButton;
