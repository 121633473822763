import styled from 'styled-components';

export const PopupContainer = styled.div`
  top: 0;
  pointer-events: all;
  position: absolute;
  z-index: 24;
  width: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  border: 1px solid ${({ theme }) => theme.colors.hoverGrey};
  padding: 20px;
  border-radius: 0;
  background-color: ${({ theme }) => theme.colors.organizationGreen};

  @media ${({ theme }) => theme.sizes.tablet} {
    top: 50px;
    width: 636px;
    max-height: calc(100vh - 100px);
    left: 50%;
    margin-left: -318px;
    border-radius: 20px;
  }
`;

export const PopupClose = styled.span`
  position: absolute;
  top: 25px;
  right: 25px;
  font-weight: 400;
  font-size: 20px;
  width: 16px;
  height: 16px;
  background-color: ${({ theme }) => theme.colors.transparent};
  opacity: 0.5;
  transition: opacity 0.2s ease-in-out;
  cursor: pointer;
  background-image: url('/assets/images/theme-2/close-black.svg');

  &:hover,
  &:focus {
    opacity: 1;
    transition: opacity 0.2s ease-in-out;
  }
`;

export const AttributePanel = styled.div`
  padding: 0 0 16px 0;
  display: flex;
  flex-direction: column;

  label {
    color: ${({ theme }) => theme.colors.black};
  }

  .color-picker {
    top: calc(-100% - 100px);
  }

  .react-colorful {
    width: 120px;
    height: 120px;
  }
`;

export const AttributeHeader = styled.label`
  font-size: 20px;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 10px;
`;

export const AttributeDescription = styled.span`
  font-weight: normal;
  margin-bottom: 12px;
`;

export const TextFieldWrapper = styled.div`
  div {
    margin: 8px 0;
  }

  input {
    font-family: ${({ theme }) => theme.fonts.roboto};
    color: ${({ theme }) => theme.colors.black};
    background-color: ${({ theme }) => theme.colors.white};
    padding: 5px;
    border-radius: 8px;
    border: 1px solid {({ theme }) => theme.colors.black_3};

    &::selection {
      color: ${({ theme }) => theme.colors.white};
      background-color: ${({ theme }) => theme.colors.standardComponentBackground};
    }

    &::placeholder {
      color: ${({ theme }) => theme.colors.black_7};
      font-style: italic;
    }
  }
`;

export const ButtonPanel = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const Label = styled.label`
  display: flex;
  margin-right: 5px;
  align-items: center;
`;

export const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;

  input {
    margin-right: 20px;

    color: ${({ theme }) => theme.colors.black_9};
    border-color: ${({ theme }) => theme.colors.black_9};

    &:hover:not(&:disabled),
    &:focus:not(&:disabled) {
      color: ${({ theme }) => theme.colors.black};
      border-color: ${({ theme }) => theme.colors.black};
    }
  }
`;
