import styled, { css } from 'styled-components';
import { animated } from '@react-spring/web';

export const PopupWrapper = styled(animated.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 23;
  pointer-events: none;
  overscroll-behavior: contain;

  ${({ $overflowY }) =>
    $overflowY &&
    css`
      pointer-events: all;
      overflow: auto;
    `};
`;

export const PopupMask = styled(PopupWrapper)`
  z-index: 20;
  opacity: 0.7;
  pointer-events: all;
  background-color: ${({ theme }) => theme.colors.black};
`;

export const PopupHeading = styled.h2`
  font-size: 30px;
  color: ${({ theme }) => theme.colors.white};
  margin-bottom: 33px;
  text-transform: ${({ capitalize }) => (capitalize ? 'capitalize' : 'none')};
`;

export const PopupBody = styled.div`
  width: 100%;
  padding: 74px 42px 57px 42px;
  font-size: 15px;
  line-height: 25px;

  @media ${({ theme, snapWidth }) => (snapWidth > 0 ? `(min-width: ${snapWidth}px)` : theme.sizes.tablet)} {
    width: ${({ width }) => (width ? `${width}px` : '435px')};
    max-height: calc(100vh - 100px);
    overflow-y: ${({ $overflowY }) => ($overflowY ? 'visible' : 'auto')};
  }
`;

export const PopupContent = styled.div`
  color: ${({ theme }) => theme.colors.standardTextGrey};
`;

export const PopupButtonPanel = styled.div`
  position: relative;
  z-index: 1;

  ${({ singleButton }) =>
    singleButton &&
    css`
      display: flex;
      justify-content: flex-end;
    `};
`;

export const PopupBox = styled.div`
  top: 0;
  border: 2px solid ${({ theme }) => theme.colors.grey};
  border-radius: 0;
  pointer-events: all;
  position: absolute;
  z-index: 24;
  background-color: ${({ theme }) => theme.colors.standardBlackBackground};
  height: 100%;
  width: 100%;
  overflow-y: ${({ $overflowY }) => ($overflowY ? 'visible' : 'auto')};

  @media ${({ theme, snapWidth }) => (snapWidth > 0 ? `(min-width: ${snapWidth}px)` : theme.sizes.tablet)} {
    top: 10vh;
    height: auto;
    width: auto;
    left: 50%;
    margin-left: ${({ width }) => (width ? `-${width / 2}px` : `-${435 / 2}px`)};
    border-radius: 15px;

    .show-mask.scrollbar & {
      margin-left: ${({ width }) => (width ? `-${(width + 16) / 2}px` : `-${(435 + 16) / 2}px`)};
    }
  }
`;

export const PopupClose = styled.span`
  position: absolute;
  top: 25px;
  right: 25px;
  font-weight: 400;
  font-size: 20px;
  width: 16px;
  height: 16px;
  background-color: ${({ theme }) => theme.colors.transparent};
  opacity: 0.5;
  transition: opacity 0.2s ease-in-out;
  cursor: pointer;
  background-image: url('/assets/images/theme-2/close-white.svg');

  &:hover,
  &:focus {
    opacity: 1;
    transition: opacity 0.2s ease-in-out;
  }
`;

export const PopupTextBlock = styled.p`
  margin-bottom: 34px;
  font-style: ${({ italic }) => (italic ? 'italic' : 'normal')};
`;
