const $empty = function () {};
export default class Uploader {
  constructor(element, options) {
    let form_data = {
      client: 'pr2',
      protected: 0,
      public: 1,
    };

    if (options.imageUpload) {
      form_data['enforce_image'] = 1;
    }

    if (options.userUUID) {
      form_data['user_uuid'] = options.userUUID;
    }

    this.options = $.extend(
      true,
      {
        formData: form_data,
        url: 'https://files.test.lin.education',
        paramName: 'file',
        dataType: 'json',
        autoUpload: true,
        uploadComplete: $empty,
        uploadStarted: $empty,
        uploadError: $empty,
        urlTemplate: '/file/{filename}',
      },
      options,
    );

    this.element = $(element).fileupload(
      $.extend({}, this.options, {
        dropZone: $(element),
        url: this.options.url + 'upload',
      }),
    );

    this.element.bind('fileuploadsend', (e, data) => {
      this.uploadStarted(e, data);
    });

    this.element.bind('fileuploaddone', (e, data) => {
      this.uploadComplete(e, data);
    });

    this.element.bind('fileuploadfail', (e, data) => {
      this.uploadError(e, data);
    });
  }

  uploadStarted(e, data) {
    this.element.addClass('loading');
    this.options.uploadStarted(data.result);
  }

  uploadComplete(e, data) {
    if (this.options.imageUpload) {
      let image = new Image();
      image.onload = () => {
        data.result.image = image;
        data.result.path = this.options.urlTemplate.supplant({
          filename: data.result.filename,
          url: this.options.url,
        });
        this.options.uploadComplete(data.result);
        this.element.removeClass('loading');
      };
      //
      image.src =
        this.options.url +
        this.options.urlTemplate.supplant({
          filename: data.result.filename,
          url: this.options.url,
        });
    } else {
      this.options.uploadComplete(data.result);
      this.element.removeClass('loading');
    }
  }

  uploadError(e, data) {
    this.element.removeClass('loading');
    if (this.options.uploadError) {
      this.options.uploadError(data);
    } else {
      flash(i18n.__('error_file_upload_failed'));
    }
  }
}

class EditorUploader extends Uploader {
  uploadFile(file) {
    this.element.fileupload('send', { files: [file] });
  }

  uploadComplete(e, data) {
    data.result.path =
      this.options.url +
      this.options.urlTemplate.supplant({
        filename: data.result.filename,
        url: this.options.url,
      });
    this.options.uploadComplete(data.result);
  }

  uploadError(e, data) {
    this.options.uploadError(data);
  }
}

if (!String.prototype.supplant) {
  String.prototype.supplant = function (o) {
    return this.replace(/\{([^{}]*)\}/g, function (a, b) {
      var r = o[b];
      return typeof r === 'string' || typeof r === 'number' ? r : a;
    });
  };
}
