import React from 'react';
import PropTypes from 'prop-types';

import { ContentSection } from './styles';

const NodeContentSection = ({ description = '', type, contextId }) => {
  const contentSection = (
    <ContentSection
      className="voice-content"
      data-selection-entity={type}
      data-selection-uuid={contextId}
      dangerouslySetInnerHTML={{ __html: description }}
    />
  );

  return contentSection;
};

export default NodeContentSection;

NodeContentSection.propTypes = {
  description: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  contextId: PropTypes.string.isRequired,
};
