import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import SecondaryButton from '../../SecondaryButton';
import PrimaryButton from '../../PrimaryButton';
import CheckBox from '../../CheckBox';
import { ViewSection, AutoSaveStatus, ColumnWrapper, ButtonPanel } from '../styles';
import { ChecklistWrapper, ChecklistHeader, ChecklistBody } from '../../NodeContentPreview/styles';

const ChecklistContentViewer = ({ report, saveReport, data, autoSaveStatus, isTeacher, readOnly }) => {
  if (!report) {
    return null;
  }

  const [items, setItems] = useState([]);
  const [checks, setChecks] = useState({});

  useEffect(() => {
    const sortedItems = [...data.ChecklistItems]
      .sort((a, b) => a.order - b.order)
      .map((item) => {
        const { id, content, order } = item;
        return {
          id,
          content,
          order,
          checked: checks[item.id] || false,
        };
      });
    setItems(sortedItems);
  }, [data, checks]);

  const isEditable = isTeacher || ['blank', 'active'].includes(report.state);
  const disabled = readOnly || !isEditable;

  useEffect(() => {
    setChecks(report.list || {});
  }, [report]);

  const submitReport = () => {
    saveReport({ ...report, state: 'done', draft: false });
  };

  const revokeReport = () => {
    saveReport({ ...report, state: 'active', draft: true });
  };

  const handleCheckbox = (event) => {
    if (disabled) {
      return;
    }
    const { id, checked } = event.target;
    const list = { ...(report.list || {}), [parseInt(id)]: checked };
    let data = { ...report, list };

    const allDone = items.filter(({ id }) => !!list[id]).length === items.length;

    if (allDone) {
      data.state = 'done';
      data.draft = false;
    }

    saveReport(data);
  };

  const list = items.map((item, index) => {
    return (
      <CheckBox
        key={index}
        id={`${item.id}`}
        text={item.content}
        defaultChecked={item.checked}
        value={1}
        onChange={handleCheckbox}
        lightMode={true}
        disabled={disabled}
      />
    );
  });

  const buttonPanel = (
    <ButtonPanel>
      <AutoSaveStatus>{autoSaveStatus['contentEditor']}</AutoSaveStatus>
      {isEditable ? (
        <SecondaryButton text={i18n.__('app_handin')} onClick={submitReport} disabled={isTeacher} />
      ) : (
        <PrimaryButton text={i18n.__('app_edit')} onClick={revokeReport} />
      )}
    </ButtonPanel>
  );

  return (
    <ViewSection>
      <ColumnWrapper>
        <ChecklistWrapper>
          <ChecklistHeader>
            <h2>{i18n.__('checklists.header')}</h2>
          </ChecklistHeader>
          <ChecklistBody>{list}</ChecklistBody>
        </ChecklistWrapper>
      </ColumnWrapper>
      {buttonPanel}
    </ViewSection>
  );
};

export default ChecklistContentViewer;

ChecklistContentViewer.propTypes = {
  type: PropTypes.string.isRequired,
  report: PropTypes.object,
  saveReport: PropTypes.func.isRequired,
  user: PropTypes.object,
  fileService: PropTypes.object.isRequired,
  integrations: PropTypes.object.isRequired,
  autoSaveStatus: PropTypes.object.isRequired,
  isTeacher: PropTypes.bool.isRequired,
  readOnly: PropTypes.bool,
};
