import styled, { css } from 'styled-components';

export const InputFieldWrapper = styled.div`
  display: inline-block;
`;

export const InputRadioButton = styled.input`
  display: none;

  &:checked ~ div {
    background-image: url(/assets/images/icons/radio-selected.svg);
  }
`;

export const Icon = styled.div`
  background-image: url(/assets/images/icons/radio-unselected.svg);
  background-position: 0 3px;
  background-repeat: no-repeat;
  background-size: 14px 14px;
  content: '';
  display: inline-block;
  padding-left: 20px;
`;

export const Label = styled.span`
  border: 1px solid ${({ theme }) => theme.colors.transparent};
  transition:
    color 0.2s ease,
    border-bottom 0.2s ease;
`;

export const RadioButtonLabel = styled.label`
  margin-right: 25px;
  display: flex;
  color: ${({ theme }) => theme.colors.standardTextGrey};
  font-weight: normal;
  cursor: pointer;
  white-space: nowrap;

  ${({ tabIndex }) =>
    tabIndex === 0 &&
    css`
      &:hover,
      &:focus {
        ${Label} {
          color: ${({ theme }) => theme.colors.white};
          border-bottom: 1px solid ${({ theme }) => theme.colors.white};
          transition:
            color 0.2s ease,
            border-bottom 0.2s ease;
        }
      }
    `}
`;
