import React from 'react';
import PropTypes from 'prop-types';
import NodeActivityEditor from '../../NodeActivityEditor';
import { PreviewSection } from '../styles';
import {
  EditorHeaderRowLeft,
  HeaderUserName,
  EditorHeaderRowRight,
  SubmitButton,
} from '../../NodeActivityEditor/styles';

const TaskContentPreview = ({ previewText }) => {
  const headerPanel = (
    <>
      <EditorHeaderRowLeft>
        <HeaderUserName>{i18n.__('app_student_editor')}</HeaderUserName>
      </EditorHeaderRowLeft>
      <EditorHeaderRowRight />
    </>
  );

  const buttonPanel = <SubmitButton>{i18n.__('app_handin')}</SubmitButton>;

  return (
    <PreviewSection>
      <NodeActivityEditor headerPanel={headerPanel} buttonPanel={buttonPanel} editorText={previewText} />
    </PreviewSection>
  );
};

export default TaskContentPreview;

TaskContentPreview.propTypes = {
  previewText: PropTypes.string.isRequired,
};
