import React, { useState } from 'react';
import { withTheme } from 'styled-components';
import TextField from '../TextField';
import Popup from '../Popup';
import useKeyPress from '../../hooks/useKeyPress';
import ColorPicker from '../../components/ColorPicker';
import NumericField from '../../components/NumericField';
import SecondaryButton from '../../components/SecondaryButton';
import {
  PopupContainer,
  PopupClose,
  AttributePanel,
  AttributeHeader,
  AttributeDescription,
  TextFieldWrapper,
  ButtonPanel,
  Label,
  RowWrapper,
} from './styles';

const ImageAttributePopup = ({ data, popupIsOpen, onSave, onClose, theme, ...rest }) => {
  const { alt, style } = data;

  const [imageAlt, setImageAlt] = useState(alt || '');
  const [imageBorderWidth, setImageBorderWidth] = useState(parseInt(style.borderWidth) || 0);
  const [imageBorderColor, setImageBorderColor] = useState(style.borderColor || theme.colors.black);

  const handleClose = () => {
    onClose();
  };

  const handleSave = () => {
    onSave({
      alt: imageAlt,
      border: `${imageBorderWidth}px solid ${imageBorderColor}`,
    });
  };

  useKeyPress('Escape', () => {
    handleClose();
  });

  const popupContent = (
    <PopupContainer className="image-attribute-popup" {...rest}>
      <PopupClose
        tabIndex={0}
        onClick={handleClose}
        onKeyPress={handleClose}
        role="button"
        aria-label={i18n.__('app_close')}
      />
      <AttributePanel>
        <AttributeHeader id="imageAltLabel">{i18n.__('image_attributes.alt_header')}</AttributeHeader>
        <AttributeDescription>{i18n.__('image_attributes.alt_description')}</AttributeDescription>
        <TextFieldWrapper>
          <TextField
            autoFocus
            id="imageAlt"
            name="imageAlt"
            aria-labelledby="imageAltLabel"
            placeholder={i18n.__('image_attributes.alt_placeholder')}
            defaultValue={imageAlt}
            onChange={(event) => setImageAlt(event.target.value)}
          />
        </TextFieldWrapper>
        <AttributeHeader>{i18n.__('image_attributes.border_header')}</AttributeHeader>
        <RowWrapper>
          <Label id="borderWidth">{i18n.__('image_attributes.border_width')}:</Label>
          <NumericField
            aria-labelledby="borderWidth"
            hideArrows={false}
            validateOnBlur={true}
            min={0}
            max={100}
            value={imageBorderWidth}
            onChange={(value) => setImageBorderWidth(value)}
          />
          <Label id="borderColor">{i18n.__('image_attributes.border_color')}:</Label>
          <ColorPicker
            aria-labelledby="borderColor"
            color={imageBorderColor}
            onChange={(value) => setImageBorderColor(value)}
            showInputField={false}
          />
        </RowWrapper>
      </AttributePanel>

      <ButtonPanel>
        <SecondaryButton text={i18n.__('app_save')} onClick={() => handleSave()} />
      </ButtonPanel>
    </PopupContainer>
  );

  return <Popup popupIsOpen={popupIsOpen} id="image-attribute-popup" fullBody={popupContent} onClose={onClose} />;
};

export default withTheme(ImageAttributePopup);
