import { format } from 'date-fns';
const DATE_FORMAT = 'yyyy-MM-dd HH:mm';

export default class ImmersiveReaderContentBuilder {
  constructor(title) {
    this.content = {
      title: (title || '').trim(),
      chunks: [],
    };
  }

  addChunk(content, mimeType = undefined) {
    this.content.chunks.push({
      content,
      mimeType,
    });
  }

  addHtmlChunk(content) {
    this.addChunk(content, 'text/html');
  }

  getContent() {
    return this.content;
  }

  buildLoop(content) {
    const dom = $(content);
    this.addHtmlChunk(dom.find('.circle-description__body').html());
  }

  buildNode(content) {
    const dom = $(content);
    this.content.title = dom.find('.pr2--hubPaneName').text();
    this.addHtmlChunk(dom.find('.pr2--hubPanelDescription .content-viewer').html());
  }

  buildBlog(content) {
    (content.BlogSessions || []).forEach((blogSession) => {
      (blogSession.BlogReports || []).forEach((blogReport) => {
        (blogReport.BlogPosts || []).forEach((blogPost) => {
          const name = blogReport.Participation?.User?.full_name || '';
          this.addChunk(`${name}, ${blogPost.updated_at}.`);
          this.addHtmlChunk(blogPost.body);
        });
      });
    });
  }

  buildCorrelation(content) {
    let correlationDescriptions = [];
    for (let concept of content.CorrelationList.CorrelationConcepts || []) {
      correlationDescriptions.push(concept.CorrelationDescription);
    }

    (content.CorrelationSessions || []).forEach((correlationSession) => {
      (correlationSession.CorrelationReports || []).forEach((correlationReport) => {
        if (correlationReport.state !== 'blank') {
          const name = correlationReport.Participation?.User?.full_name || '';
          this.addChunk(name);

          (correlationReport.CorrelationAnswers || []).forEach((correlationAnswer) => {
            const conceptAnswer = (content.CorrelationList.CorrelationConcepts || []).find(
              (concept) => concept.id === correlationAnswer.correlation_concept_id,
            );
            const conceptDescription = correlationDescriptions.find(
              (description) => description.id === correlationAnswer.correlation_description_id,
            );
            this.addHtmlChunk(conceptAnswer.body);
            this.addHtmlChunk(conceptDescription.body);
          });
        } else {
          (content.CorrelationList.CorrelationConcepts || []).forEach((concept) => {
            this.addHtmlChunk(`${concept.body}`);
            correlationDescriptions.forEach((description, descriptionIndex) => {
              const alternativeNumber = i18n.__('quiz_alternative_number', { number: descriptionIndex + 1 });
              this.addHtmlChunk(`${alternativeNumber}: ${description.body}`);
            });
          });
        }
      });
    });
  }

  buildEvaluation(content) {
    this.addHtmlChunk(content.placeholder);
    (content.EvaluationSessions || []).forEach((evaluationSession) => {
      (evaluationSession.EvaluationReports || []).forEach((evaluationReport) => {
        if (evaluationReport.body) {
          const name = evaluationReport.User?.full_name || '';
          const date = format(new Date(evaluationReport.updated_at), DATE_FORMAT);
          this.addChunk(`${name}, ${date}.`);
          this.addHtmlChunk(evaluationReport.body);
        }
      });
    });
  }

  buildLegend(content) {
    (content.CorrelationList.CorrelationConcepts || []).forEach((concept) => {
      this.addHtmlChunk(concept.body);
      this.addHtmlChunk(concept.CorrelationDescription.body);
    });
  }

  buildTask(content) {
    (content.TaskSessions || []).forEach((taskSession) => {
      (taskSession.TaskReports || []).forEach((taskReport) => {
        if (taskReport.state !== 'blank') {
          const names = taskReport.TaskParticipations.map(({ User }) => User.full_name).join(', ');
          const date = format(new Date(taskReport.updated_at), DATE_FORMAT);
          if (taskReport.name) {
            this.addChunk(`${taskReport.name}: ${names}, ${date}.`);
          } else {
            this.addChunk(`${names}, ${date}.`);
          }
          this.addHtmlChunk(taskReport.body);
        }
      });
    });
  }

  buildQuiz(content) {
    if (content.role === 'teacher') {
      const name = content.user?.full_name;
      if (name) {
        this.addChunk(name);
      }
    }

    if (content.QuizQuestions) {
      (content.QuizQuestions || []).forEach((question, index) => {
        (content.QuizSessions || []).forEach((quizSession) => {
          (quizSession.QuizReports || []).forEach((quizReport) => {
            if (quizReport.state !== 'blank') {
              const questionNumber = i18n.__('quiz_question_number', { number: index + 1 });
              this.addHtmlChunk(`${questionNumber}: ${question.body}`);

              (quizReport.QuizAnswers || []).forEach((quizAnswer) => {
                if (quizAnswer.question_id === question.id && quizAnswer.text) {
                  this.addHtmlChunk(quizAnswer.text);
                }
              });
            }
          });
        });
      });
    } else {
      (content.questions || []).forEach((question, index) => {
        const questionNumber = i18n.__('quiz_question_number', { number: index + 1 });
        this.addHtmlChunk(`${questionNumber}: ${question.body}`);

        const sortedAlternatives = (question.alternatives || []).sort((a, b) => {
          return a.order - b.order;
        });

        if (content.role === 'teacher') {
          if (content.answers && Object.keys(content.answers).length > 0) {
            sortedAlternatives.forEach((alternative, alternativeIndex) => {
              if (alternative.id === content.answers[question.id]) {
                const alternativeNumber = i18n.__('quiz_alternative_number', { number: alternativeIndex + 1 });
                this.addHtmlChunk(`${alternativeNumber}: ${alternative.body}`);
              }
            });
          }
        } else {
          sortedAlternatives.forEach((alternative, alternativeIndex) => {
            const alternativeNumber = i18n.__('quiz_alternative_number', { number: alternativeIndex + 1 });
            this.addHtmlChunk(`${alternativeNumber}: ${alternative.body}`);
          });
        }
      });
    }
  }

  buildChecklist(content) {
    (content.ChecklistItems || [])
      .sort((a, b) => a.order - b.order)
      .forEach((checklistItem) => {
        this.addHtmlChunk(`<div>${checklistItem.content}</div>`);
      });
  }

  buildFromContent(content, entityType = null) {
    if (!this.content.title && content?.Behavior?.Node?.name) {
      this.content.title = content.Behavior.Node.name.trim();
    }

    if (content.body) {
      this.addHtmlChunk(content.body);
    }

    const buildMap = {
      loop: this.buildLoop,
      node: this.buildNode,
      blog: this.buildBlog,
      correlation: this.buildCorrelation,
      evaluation: this.buildEvaluation,
      legend: this.buildLegend,
      task: this.buildTask,
      quiz: this.buildQuiz,
      checklist: this.buildChecklist,
    };

    if (entityType && buildMap[entityType]) {
      buildMap[entityType].bind(this)(content);
    }
  }
}
