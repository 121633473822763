import Utils from '../utils';
import Remote from '../remote';
import flash from '../flash';
import 'toolbar/jquery.toolbar.js';
import getDomPath from './get_dom_path';
import newCommentClickHandler from './new_comment_click_handler';

const determineBlockOwner = function (node) {
  if (node.nodeType === 1) {
    if (node.nodeName.match(SelectionManager.blockLevel)) {
      return node;
    } else {
      return determineBlockOwner(node.parentNode);
    }
  } else if (node.nodeType === 3) {
    return determineBlockOwner(node.parentNode);
  }
};

export default class SelectionManager {
  get eventSource() {
    if (!this._eventsource) {
      this._eventsource = $({});
    }
    return this._eventsource;
  }

  init(opts) {
    this.options = opts;
    let that = this;

    this.selectionChangeHandler = Utils.debounce(() => {
      const isEditing = document.activeElement.classList.contains('ql-editor');
      if (isEditing) {
        return;
      }

      let selection = window.getSelection();
      this.pendingSelection = selection;
      if (that.pendingSelection) {
        if (!that.pendingSelection.isCollapsed) {
          that.manageSelection(that.pendingSelection);
        } else {
          that.terminateSelection(that.pendingSelection);
        }
      } else {
        that.terminateSelection(that.pendingSelection);
      }
    }, 500);

    this.currentUserUUID = document.body.getAttribute('data-user-uuid');

    this.remote = new Remote('/learn');

    this.selectionClickHandler = this.selectionClicked.bind(this);

    this.fullscreenHandler = function () {
      that.terminateSelection();
    };

    $(document).on(Utils.tapInteractionEvent, 'mark', this.selectionClickHandler);
    $(document).on('selectionchange', this.selectionChangeHandler);
    $(document).on('touchended mouseup', this.mouseUpHandler);
    $(document).on('panel.fullscreen', this.fullscreenHandler);
  }

  detach() {
    $(document).off('click', 'mark', this.selectionClickHandler);
    $(document).off('selectionchange', this.selectionChangeHandler);
    $(document).off('touchended mouseup', this.mouseUpHandler);
    $(document).off('panel.fullscreen', this.fullscreenHandler);

    this.options.commentInstance.eventSource.off('comment.empty.selection');
    this.options.commentInstance.eventSource.off('comment.posted.selection');

    this.selectionChangeHandler = null;
    this.selectionClickHandler = null;
  }

  terminateSelection() {
    this.pendingSelection = null;
    let bd = $(document.body);
    if (bd.data('toolbarObj')) {
      $(document.body).toolbar('hide');
    }
  }

  selectionClicked(e) {
    let selection = $(e.currentTarget);
    if (e.target.nodeName === 'A') {
      // intentional no-op
      return;
    }
    e.preventDefault();
    const dataUUID = selection.attr('data-selection-uuid');
    if (e.shiftKey) {
      // don't know what this is...
      let wrapper = selection.parents('[data-selection-entity]');
      let owner = determineBlockOwner(selection.get(0));
      selection.contents().unwrap();
      wrapper.get(0).normalize();
      let path = getDomPath(owner, wrapper.get(0));
      this.remote
        .delete(this.options.viewer.options.session_id + '/selections/' + dataUUID, {
          data: owner.outerHTML,
          xpath: path,
        })
        .catch(function () {
          flash(i18n.__('error_selection_delete_failed'));
        });
    } else {
      this.options.commentInstance.eventSource.on(
        'comment.empty.selection',
        { matchingId: dataUUID },
        ({ entity_id, data: { matchingId } }) => {
          if (matchingId === entity_id) {
            selection.contents().unwrap();
          }
        },
      );
      this.options.commentInstance.open('selection', dataUUID);
    }
  }

  manageSelection(sel) {
    let that = this;
    let range = sel.getRangeAt(0);
    if (range.isCollapsed) {
      return;
    }
    let startBlock = determineBlockOwner(range.startContainer);
    let endBlock = determineBlockOwner(range.endContainer);

    let selectionAllowed = range.startContainer === range.endContainer || startBlock === endBlock;

    if (selectionAllowed) {
      let existingMarks = determineBlockOwner(range.commonAncestorContainer).querySelectorAll('mark');
      if (existingMarks.length > 0) {
        for (var i = 0; i < existingMarks.length; i++) {
          let markRange = document.createRange();
          markRange.selectNodeContents(existingMarks[i]);
          let start = range.compareBoundaryPoints(Range.START_TO_END, markRange);
          let end = range.compareBoundaryPoints(Range.END_TO_START, markRange);
          if (!(start <= 0 || end >= 0)) {
            selectionAllowed = false;
            break;
          }
        }
      }
      if (selectionAllowed) {
        let wrapper = null;
        if ($(startBlock).is('[data-selection-entity]')) {
          wrapper = $(startBlock);
        } else {
          wrapper = $(startBlock).parents('[data-selection-entity]');
        }
        selectionAllowed = wrapper.length > 0;
      }
    }

    let boundingRect = range.getBoundingClientRect();
    let toolbarObj = $(document.body).data('toolbarObj');
    let tools = this.determineValidTools(selectionAllowed);

    const voiceoverOnly = Object.keys(tools).length === 1 && tools.vo;
    const coordinates = {
      left: boundingRect.left + boundingRect.width / 2,
      top: boundingRect.top + boundingRect.height + 110,
    };

    if (voiceoverOnly) {
      coordinates.top -= 30;
    }

    that._range = range;

    if (toolbarObj) {
      toolbarObj.toolbar.css(coordinates);
      if (!selectionAllowed) {
        $(document.body).find(`[data-state='comment']`).hide();
      } else {
        $(document.body).find(`[data-state='comment']`).show();
      }
    } else {
      let toolbar = $(Handlebars.templates['text_tools'](tools));
      $(document.body)
        .toolbar({
          content: toolbar,
          coordinates,
          zIndex: 1300,
          event: false,
          destroyOnHide: true,
          hideOnClick: true,
          style: 'dark',
        })
        .on('toolbarItemClick.toolbar', (e, link) => {
          switch (link.getAttribute('data-state')) {
            case 'voiceover':
              document.dispatchEvent(new CustomEvent('open.immersiveReader', { detail: sel.toString() }));
              $(document.body).toolbar('hide');
              break;
            case 'comment':
              newCommentClickHandler(this).onCommentClick(startBlock);
          }
        })
        .on('toolbarHidden.toolbar', function () {
          $(this).off('.toolbar');
          $(window).off('.selection');
        })
        .one('toolbarShown.toolbar', () => {
          $('.tool-container').attr('role', 'dialog').attr('aria-label', 'dialog').attr('tabindex', 0).focus();
          $(window).one('willHide.panel.selection', () => {
            $(window).off('.selection');
            if ($(document.body).data('toolbarObj')) {
              $(document.body).off('.toolbar').toolbar('hide');
            }
          });
        })
        .toolbar('show');
      $('.arrow').css({ transform: 'rotate(180deg)', top: -14 });
    }
  }

  determineValidTools(selectionAllowed) {
    let tools = { vo: true };
    if (selectionAllowed) {
      // Always true, unless hideDiscussion in activated
      tools['comment'] = !$('.graph-container').hasClass('hideDiscussion');
    }
    return tools;
  }
}

SelectionManager.blockLevel = /H[1-6]|LI|OL|UL|P|DIV/;
