import React, { useState } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';
import { InputFieldWrapper, DropDownLabel } from './styles';

const DropDown = (props) => {
  const {
    label,
    id,
    items = [],
    selectedValue = '',
    isSearchable = false,
    isFontSelection = false,
    noHitsMessage = i18n.__('app_no_matches'),
    placeholder,
    onChange,
    theme,
    disabled,
    colors,
    maxWidth,
    ...rest
  } = props;

  const [value, setValue] = useState(selectedValue);

  const dropDownLabel = label ? <DropDownLabel htmlFor={id}>{label}</DropDownLabel> : null;

  const selectedOption = items.find((item) => item.value.toString().toUpperCase() === value.toString().toUpperCase());

  const onItemChange = (item) => {
    setValue(item.value);
    if (onChange) {
      onChange({ target: { ...item, id } });
    }
  };

  const getColors = (state) => state.selectProps.theme.colors;

  const dropdownStyle = {
    container: (provided) => ({
      ...provided,
      width: 'auto',
      maxWidth,
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: colors
        ? colors.back
        : state.isSelected || state.isFocused
          ? `${getColors(state).white_1}`
          : `${getColors(state).standardBlackBackground}`,
      color: colors ? colors.front : `${getColors(state).white}`,
      padding: 10,
      cursor: 'pointer',
      border: `1px solid ${getColors(state).iconButtonBorder}`,
      borderBottom: `1px solid ${getColors(state).grey}`,
      fontFamily: isFontSelection ? state.data.value : 'Roboto',
      display: 'flex',
    }),
    control: (provided, state) => ({
      ...provided,
      cursor: 'pointer',
      backgroundColor: colors ? colors.back : `${getColors(state).standardBlackBackground}`,
      color: colors ? colors.front : `${getColors(state).white}`,
      borderRadius: 5,
      transition: `border 0.3s ease`,
      border: state.isFocused
        ? `2px solid ${getColors(state).iconButtonBorderHover}`
        : `2px solid ${getColors(state).iconButtonBorder}`,
      ':hover': {
        borderColor: `${getColors(state).iconButtonBorderHover}`,
        boxShadow: 'none',
      },
      maxWidth,
      opacity: state.selectProps.isDisabled ? 0.6 : 1,
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: colors ? colors.front : `${getColors(state).white}`,
      display: 'flex',
    }),
    input: (provided) => ({
      ...provided,
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      cursor: state.selectProps.isSearchable ? 'text' : 'pointer',
    }),
    menu: (provided, state) => ({
      ...provided,
      backgroundColor: colors ? colors.back : `${getColors(state).standardBlackBackground}`,
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      color: `${getColors(state).buttonGreen}`,
      ':hover': {
        borderColor: `${getColors(state).buttonGreen}`,
      },
    }),
    placeholder: (provided, state) => ({
      ...provided,
      color: colors ? colors.placeholder : `${getColors(state).white_7}`,
      fontStyle: 'italic',
    }),
    noOptionsMessage: (provided, state) => ({
      ...provided,
      color: colors ? colors.front : `${getColors(state).white}`,
    }),
  };

  const placeholderText = placeholder || (isSearchable ? i18n.__('app_select_or_search') : i18n.__('app_select'));

  return (
    <InputFieldWrapper maxWidth={maxWidth} {...rest}>
      {dropDownLabel}
      <Select
        inputId={id}
        name={id}
        value={selectedOption}
        isSearchable={isSearchable}
        options={items}
        theme={theme}
        noOptionsMessage={() => noHitsMessage}
        onChange={onItemChange}
        onKeyPress={onItemChange}
        styles={dropdownStyle}
        placeholder={placeholderText}
        components={{
          IndicatorSeparator: () => null,
        }}
        isDisabled={disabled}
        {...rest}
      />
    </InputFieldWrapper>
  );
};

DropDown.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string.isRequired,
  items: PropTypes.array,
  selectedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  isSearchable: PropTypes.bool,
  isFontSelection: PropTypes.bool,
  disabled: PropTypes.bool,
  noHitsMessage: PropTypes.string,
  onChange: PropTypes.func,
  colors: PropTypes.object,
  maxWidth: PropTypes.number,
  placeholder: PropTypes.string,
  rest: PropTypes.object,
};

export default withTheme(DropDown);
