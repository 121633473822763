import styled, { css } from 'styled-components';

export const TopSection = styled.section`
  display: flex;
  background: ${({ theme }) => theme.colors.ghostWhite};
  justify-content: space-between;
  padding: 20px 30px;
  position: sticky;
  top: 0;
  z-index: 3;
`;

export const TitleWrapper = styled.div`
  color: ${({ theme }) => theme.colors.standardTextGrey};
  opacity: 0.8;
  display: flex;
  align-items: center;
  font-size: 15px;
  height: 26px;
  min-height: 20px;
  line-height: 26px;
  padding-left: 37px;
  position: relative;
`;

export const NodeIcon = styled.span`
  background-position: 50%;
  background-repeat: no-repeat;
  border-radius: 50%;
  height: 26px;
  left: 0;
  position: absolute;
  width: 26px;

  ${({ src }) =>
    src &&
    css`
      background-image: url(${src});
    `}

  &.chunk, &.video, &.link {
    background-size: 13px auto;
    border: 2px solid ${({ theme }) => theme.colors.behaviorYellow};
  }

  &.video {
    background-position: 8px 5px;
    background-size: 8px auto;
  }

  &.link {
    background-size: 12px auto;
  }

  &.task {
    background-size: 8px auto;
    border: 2px solid ${({ theme }) => theme.colors.behaviorBlue};
  }

  &.group {
    border: 2px solid ${({ theme }) => theme.colors.behaviorGreen};
    background-size: 14px auto;
    background-position: 4px 4px;
  }

  &.written_quiz {
    background-size: 8px auto;
    border: 2px solid ${({ theme }) => theme.colors.behaviorBlue};
  }

  &.checklist {
    background-size: 60%;
    border: 2px solid ${({ theme }) => theme.colors.behaviorBlue};
  }
`;

export const NodeTitle = styled.p`
  color: ${({ theme }) => theme.colors.black};
  display: flex;
  margin: 0;
`;

export const HeaderTools = styled.div`
  display: flex;
  align-items: center;
`;

export const HeaderTool = styled.div`
  width: 16px;
  height: 16px;
  opacity: 0.6;
  transition: 0.2s opacity ease-in-out;
  background-repeat: no-repeat;
  display: block;
  cursor: pointer;

  &:hover,
  &:focus {
    opacity: 1;
    transition: 0.2s opacity ease-in-out;
  }

  ${({ src }) =>
    src &&
    css`
      background-image: url(${src});
    `}
`;

export const FullScreenHeaderTool = styled(HeaderTool)`
  margin: 0 18px 0 0;
`;

export const DyslexicFontHeaderTool = styled(HeaderTool)``;

export const CloseHeaderTool = styled(HeaderTool)`
  background-size: 18px 18px;
  height: 18px;
  width: 18px;
  margin: 0 0 0 25px;
`;

export const TeacherPanelLink = styled.span`
  margin-left: 16px;
  width: 26px;
  height: 26px;
  align-self: center;
  cursor: pointer;
  display: inline-block;
  background-repeat: no-repeat;
  margin-bottom: 2px;
  flex-shrink: 0;
  transition: 0.2s;

  ${({ src }) =>
    src &&
    css`
      background-image: url(${src});
    `}

  &:hover {
    transform: scale(1.2);
    transition: transform 0.2s ease;
  }
`;
