import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import PrimaryButton from '../PrimaryButton';
import SecondaryButton from '../SecondaryButton';
import ContentEditor from '../ContentEditor';
import NodeContentTopSection from '../NodeContentTopSection';
import Popup from '../Popup';
import useApi from '../../hooks/useApi';
import RemoveIcon from '../../../public/assets/images/editor/delete-icon.svg';
import InfoIconInactive from '../../../public/assets/images/icons/info-green.svg';
import InfoIconActive from '../../../public/assets/images/icons/info-white.svg';
import ChunkContentEditor from './nodes/ChunkContentEditor';
import TaskContentEditor from './nodes/TaskContentEditor';
import WrittenQuizContentEditor from './nodes/WrittenQuizContentEditor';
import { debounce, setStatusSaving, setStatusSaved, setStatusSaveFailed, getAutoSaveInterval } from '../../utils';

import {
  PanelWrapper,
  HeaderSection,
  HeaderText,
  FooterSection,
  BottomPanel,
  InfoPanel,
  InfoText,
  InfoIcon,
  DeleteIcon,
  TypeSelector,
  ArticleType,
  SelectorText,
  Wrapper,
} from './styles';

const NodeContentEditor = ({ nodeId, editor, panel, fileService, flash }) => {
  const { options, useAutoSave, contentEditor, graph } = editor;
  const { type: baseType, updateNodeHeader } = contentEditor;
  const { userToken, integrations } = options;

  const { getData, loading } = useApi(userToken);
  const { postData } = useApi(userToken);
  const [updatedAt, setUpdatedAt] = useState();
  const [isInfoShowing, setIsInfoShowing] = useState(false);
  const [type, setType] = useState('');
  const [title, setTitle] = useState('');
  const [data, setData] = useState();
  const [questions, setQuestions] = useState([]);
  const [deletions, setDeletions] = useState([]);
  const [isUpdating, setIsUpdating] = useState(false);
  const [description, setDescription] = useState('');
  const [showSaveButton, setShowSaveButton] = useState(!useAutoSave);
  const [confirmPopupIsOpen, setConfirmPopupIsOpen] = useState(false);

  const getArticleTypeIcon = (articleType) => `/assets/images/theme-2/icons/${articleType}.svg`;
  const nodeDescription = i18n.__(`${baseType}_node_description`);
  const isArticle = baseType === 'chunk';
  const articleTypes = ['chunk', 'video', 'link'];
  const showAddQuestionButton = ['written_quiz'].includes(baseType);

  useEffect(() => {
    const getNodeData = async () => {
      if (!loading) {
        const nodeType = isArticle ? 'chunks' : 'behaviors';
        const response = await getData(`/api/v3/editor/${nodeType}/${nodeId}`);
        if (response.success) {
          const body = response.body;
          const loadedQuestions = body.data.QuizQuestions;
          setIsUpdating(true);
          setTitle(body.name || '');
          setType(body.type || '');
          setDescription((isArticle ? body.data.content : body.data.body) || '');
          setUpdatedAt(body.updated_at);
          setQuestions(
            loadedQuestions && loadedQuestions.length === 0 && !body.name
              ? [{ body: '', order: 0 }]
              : loadedQuestions || [],
          );
          setData(body.data);
          setIsUpdating(false);
        }
      }
    };
    getNodeData();
  }, []);

  useEffect(() => {
    document.addEventListener('useAutoSave', handleAutoSave);
    return () => document.removeEventListener('useAutoSave', handleAutoSave);
  }, []);

  const handleAutoSave = (ev) => {
    setShowSaveButton(!ev.detail.useAutoSave);
  };

  const toggleConfirmPopup = () => {
    setConfirmPopupIsOpen(!confirmPopupIsOpen);
  };

  const debouncedSave = useCallback(
    debounce(async (item) => {
      await save(item);
    }, getAutoSaveInterval()),
    [],
  );

  useEffect(() => {
    if (useAutoSave && updatedAt && !isUpdating) {
      setStatusSaving();
      debouncedSave({ title, description, type, questions, deletions, updatedAt });
    }
  }, [title, description, type, questions, deletions, debouncedSave]);

  const onChangeTitle = useCallback((value) => {
    setTitle(value);
  }, []);

  const onChangeDescription = useCallback((value) => {
    setDescription(value);
  }, []);

  const toggleInfo = () => {
    setIsInfoShowing(!isInfoShowing);
  };

  const remove = () => {
    toggleConfirmPopup();
    document.dispatchEvent(new CustomEvent('deleteNode', { detail: { nodeId } }));
    panel.hide();
  };

  const addQuestion = () => {
    const newQuestions = [...questions];
    newQuestions.push({ body: '', order: questions.length });
    setQuestions(newQuestions);
  };

  const save = async ({ title, description, type, questions, deletions, timestamp }) => {
    const payload = {
      node_information: {
        name: title,
        type,
      },
      questions,
      deletions: { questions: deletions },
      updated_at: timestamp,
    };
    if (isArticle) {
      payload.content = description;
    } else {
      payload.body = description;
    }

    const nodeType = isArticle ? 'chunks' : 'behaviors';
    const response = await postData(`/api/v3/editor/${nodeType}/${nodeId}`, payload);
    if (response.success) {
      setIsUpdating(true);
      setStatusSaved();
      const body = response.body;
      setUpdatedAt(body.updated_at);
      setDeletions([]);
      setQuestions((body.data && body.data.QuizQuestions) || []);
      updateNodeHeader(nodeId, title);
      if (isArticle) {
        const node = graph.cy.$(`#${nodeId}`);
        const originalType = node.data('type');
        if (originalType !== 'chunk') {
          node.removeClass(originalType);
        }
        node.addClass(type);
        node.data({ type });
      }
      setIsUpdating(false);
    } else {
      setStatusSaveFailed(i18n.__('behavior_save_failed'));
      console.error(response);
      if (response.message === 'auth_failed') {
        flash(i18n.__('app_error_unauthorized'), { ttl: 5000 });
      } else if (response.message === 'edit_conflict') {
        flash(i18n.__('error_edit_conflict_behavior'), { ttl: 5000 });
      } else if (response.message === 'payload_too_large') {
        flash(i18n.__('error_payload_too_large'), { ttl: 20000 });
      } else {
        flash(i18n.__('error_behavior_save_failed'));
      }
    }
  };

  const selectType = (articleType) => {
    setType(articleType);
  };

  if (!data) {
    return null;
  }

  const topSection = <NodeContentTopSection nodeType={type} panel={panel} />;

  const headerSection = (
    <HeaderSection>
      <HeaderText
        rows="1"
        aria-label={i18n.__('node_name_placeholder')}
        placeholder={i18n.__('node_name_placeholder')}
        value={title}
        onChange={(event) => {
          onChangeTitle(event.target.value);
        }}
      />
    </HeaderSection>
  );

  const commonContent = (
    <ContentEditor
      type={type}
      panelType={panel.type}
      aria-label={i18n.__('placeholder_description')}
      placeholder={i18n.__('placeholder_description')}
      value={description}
      onChange={(value) => {
        !loading && onChangeDescription(value);
      }}
      integrations={integrations}
      fileService={fileService}
      canToggleToolbar={false}
      showToolbar={true}
    />
  );

  const contentSection = () => {
    switch (type) {
      case 'chunk':
      case 'video':
      case 'link':
        return <ChunkContentEditor>{commonContent}</ChunkContentEditor>;
      case 'task':
        return <TaskContentEditor>{commonContent}</TaskContentEditor>;
      case 'written_quiz':
        return (
          <WrittenQuizContentEditor
            questions={questions}
            setQuestions={setQuestions}
            deletions={deletions}
            setDeletions={setDeletions}
            type={type}
            panelType={panel.type}
            fileService={fileService}
            loading={loading}
          >
            {commonContent}
          </WrittenQuizContentEditor>
        );
    }
  };

  const footerSection = (
    <FooterSection>
      {isInfoShowing && (
        <InfoPanel>
          <InfoText>{nodeDescription}</InfoText>
        </InfoPanel>
      )}
      <BottomPanel>
        <Wrapper>
          <DeleteIcon src={RemoveIcon} onClick={toggleConfirmPopup}>
            {i18n.__('app_delete')}
          </DeleteIcon>
          {isArticle && (
            <TypeSelector>
              {articleTypes.map((articleType) => (
                <ArticleType
                  selected={articleType === type}
                  key={articleType}
                  src={getArticleTypeIcon(articleType)}
                  onClick={() => selectType(articleType)}
                />
              ))}{' '}
              <SelectorText>{i18n.__('select_symbol')}</SelectorText>
            </TypeSelector>
          )}
        </Wrapper>
        <InfoIcon src={isInfoShowing ? InfoIconActive : InfoIconInactive} onClick={toggleInfo} />
        <Wrapper>
          {showAddQuestionButton && <SecondaryButton onClick={addQuestion} text={i18n.__('activity_add_question')} />}
          {showSaveButton && (
            <PrimaryButton onClick={() => save(title, description, type, updatedAt)} text={i18n.__('app_save')} />
          )}
        </Wrapper>
      </BottomPanel>
    </FooterSection>
  );

  const confirmPopup = (
    <Popup
      popupIsOpen={confirmPopupIsOpen}
      id="confirmPopup"
      heading={i18n.__('confirm_node_delete.title')}
      description={i18n.__('confirm_node_delete.body', { name: title })}
      buttonLabel={i18n.__('confirm_node_delete.action')}
      onClose={toggleConfirmPopup}
      onClick={remove}
    />
  );

  return (
    !loading && (
      <PanelWrapper>
        {topSection}
        {headerSection}
        {contentSection()}
        {footerSection}
        {confirmPopup}
      </PanelWrapper>
    )
  );
};

export default NodeContentEditor;

NodeContentEditor.propTypes = {
  nodeId: PropTypes.string.isRequired,
  editor: PropTypes.object.isRequired,
  panel: PropTypes.object.isRequired,
  fileService: PropTypes.object.isRequired,
  flash: PropTypes.func.isRequired,
};
