import React from 'react';
import { useTransition } from '@react-spring/web';
import useNavigateWith from '../../hooks/useNavigateWithData';
import useApi from '../../hooks/useApi';
import avatarSrcSelector from '../../components/ProfileAvatar/avatarSrcSelector';
import {
  OrganizationWrapper,
  CurrentOrganization,
  OrganizationHeader,
  OrganizationLibraryWrapper,
  ContentWrapper,
  LibraryHeader,
  LoopCount,
  OrganizationAdministrationWrapper,
  AdministrationHeader,
  ParticipantCount,
  OrganizationSettings,
  OrganizationTrialEnded,
  OrganizationsWrapper,
  OrganizationContent,
  TrialDaysWrapper,
  TrialDays,
  TrialDaysText,
  HeaderWrapper,
  Header,
  Roles,
} from './styles';

const OrganizationList = React.forwardRef((props, ref) => {
  const {
    fileService,
    organizationData,
    hasRouter,
    userToken,
    isOpen,
    toggleOrganizationMenu,
    trialDays: currentTrialDays,
    flash,
    openTrialExtend,
    isTrialExtendAllowed,
  } = props;
  const {
    current_organisation: currentOrganization,
    current_organisation_participation: currentParticipation,
    isOrganizationLibraryVisibleToStudents,
    isPaying,
    organisations: organizations = [],
    loop_count: loopCount,
    collection_count: collectionCount,
    participation_count: studentCount,
    participation_total_count: participationTotalCount,
  } = organizationData;

  const teacherCount = participationTotalCount - studentCount;
  const { id, name, slug, icon_url: iconUrl } = currentOrganization || {};

  const { is_owner: isOwner, role } = currentParticipation || {};
  const isPupil = role === 'pupil';
  const isTeacher = role === 'teacher';
  const isAdmin = isTeacher && currentOrganization.teachers_can_invite;
  const isCurrentTrial = currentTrialDays >= 0;
  const isOnlyActive = currentTrialDays > 0 && organizations.length === 0;

  const transitions = useTransition(isOpen, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  const { postData } = useApi(userToken);
  const { navigateAndReload } = useNavigateWith('', hasRouter);

  const gotoOrganization = (ev, path, organizationSlug = slug) => {
    toggleOrganizationMenu(ev);
    setTimeout(() => {
      const url = `/organisation/${organizationSlug}${path}`;
      navigateAndReload(url);
    }, 500);
  };

  const setCurrentOrganization = async (ev, id) => {
    const res = await postData(`/organisation/${id}/make_current`);
    if (res.success) {
      navigateAndReload('/me');
    } else {
      console.error(res);
      if (res.message === 'auth_failed') {
        flash(i18n.__('app_error_unauthorized'));
      } else {
        flash(i18n.__('error_organisation_make_primary'));
      }
    }
  };

  const trialInfo = (trialDays) => (
    <TrialDaysWrapper>
      <TrialDays>{trialDays}</TrialDays>
      <TrialDaysText>{i18n.__('organisation_picker.days_left')}</TrialDaysText>
    </TrialDaysWrapper>
  );

  const getHeader = (name, isPupil, isOwner) => {
    const roles = isPupil ? ['app_student'] : ['app_teacher'];
    if (isOwner) {
      roles.unshift('app_owner');
    }
    return (
      <HeaderWrapper>
        <Header>{name}</Header>
        <Roles>{roles.map((role) => `${i18n.__(role)}`).join(', ')}</Roles>
      </HeaderWrapper>
    );
  };

  const organizationItems = organizations.map((organization, index) => {
    const { id, name, icon: iconUrl, trialDays, OrganisationParticipations = [] } = organization;
    const { role, is_owner: isOwner } = OrganisationParticipations[0] || {};
    const isTrial = trialDays >= 0;
    return (
      <OrganizationHeader
        isLast={index === organizations.length - 1}
        key={id}
        isTrial={trialDays >= 0}
        icon={avatarSrcSelector(fileService, iconUrl)}
        tabIndex={0}
        onClick={(ev) => setCurrentOrganization(ev, id)}
        onKeyPress={(ev) => setCurrentOrganization(ev, id)}
      >
        {getHeader(name, role === 'pupil', isOwner)}
        {isTrial && trialInfo(trialDays)}
      </OrganizationHeader>
    );
  });

  const hasOrganizations = organizations.length > 0;

  const getParticipantsHeader = () => {
    let header = i18n.__('organisation_picker.teachers');
    if (isOwner || isAdmin) {
      header = i18n.__('organisation_picker.participant_administration');
    }

    if (isPupil) {
      header = i18n.__('organisation_picker.teachers');
    }

    if (isTeacher && !isOwner && !isAdmin) {
      header = i18n.__('organisation_picker.participants');
    }
    return header;
  };

  const showLibrary = (isOwner || isTeacher || (isPupil && isOrganizationLibraryVisibleToStudents)) && isPaying;

  const studentUsers = `${studentCount} ${i18n.__('organisation_picker.student_count')}`;
  const teacherUsers = `${teacherCount} ${i18n.__('organisation_picker.teacher_count')}`;
  const allUsers = `${studentUsers}, ${teacherCount} ${i18n.__('organisation_picker.teacher_count')}`;

  const gotoLibrary = (ev) => gotoOrganization(ev, '/library');
  const gotoAdministration = (ev) => gotoOrganization(ev, '/administration');
  const gotoSettings = (ev) => gotoOrganization(ev, '/settings');
  const gotoDashboard = (ev) => gotoOrganization(ev, '/dashboard');

  return transitions(
    (style, show) =>
      show && (
        <OrganizationWrapper style={style} ref={ref}>
          <CurrentOrganization id={id}>
            <OrganizationHeader isFirst={true} isTrial={isCurrentTrial && !isOnlyActive} icon={iconUrl}>
              {getHeader(name, isPupil, isOwner)}
              {isCurrentTrial && trialInfo(currentTrialDays)}
            </OrganizationHeader>
            {isTrialExtendAllowed && (
              <OrganizationTrialEnded tabIndex={0} onClick={openTrialExtend} onKeyPress={openTrialExtend}>
                {i18n.__('organisation_picker.trialEnded')}
              </OrganizationTrialEnded>
            )}
            {isOwner && (
              <OrganizationSettings tabIndex={0} onClick={gotoDashboard} onKeyPress={gotoDashboard}>
                {i18n.__('organisation_picker.dashboard')}
              </OrganizationSettings>
            )}
            {showLibrary && (
              <OrganizationLibraryWrapper tabIndex={0} onClick={gotoLibrary} onKeyPress={gotoLibrary}>
                <ContentWrapper>
                  <LibraryHeader>{i18n.__('organisation_picker.library')}</LibraryHeader>
                  <LoopCount>
                    {loopCount === 0
                      ? i18n.__('organisation_picker.no_loops')
                      : i18n.__('organisation_picker.x_loops', loopCount)}
                    {collectionCount > 0 ? i18n.__('organisation_picker.x_collections', collectionCount) : ''}
                  </LoopCount>
                </ContentWrapper>
              </OrganizationLibraryWrapper>
            )}
            <OrganizationAdministrationWrapper
              isLast={!hasOrganizations && !isOwner}
              tabIndex={0}
              onClick={gotoAdministration}
              onKeyPress={gotoAdministration}
            >
              <ContentWrapper>
                <AdministrationHeader>{getParticipantsHeader()}</AdministrationHeader>
                <ParticipantCount>
                  {isOwner || isAdmin ? allUsers : isTeacher ? studentUsers : teacherUsers}
                </ParticipantCount>
              </ContentWrapper>
            </OrganizationAdministrationWrapper>
            {isOwner && (
              <OrganizationSettings
                isLast={!hasOrganizations}
                tabIndex={0}
                onClick={gotoSettings}
                onKeyPress={gotoSettings}
              >
                {i18n.__('organisation_picker.settings')}
              </OrganizationSettings>
            )}
          </CurrentOrganization>
          <OrganizationsWrapper>
            {organizationItems.length > 0 && <OrganizationContent>{organizationItems}</OrganizationContent>}
          </OrganizationsWrapper>
        </OrganizationWrapper>
      ),
  );
});

OrganizationList.displayName = 'OrganizationList';

export default OrganizationList;
