class Flash {
  constructor(msg, options = {}) {
    this.msg = msg;
    this.options = options;
    this.type = options.type || 'alert';
  }

  showAfterReload() {
    window.localStorage['flash'] = JSON.stringify({
      options: this.options,
      msg: this.msg,
    });
  }

  show() {
    this.itm = $(Handlebars.templates['flash']({ message: this.msg, type: this.type }));
    this.itm.find('.lp--js-close').one('click.flash', this.hide.bind(this));
    let itm = this.itm.appendTo('body');
    setTimeout(function () {
      itm.addClass('lp--js-displayed');
    }, 1);
    var that = this;
    this.timer = setTimeout(function () {
      that.hide();
    }, this.options.ttl || 5000);
  }

  hide() {
    var that = this;
    clearTimeout(this.timer);
    return new Promise(function (resolve, reject) {
      that.itm.one(transition_event_name, (e) => {
        $(window).trigger('hide.flash');
        that.itm.remove();
        if (current == that) {
          current = null;
        }
        resolve();
      });
      that.itm.removeClass('lp--js-displayed');
    });
  }
}

let current;

$(window).on('flash', function (e, msg, opts) {
  window.flash(msg, opts);
});

export default function flash(msg, options) {
  let p = Promise.resolve(true);
  if (current) {
    p = p.then(function () {
      if (current.timer) {
        return new Promise(function (resolve, reject) {
          $(window).on('hide.flash', function () {
            current = null;
            resolve();
          });
        });
      } else {
        return current.hide();
      }
    });
  }
  p.then(function () {
    current = new Flash(msg, options);
    if (options && options.afterReload) {
      current.showAfterReload();
    } else {
      current.show();
    }
    return current;
  });
}

$(function () {
  let prom = Promise.resolve(true);
  if (window.localStorage.getItem('flash')) {
    prom = prom.then(function () {
      return new Promise(function (resolve, reject) {
        let data = JSON.parse(window.localStorage['flash']);
        window.localStorage.removeItem('flash');
        flash(data.msg, { ttl: data.options.ttl, type: data.options.type });
        $(window).one('hide.flash', function () {
          resolve();
        });
      });
    });
  }
  prom.then(function () {
    const alertMessage = document.body.getAttribute('data-flash-alert');
    const statusMessage = document.body.getAttribute('data-flash-status');
    const type = statusMessage ? 'status' : 'alert';
    const message = alertMessage + statusMessage;
    if (message) {
      setTimeout(function () {
        flash(message, { ttl: 3000, type });
      }, 200);
    }
  });
});
