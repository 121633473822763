import React from 'react';
import PropTypes from 'prop-types';
import PurpleGuide from '../PurpleGuide';

import { TrialPanelWrapper, TrialPanelTab, TrialDays } from './styles';

const TrialPanel = ({ userId, trialDays, forceOpen }) => {
  const trialDaysLeft = forceOpen ? 0 : trialDays;
  const trialEnd = trialDaysLeft === 0;
  const trialTab = ({ children, role, onClick }) => (
    <TrialPanelTab role={role} onClick={onClick}>
      <TrialDays trialEnd={trialEnd}>{trialDaysLeft}</TrialDays>
      {children}
    </TrialPanelTab>
  );

  return trialDaysLeft < 0 ? null : (
    <PurpleGuide
      id="trialPanel"
      userId={userId}
      defaultOpen={trialEnd}
      saveInLocalStorage={trialEnd}
      forceOpen={forceOpen}
      TabComponent={trialTab}
      WrapperComponent={TrialPanelWrapper}
      openClassName="trial-open"
    />
  );
};

TrialPanel.propTypes = {
  userId: PropTypes.string.isRequired,
  trialDays: PropTypes.number.isRequired,
  forceOpen: PropTypes.bool,
};

export default TrialPanel;
