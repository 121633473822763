import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Utils from '../../../app/assets/js/shared/utils';

import {
  ReportStateContainer,
  StateSwitchButton,
  StateLabel,
  StateListContainer,
  StateList,
  StateListItem,
  StateListItemLabel,
  StateListItemIcon,
} from './styles';

import stateIconFeedback from '../../../public/assets/images/theme-2/icons/states/feedback-loop-_want-feedback.svg';
import stateIconBlank from '../../../public/assets/images/theme-2/icons/states/not-started.svg';
import stateIconActive from '../../../public/assets/images/theme-2/icons/states/active.svg';
import stateIconDone from '../../../public/assets/images/theme-2/icons/states/submitted.svg';
import stateIconAccept from '../../../public/assets/images/theme-2/icons/states/accepted.svg';
import stateIconSendBack from '../../../public/assets/images/theme-2/icons/states/feedback-loop-_send-back.svg';
import lockIconLock from '../../../public/assets/images/theme-2/icons/states/feedback-loop-_lock.svg';
import lockIconUnlock from '../../../public/assets/images/theme-2/icons/states/feedback-loop-_unlock.svg';

const STATE_ICON_MAP = {
  feedback: stateIconFeedback,
  blank: stateIconBlank,
  active: stateIconActive,
  done: stateIconDone,
  accept: stateIconAccept,
  accepted: stateIconAccept,
};

const NEW_STATE_ICON_MAP = {
  ...STATE_ICON_MAP,
  active: stateIconSendBack,
  done: stateIconAccept,
  return_feedback: stateIconFeedback,
};

const LOCK_ICON_MAP = {
  lock: lockIconLock,
  unlock: lockIconUnlock,
};

const ReportStateSelector = ({ report, updateState, isTeacher = false, allowLock = false, allowStates = false }) => {
  const [open, setOpen] = useState(false);

  const i18nRole = (str) => {
    return i18n.__(`${str}${isTeacher ? '_teacher' : ''}`);
  };

  const stateLabel = i18n.__(Utils.getStateLabelString(report.state));
  const stateImage = STATE_ICON_MAP[report.state];

  const toggleOpen = (ev) => {
    ev.preventDefault();
    setOpen(!open);
  };

  const changeState = (ev, newState) => {
    ev.preventDefault();
    updateState(newState, report.locked);
  };

  const changeLock = (ev, newLock) => {
    ev.preventDefault();
    updateState(report.state, newLock);
  };

  const afterStateChange = () => {
    setOpen(false);
  };

  const stateListItems = [];
  if (allowStates) {
    Utils.getAvailableBehaviorStates(report.state, isTeacher).forEach(({ label, event }) => {
      stateListItems.push(
        <StateListItem
          key={event}
          onClick={(ev) => {
            changeState(ev, event, afterStateChange);
          }}
        >
          <StateListItemLabel>{i18nRole(`editor_state_${label ? label : event}`)}</StateListItemLabel>
          <StateListItemIcon src={NEW_STATE_ICON_MAP[event]}></StateListItemIcon>
        </StateListItem>,
      );
    });
  }

  if (allowLock) {
    const lockedStateToKey = report.locked ? 'unlock' : 'lock';
    stateListItems.push(
      <StateListItem
        key={lockedStateToKey}
        lockItem={true}
        onClick={(ev) => {
          changeLock(ev, !report.locked);
        }}
      >
        <StateListItemLabel>{i18nRole(`editor_state_${lockedStateToKey}`)}</StateListItemLabel>
        <StateListItemIcon src={LOCK_ICON_MAP[lockedStateToKey]}></StateListItemIcon>
      </StateListItem>,
    );
  }

  return (
    <>
      <ReportStateContainer>
        <StateLabel>{stateLabel}</StateLabel>
        <StateSwitchButton
          report={report}
          stateImage={stateImage}
          title={i18n.__('set_editor_state_teacher')}
          onClick={(ev) => {
            toggleOpen(ev);
          }}
        >
          <StateListContainer open={open}>
            <StateList>{stateListItems}</StateList>
          </StateListContainer>
        </StateSwitchButton>
      </ReportStateContainer>
    </>
  );
};

export default ReportStateSelector;

ReportStateSelector.propTypes = {
  report: PropTypes.object.isRequired,
  isTeacher: PropTypes.bool.isRequired,
  allowLock: PropTypes.bool.isRequired,
  allowStates: PropTypes.bool.isRequired,
  updateState: PropTypes.func.isRequired,
};
