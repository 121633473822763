import Vue from 'vue';

Vue.component('category-display', {
  props: ['categories', 'selectedGap', 'selectedCategories', 'excludedCategories', 'gap_class'],
  template: `
    <div class="lp--categoryPicker-modalContentWrapper" v-bind:class="['lp--categoryPicker-grade'+gap_class(selectedGap.key)]">
      <div class="lp--categoryPicker-modalContentWrapperCircle">
        <div class="lp--categoryPicker-modalContent">
          <div v-for="category in categories" v-if="category.gap_type == selectedGap.key" class="lp--categoryPicker-categoryBlock">
            <div class="category-title">{{category.name}}</div>
            <ul :data-uuid="category.uuid">
              <li tabindex="0" v-for="child in category.children" :aria-disabled="isCategoryBlocked(child)" v-on:keypress="toggleCategory(child)" v-on:click="toggleCategory(child)" v-bind:data-uuid="child.uuid" v-bind:class="{active: isCategorySelected(child), disabled: isCategoryBlocked(child)}">{{child.name}}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>    
  `,
  methods: {
    isCategoryBlocked: function (cat) {
      return this.excludedCategories.indexOf(cat.document_id) > -1;
    },
    isCategorySelected: function (cat) {
      return this.selectedCategories.indexOf(cat) > -1;
    },
    toggleCategory: function (cat) {
      if (!this.isCategoryBlocked(cat)) {
        this.$emit('toggleCategory', cat);
      }
    },
  },
});

Vue.component('category-modal-header', {
  props: ['selectedGap', 'gapTypes', 'gap_class', 'sizeClass'],
  template: `
  <div class="lp--categoryPicker-modalHeader">
    {{i18n('category_picker_loops_for')}}
    <a v-bind:tabIndex="getTabIndex(true)" v-for="gap in gapTypes" href="#" class="lp--js-categoryPicker-grade lp--categoryPicker-grade" v-bind:class="['lp--categoryPicker-grade'+gap_class(gap.key), {active: (selectedGap.key == gap.key)}]" v-on:click.prevent="displayGroup(gap)">{{gap.value}}</a>
    <div class="lp--categoryPicker-subMenu">
      <a v-bind:tabIndex="getTabIndex()" href="#" class="lp--categoryPicker-menuBack lp--js-categoryPicker-menuBack" v-on:click="goBack">{{i18n('category_picker_back')}}</a>
      <a tabIndex="-1" href="#" class="lp--categoryPicker-grade lp--js-categoryPicker-pickedGrade lp--categoryPicker-grade active" v-bind:class="['lp--categoryPicker-grade'+gap_class(selectedGap.key)]">{{selectedGap.value}}</a>
    </div>
  </div>`,
  methods: {
    getTabIndex: function (inverted) {
      if (this.sizeClass === 'small') {
        if (inverted) {
          return this.selectedGap.key ? -1 : 0;
        }
        return this.selectedGap.key ? 0 : -1;
      } else {
        return 0;
      }
    },
    displayGroup: function (gap) {
      this.$emit('displayGroup', gap);
    },
    goBack: function () {
      this.$emit('back');
    },
    i18n: function (str) {
      return i18n.__(str);
    },
  },
});

Vue.component('category-embedded-header', {
  props: ['selectedGap', 'gapTypes', 'gapLabel', 'gap_class'],
  template: `
    <div class="lp--categoryPicker-embeddedHeader">
      <h3>{{i18n('category_picker_interests')}}</h3>
      <div class="lp--categoryPicker-subMenu">
        <label class="lp--categoryPicker-subMenuLabel">{{typeof(gapLabel) != 'undefined' ? gapLabel : i18n('category_picker_subject')}}</label>
        <a v-for="gap in gapTypes" href="#" class="lp--js-categoryPicker-grade lp--categoryPicker-grade" v-bind:class="['lp--categoryPicker-grade'+gap_class(gap.key), {active: (selectedGap.key == gap.key)}]" v-on:click.prevent="displayGroup(gap)">{{gap.value}}</a>
      </div>
    </div>
  `,
  methods: {
    displayGroup: function (gap) {
      this.$emit('displayGroup', gap);
    },
    i18n: function (str) {
      return i18n.__(str);
    },
  },
});

Vue.component('category-picker', {
  props: ['sizeClass', 'gapTypes', 'gapLabel', 'categories', 'selectedGap', 'selectedCategories', 'excludedCategories'],
  template: `
  <div v-bind:class="viewClasses"">  
    <category-modal-header :sizeClass="sizeClass" :selectedGap="selectedGap" :gapTypes="gapTypes" :gap_class="getSupportedGapClass" @displayGroup="displayGroup" @back="goBack" v-if="sizeClass!='embedded'" />
    <category-embedded-header :selectedGap="selectedGap" :gapTypes="gapTypes" :gap_class="getSupportedGapClass" :gapLabel="gapLabel" @displayGroup="displayGroup" v-if="sizeClass=='embedded'" />

    <category-display :categories="categories" :excludedCategories="excludedCategories" :selectedGap="selectedGap" :gap_class="getSupportedGapClass" @toggleCategory="toggleCategory" :selectedCategories="selectedCategories" />

    <a href="#" class="lp--categoryPicker-close pr2--js-closeBtn" v-on:click.prevent="closePicker" v-if="sizeClass!='embedded'" :title="i18n('app_close')">Close</a>
    <div class="lp--categoryPicker-brand" v-if="sizeClass!='embedded'"></div>
  </div>
  `,
  methods: {
    getSupportedGapClass: function (key) {
      return key != 'all' ? key : 'all';
    },
    goBack: function () {
      this.$emit('resetCategory');
    },
    displayGroup: function (gap) {
      this.$emit('gapSelected', gap);
    },
    toggleCategory: function (cat) {
      this.$emit('toggleCategory', cat);
    },
    closePicker: function () {
      this.$emit('close');
    },
    i18n: function (str) {
      return i18n.__(str);
    },
  },
  computed: {
    viewClasses: function () {
      return {
        'lp--categoryPicker-modal': this.sizeClass != 'embedded',
        'lp--categoryPicker-embedded': this.sizeClass == 'embedded',
        'lp--categoryPicker-gradeView': this.selectedGap != '',
        small: this.sizeClass == 'small',
        embedded: this.sizeClass == 'embedded',
      };
    },
  },
  mounted: function () {},
});
