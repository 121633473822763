import styled from 'styled-components';

export const Container = styled.div`
  margin-bottom: 20px;
`;

export const Table = styled.table``;

export const TableRow = styled.tr``;

export const TableHeader = styled.thead``;
export const TableBody = styled.tbody``;

export const TableHeaderCell = styled.th`
  white-space: nowrap;
  transform: rotate(180deg);
  writing-mode: vertical-rl;
  padding: 8px 2px 0 2px;
`;

export const TableCellItem = styled.td`
  padding: 5px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.black_1};
`;

export const TableCell = styled.td`
  text-align: center;
  border: 1px solid ${({ theme }) => theme.colors.black_1};
`;

export const TableHeaderCellRotation = styled.div``;
