import React, { useState, useContext, useEffect } from 'react';
import Popup from '../Popup';
import TextField from '../TextField';

import useApi from '../../hooks/useApi';
import { GlobalContext } from '../../GlobalContext';

import { Container, Wrapper, TextBlock } from './styles';

const LinkEmailPopup = ({ popupIsOpen, userToken, flash, onClose, source = '' }) => {
  const { postData, loading } = useApi(userToken);
  const [email, setEmail] = useState('');
  const [emailSent, setEmailSent] = useState(false);
  const [disableSendButton, setDisableSendButton] = useState(false);

  const context = useContext(GlobalContext);

  const showFlashMessage = (options) => {
    if (flash) {
      flash(options.message, { ttl: options.duration, type: options.type });
    } else {
      if (options.error) {
        context.showFlashError(options);
      } else {
        context.showFlashMessage(options);
      }
    }
  };

  const clearState = () => {
    setEmail('');
    setEmailSent(false);
  };

  useEffect(() => {
    if (!popupIsOpen) {
      setTimeout(() => clearState(), 500);
    }
  }, [popupIsOpen]);

  const handleSave = async () => {
    try {
      const result = await postData(`/linkEmail/${source}`, {
        email,
      });

      if (result.success) {
        setDisableSendButton(true);
        setTimeout(() => {
          setDisableSendButton(false);
        }, 5000);
        setEmailSent(true);
        showFlashMessage({
          message: i18n.__('app_sent'),
          type: 'status',
        });
      } else {
        if (result.code === 400) {
          throw new Error(i18n.__('invalid_email'));
        } else {
          throw new Error();
        }
      }
    } catch (error) {
      showFlashMessage({
        message: error.message ? error.message : i18n.__('app_error_alert'),
        error: true,
      });
    }
  };

  const heading = !emailSent ? i18n.__('linkEmail.header') : i18n.__('signup_modal_enter_code_title');

  const body = (
    <Wrapper>
      <TextBlock>{i18n.__(source === 'feide' ? 'linkEmail.bodyFeide' : 'linkEmail.body')}</TextBlock>

      <>
        <TextField
          autoFocus
          id="email"
          name="email"
          label={i18n.__('app_email')}
          defaultValue={email}
          onChange={(event) => {
            setEmail(event.target.value);
          }}
        />
      </>
    </Wrapper>
  );

  const bodySent = (
    <Wrapper>
      <TextBlock>{i18n.__('signup_modal_enter_code_body')}</TextBlock>
      <TextBlock>{i18n.__('signup_modal_enter_code_resend_mail').replace(/%s/gi, '')}</TextBlock>
      <TextBlock
        dangerouslySetInnerHTML={{
          __html: i18n.__('signup_modal_enter_code_contact'),
        }}
      />
    </Wrapper>
  );

  const contentPanel = <Container>{!emailSent ? body : bodySent}</Container>;

  return (
    <Popup
      popupIsOpen={popupIsOpen}
      heading={heading}
      id="link-email-popup"
      width={675}
      contentPanel={contentPanel}
      buttonLabel={i18n.__(!emailSent ? 'app_send' : 'app_send_again')}
      onClose={onClose}
      onClick={handleSave}
      buttonDisabled={email.trim() === '' || loading || disableSendButton}
    />
  );
};

export default LinkEmailPopup;
