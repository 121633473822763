import React from 'react';
import useMousePosition from '../../hooks/useMousePosition';
import { Cursor } from './styles';

const CanvasCursor = ({ width, color }) => {
  const { x, y } = useMousePosition();
  return <Cursor className="cursor" width={width} color={color} style={{ left: `${x}px`, top: `${y}px` }} />;
};

export default CanvasCursor;
