import React, { useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { InputFieldWrapper, InputCheckBox, CheckBoxLabel, Icon, Label } from './styles';

const CheckBox = ({
  id,
  name,
  text,
  defaultChecked,
  value,
  onChange,
  tabIndex = 0,
  top,
  lightMode = false,
  disabled = false,
  noLabel = false,
  ...rest
}) => {
  const checkboxRef = useRef(null);
  const triggerClick = useCallback(() => {
    checkboxRef.current.click();
  });
  const onChangeHandler = (ev) => {
    ev.target.parentNode.blur();
    onChange(ev);
  };

  return (
    <InputFieldWrapper top={top}>
      <CheckBoxLabel
        tabIndex={tabIndex}
        onKeyPress={triggerClick}
        htmlFor={id || name}
        lightMode={lightMode}
        disabled={disabled}
        noLabel={noLabel}
      >
        <InputCheckBox
          ref={checkboxRef}
          key={`${value}_${defaultChecked}`}
          type="checkbox"
          name={name || id}
          id={id || name}
          defaultChecked={defaultChecked}
          value={value}
          onChange={onChangeHandler}
          disabled={disabled}
          {...rest}
        />
        <Icon noLabel={noLabel} />
        <Label>{text}</Label>
      </CheckBoxLabel>
    </InputFieldWrapper>
  );
};

CheckBox.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  text: PropTypes.string,
  defaultChecked: PropTypes.bool.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onChange: PropTypes.func.isRequired,
  tabIndex: PropTypes.number,
  top: PropTypes.number,
  rest: PropTypes.object,
  lightMode: PropTypes.bool,
  disabled: PropTypes.bool,
  noLabel: PropTypes.bool,
};

export default CheckBox;
