import { parseISO } from 'date-fns';

export const VALID_EMAIL_PATTERN =
  /(?:[a-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
export const IS_MOBILE_LAYOUT = window.innerWidth < 768;
export const IS_TABLET_LAYOUT = window.innerWidth < 1160;

export const getQueryParameter = (key) => {
  const queryParams = new URLSearchParams(window.location.search);
  return queryParams.get(key);
};

export const isSessionFinished = (sessionState, participationState, organizationParticipationState) => {
  return (
    sessionState === 'terminated' ||
    participationState === 'left' ||
    participationState === 'inactive' ||
    organizationParticipationState === 'inactive'
  );
};

export const isSessionOngoing = (sessionState, isSessionFinished, isSessionCompleted) => {
  return !(isSessionFinished || isSessionCompleted) && sessionState === 'active';
};

export const isSessionCompleted = (participation) => {
  return participation.completed_progress === 1;
};

export const parseImageObjectOrString = (image, placeholder) => {
  return {
    imageUrl: typeof image === 'object' && image !== null ? image._url || '' : image,
    placeholder:
      typeof image === 'object' && image !== null && image.options && image.options.placeholder
        ? image.options.placeholder
        : placeholder,
  };
};

export const buildFileServiceImageUrl = (fileService) => {
  const shouldBePrefixed = (imageUrl) => !/^[a-z0-9]+:\/\//.test(imageUrl);
  return (image, width = 100, height = 100, defaultPlaceholder) => {
    const { imageUrl, placeholder } = parseImageObjectOrString(image, defaultPlaceholder);

    if (imageUrl) {
      if (shouldBePrefixed(imageUrl) && !imageUrl.startsWith(fileService.url) && !imageUrl.startsWith('resources/')) {
        if (width && height) {
          return `${fileService.url}${width}x${height}/${imageUrl}`;
        } else {
          return `${fileService.url}${imageUrl}`;
        }
      }
      return imageUrl;
    }
    return placeholder;
  };
};

export const isInFrame = (entryPoint) => {
  try {
    return window.self !== window.top || entryPoint === 'teams';
  } catch (e) {
    return true;
  }
};

export const getLocalizedGenitive = (locale, name) => {
  const language = locale.substring(0, 2);
  const endsWithS = name.endsWith('s') || name.endsWith('x') || name.endsWith('z');

  if (language === 'en') {
    return `${name}'s`;
  } else if (language === 'es') {
    return name;
  } else {
    return endsWithS ? name : `${name}s`;
  }
};

export const detectUserPlatform = () => {
  if (!window || !window.UAParser) {
    return null;
  }

  const infoSections = {};
  const screen = window.screen.width + 'x' + window.screen.height;
  const browser = window.innerWidth + 'x' + window.innerHeight;

  const parser = new window.UAParser();
  infoSections['user_agent'] = parser.getUA();
  infoSections['os'] = parser.getOS();
  infoSections['device'] = parser.getDevice();
  infoSections['browser'] = parser.getBrowser();
  infoSections['size'] = { screen, browser };

  return infoSections;
};

export const debounce = (func, wait, immediate) => {
  let timeout;
  return function () {
    const context = this;
    const args = arguments;
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      timeout = null;
      if (!immediate) {
        func.apply(context, args);
      }
    }, wait);
    if (immediate && !timeout) {
      func.apply(context, args);
    }
  };
};

export const getOptions = () => {
  return (window.editor || window.viewer || window.tp).options;
};

export const getAutoSaveInterval = () => getOptions().autoSave.autoSaveInterval;

export const setStatusSaving = () => {
  window.editor.setSaveStatus('behavior_saving');
  clearTimeout(window.editor.clearStatusTimer);
};

export const setStatusSaved = () => {
  window.editor.setSaveStatus('behavior_saved');
  window.editor.clearStatusTimer = setTimeout(() => {
    window.editor.setSaveStatus('');
  }, 5000);
};

export const setStatusSaveFailed = () => {
  window.editor.setSaveStatus('behavior_save_failed');
  window.editor.clearStatusTimer = setTimeout(() => {
    window.editor.setSaveStatus('');
  }, 5000);
};

export const isWindows = () => {
  return window.navigator.userAgent.indexOf('Win') > -1;
};

export const formatDate = (value, withYear = true) => {
  if (!/\d{4}-\d{2}-\d{2}/.test(value)) {
    return value;
  }

  const options = { month: 'numeric', day: 'numeric' };
  if (withYear) {
    options.year = 'numeric';
  }
  return parseISO(value).toLocaleDateString(window.baseLocale, options);
};

export const buildBranding = (theme, branding) => {
  return {
    ...theme,
    profile: {
      ...theme.profile,
      ...(branding ? branding.profile : {}),
    },
  };
};

export const nameSorter = (a, b) =>
  (a['name'] || a['full_name'] || a['User.full_name'] || '').localeCompare(
    b['name'] || b['full_name'] || b['User.full_name'] || '',
  );

export const validateEmail = (email, validOrganizationEmailPattern) => {
  const [validEmails] = validateEmails([email], validOrganizationEmailPattern);
  return validEmails.includes(email);
};

export const validateEmails = (emails, validOrganizationEmailPattern) => {
  const orgRegExp = new RegExp(validOrganizationEmailPattern || '', 'i');
  const emailRegExp = new RegExp(VALID_EMAIL_PATTERN, 'i');
  const validEmails = emails.filter((email) => orgRegExp.test(email) && emailRegExp.test(email));
  const invalidEmails = emails.filter((email) => !orgRegExp.test(email) || !emailRegExp.test(email));

  return [validEmails, invalidEmails];
};
