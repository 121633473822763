export default class FileDrop {
  constructor(quill, options = {}) {
    this.quill = quill;
    this.options = options;
    this.handleDrop = this.handleDrop.bind(this);
    this.handlePaste = this.handlePaste.bind(this);
    this.quill.root.addEventListener('drop', this.handleDrop, false);
    this.quill.root.addEventListener('paste', this.handlePaste, false);
  }

  handlePaste(e) {
    if (e.clipboardData && e.clipboardData.items && e.clipboardData.items.length) {
      this.readFiles(e.clipboardData.items, async (file, base64Data) => {
        await this.options.pasteAndDropHandler.call(this, file, base64Data);
      });
    }
  }

  handleDrop(e) {
    e.preventDefault();
    if (e.dataTransfer && e.dataTransfer.files && e.dataTransfer.files.length) {
      if (document.caretRangeFromPoint) {
        const selection = document.getSelection();
        const range = document.caretRangeFromPoint(e.clientX, e.clientY);
        if (selection && range) {
          selection.setBaseAndExtent(range.startContainer, range.startOffset, range.startContainer, range.startOffset);
        }
      }
      this.readFiles(e.dataTransfer.files, async (file, base64Data) => {
        await this.options.pasteAndDropHandler.call(this, file, base64Data);
      });
    }
  }

  readFiles(files, callback) {
    [].forEach.call(files, (file) => {
      const blob = file.getAsFile ? file.getAsFile() : file;
      if (file.type.match('image/*')) {
        const reader = new FileReader();
        reader.onload = (e) => {
          if (e.target && e.target.result) {
            callback(blob, e.target.result);
          }
        };
        if (blob instanceof Blob) {
          reader.readAsDataURL(blob);
        }
      } else {
        callback(blob);
      }
    });
  }
}
