import styled from 'styled-components';

export const InputFieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px 0;
`;

export const InputTextField = styled.input`
  background-color: ${({ theme }) => theme.colors.transparent};
  border: none;
  outline: none;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey};
  font-family: ${({ theme }) => theme.fonts.robotoLight};
  font-size: 18px;
  color: ${({ theme }) => theme.colors.white};

  ::selection {
    background-color: ${({ theme }) => theme.colors.grey};
  }

  ::placeholder {
    font-style: italic;
    color: ${({ theme }) => theme.colors.white_7};
  }
`;

export const TextFieldLabel = styled.label`
  color: ${({ theme }) => theme.colors.standardTextGrey};
  font-weight: normal;
  margin-bottom: 5px;
`;
