import { Client } from 'paho-mqtt';
import flash from './shared/flash';

export default class NotificationCenter {
  get baseTopic() {
    if (!this._baseTopic) {
      this._baseTopic = '/loops/notifications/' + document.body.getAttribute('data-user-uuid');
    }
    return this._baseTopic;
  }

  get userIdentity() {
    if (!this._userIdentity) {
      this._userIdentity = document.body.getAttribute('data-user-uuid');
    }
    return this._userIdentity;
  }

  constructor(opts) {
    this.options = opts;
    this.client = new Client(opts.mqtt.host, opts.mqtt.port, '/mqtt', 'client-' + Math.random().toString(36).slice(-6));
    this.client.onMessageArrived = this.messageReceived.bind(this);
    this.client.onConnectionLost = (err) => {
      console.log('mqtt disconnected', err);
    };
  }

  subscribe(uuid) {
    if (this.client.isConnected()) {
      this.client.subscribe('/loops/comments/' + uuid);
    }
  }

  unsubscribe(uuid) {
    if (this.client.isConnected()) {
      this.client.unsubscribe('/loops/comments/' + uuid);
    }
  }

  messageReceived(message) {
    const payload = JSON.parse(message.payloadString);
    const editType = payload.event.replace('.edit', '');
    switch (payload.event) {
      case 'stream.comments':
        this.handleIncomingCommentStream(payload);
        break;
      case 'notification.created':
        this.handleIncomingNotification(payload);
        document.dispatchEvent(new CustomEvent('notification.created', { detail: payload.data }));
        break;
      case 'progress.updated':
        $(window).trigger('progress.updated', payload.data);
        break;
      case 'media.processed':
        $(window).trigger('media.processed', payload.data);
        break;
      case `${editType}.edit`:
        this.handleIncomingEditNotification(payload, editType);
        break;
      case 'mediaconvert.progressing':
        $(window).trigger('mediaprogress.updated', payload.data);
        document.dispatchEvent(new CustomEvent('mediaprogress.updated', { detail: payload.data }));
        break;
      case 'gamification.reward.achieved':
        document.dispatchEvent(new CustomEvent('gamification.updated', { detail: payload.data }));
        break;
      case 'content.generation.done':
        document.dispatchEvent(new CustomEvent('ai.content.updated', { detail: payload.data }));
        break;
    }
  }

  patchNotification(payload) {
    if (payload.data.tmpl_key) {
      payload.data.template_key = payload.data.tmpl_key;
    }
    if (payload.data.node) {
      payload.data.uuid = payload.data.node.uuid;
    }
    if (payload.notification_id) {
      payload.data.id = payload.notification_id;
    }
  }

  handleIncomingEditNotification(payload, editType) {
    const isEditingSameLoop = location.pathname.endsWith(`/editor/${payload.loopId}`);
    if (!isEditingSameLoop) {
      return;
    }

    const isEditingSameNode = location.hash === `#${payload.nodeUuid}`;
    const showFlash = (editType === 'loop' && !location.hash) || (editType !== 'loop' && isEditingSameNode);
    if (showFlash) {
      flash(
        i18n.__(`editor_collaborator_edits_same_${editType}`, {
          name: payload.name,
        }),
        { ttl: 20000 },
      );
    }
  }

  handleIncomingNotification(payload) {
    this.patchNotification(payload);
    const isTeacher = document.body.getAttribute('data-user-is-teacher') === 'true';
    if (payload.data.tmpl_key === 'report_state') {
      this.markAcceptedAssignment(payload.data);
    }
    if (payload.data.tmpl_key === 'announce') {
      this.markAnnounced(payload.data);
    } else if (payload.data.tmpl_key !== 'report_state' || isTeacher) {
      this.markNotifiedAssignment(payload.data);
    }
  }

  handleIncomingCommentStream(payload) {
    if (payload.author_identity != this.userIdentity) {
      let e = $.Event('comment.posted', {
        entity_uuid: payload.entity_uuid,
        entity_type: payload.entity_type,
        html: payload.html,
        remote: true,
      });
      $('body').trigger(e);
    }
  }

  markAcceptedAssignment(data) {
    if (window.viewer) {
      const panelNode = $(`.activity-item[data-uuid=${data.node.uuid}]`);
      if (data.report.state === 'accepted' && !panelNode.hasClass('accepted')) {
        panelNode.addClass('accepted');
        window.viewer.addCheckmarkById(data.node.uuid);
      } else {
        panelNode.removeClass('accepted');
        window.viewer.removeCheckmarkById(data.node.uuid);
      }
    }
  }

  markNotifiedOrAnnounced(data, type = 'notification') {
    const node = data.node;
    if (window.viewer && node) {
      const panelNode = $(`.activity-item[data-uuid=${node.uuid}] .notification`);
      if (!panelNode.hasClass('show')) {
        panelNode.addClass('show');
        window.viewer.addNotificationById(node.uuid, type);
      }
    }
  }

  markNotifiedAssignment(data) {
    this.markNotifiedOrAnnounced(data);
  }

  markAnnounced(data) {
    this.markNotifiedOrAnnounced(data, 'announce');
  }

  setup() {
    this.client.connect({
      useSSL: this.options.mqtt.ssl,
      reconnect: true,

      cleanSession: true,
      timeout: 10,
      keepAliveInterval: 30,

      onSuccess: () => {
        this.client.subscribe(this.baseTopic);
      },
    });
  }
}
