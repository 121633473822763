import styled from 'styled-components';

export const DatePickerWrapper = styled.div`
  input {
    background-color: ${({ theme }) => theme.colors.standardBlackBackground};
    color: ${({ theme }) => theme.colors.white};
    border: none;
    border-bottom: 1px solid ${({ theme }) => theme.colors.white_7};
    width: 90px;
    cursor: pointer;

    &::placeholder {
      color: ${({ theme }) => theme.colors.white_7};
      font-style: italic;
    }

    &[disabled] {
      color: ${({ theme }) => theme.colors.white_4};
      border-bottom: 1px solid ${({ theme }) => theme.colors.white_4};

      &::placeholder {
        color: ${({ theme }) => theme.colors.white_4};
        font-style: italic;
      }
    }
  }

  .react-datepicker-popper {
    z-index: 2;
  }
`;
