import useFetch from 'use-http';

const useApi = (userToken, contentType) => {
  const headers = {
    callerId: 'loops-web',
    mimeType: 'application/json',
    responseType: 'blob',
  };
  if (userToken) {
    headers['x-lp-token'] = userToken;
  }
  if (contentType) {
    headers['Content-Type'] = contentType;
  }

  const { get, post, put, del, response, loading, error } = useFetch({
    headers,
    cachePolicy: 'no-cache',
  });

  const handleError = (err, code) => {
    let message = err && err.message;
    if (code === 403) {
      message = 'auth_failed';
    } else if (code === 409) {
      message = 'edit_conflict';
    } else if (code === 413) {
      message = 'payload_too_large';
    } else if (code === 402) {
      return (location.href = `/#expiredAccount`);
    }
    return {
      success: false,
      message,
      code,
      err,
    };
  };

  const postData = async (url, data) => {
    const body = await post(url, data);

    if (response.redirected) {
      return {
        success: true,
        redirect: true,
        url: response.url,
      };
    } else if (response.ok) {
      return {
        success: true,
        body,
        data: response,
      };
    } else {
      return handleError(response.data, response.status);
    }
  };

  const putData = async (url, data) => {
    const body = await put(url, data);

    if (response.ok) {
      return {
        success: true,
        body,
      };
    } else {
      return handleError(response.data, response.status);
    }
  };

  const deleteData = async (url, data) => {
    const body = await del(url, data);

    if (response.ok) {
      return {
        success: true,
        body,
      };
    } else {
      return handleError(response.data, response.status);
    }
  };

  const getData = async (url) => {
    const body = await get(url);

    if (response.ok) {
      return {
        success: true,
        body,
      };
    } else {
      return handleError(response.data, response.status);
    }
  };

  return { getData, postData, putData, deleteData, response, loading, error };
};

export default useApi;
